/*
** @name: Meu Clínicas - faqEditModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2022
** @description: Módulo para gerar modal de edição/visualização de perguntas/respostas do FAQ
** 
*/

import React from 'react';
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppModal from '../../components/general/appModal/appModal.js';
import { InputField, TextareaField } from '../../components/fields/formsBuilderCustoms/';
import ToggleButton from '../../components/fields/toggleButton/toggleButton.js';

import FaqContent from '../../modules/faq/faqContent.js';


// Import module styles
import './scss/faqEditModal.scss';


const InformationField = ({info}) => info ? <i className="field-information" title={info} /> : null;

const FaqEditModal = (props) => {
    const { editItem, onEditClose, onEditSave, onFieldChange, buildResultItem } = props || {};
    let { fieldMinSize } = props || {};
    fieldMinSize = fieldMinSize || {};
    if(!editItem || !buildResultItem) {
        return null;
    }

    const _checkItemChanged = () => {
        const toBoolean = val => val ? true : false;
        const item = buildResultItem();
        const original = editItem.data;
        if(item && original) {
            // compare editable values - 'criadoEm' and 'atualizadoEm' are controled only in BE
            // imagesChanged is used to avoid large imagens comparisons
            return item.imagesChanged ||
                toBoolean(item.hideFromList) !== toBoolean(original.hideFromList) ||
                item.id !== original.id ||
                item.questionId !== original.questionId ||
                item.titulo !== original.titulo ||
                item.resposta !== original.resposta ||
                JSON.stringify(item.tags) !== JSON.stringify(original.tags)
            ;
        }
        return null;
    }

    const formFields = editItem.control.fields;
    const formError = editItem.control.erroEdicao;
    const isFormChanged = !editItem.viewOnly && _checkItemChanged();
    const isInsertMode = !editItem.viewOnly && !editItem.data.id;
    const previewedData = editItem.viewOnly ? editItem.data : (!isInsertMode || isFormChanged ? buildResultItem() : null);

    return(
        <div id="faqEditModalId">
            <AppModal
                closeOnEsc={true}
                closeOnClickOutside={false}
                onClose={() => onEditClose(isFormChanged, editItem.viewOnly)}
                headerContent={
                    <div className="modal-header-wrapper">
                        <div className="header-title">{editItem.viewOnly ? "Visualização" : (isInsertMode ? "Inserção" : "Edição")} de item do Faq</div>
                        <div className="close-button" onClick={() => onEditClose(isFormChanged, editItem.viewOnly)}>
                            <AppCustomImage imageContextFn={getImageThemeContext} module="appCard" imageId="normal-icon_back" />
                        </div>
                    </div>
                }
            >
                <div className="faq-editor-wrapper no-select">
                    { !editItem.viewOnly &&
                    <div className="faq-form-wrapper">
                        <form className="ui form editor-content">

                            <div className="form-row form-instructions">
                                <div className="aviso">ATENÇÃO: No momento a edição de imagens não é suportada nesse módulo</div>
                                <div><i className="field-required" /> Campo obrigatório</div>
                            </div>

                            <div className="form-row">
                                <div className="field-block-row">
                                    <div className="input-field block-col ordem-question-id">

                                        { isInsertMode &&
                                        <div className="new-item-order">Novo item será inserido na ordem: <span>{editItem.data.ordem}</span></div>
                                        }

                                        <div className="field-group input-field item-visibility">
                                            <div className="field-label">
                                                <span className="caption">Ocultar na listagem de FAQ</span>
                                                <InformationField info="Item segue acessível quando consutlado por ID" />
                                            </div>
                                            <div className="visibility-toggle">
                                                <ToggleButton
                                                    id="hideFromListId"
                                                    value={formFields["hideFromList"].value}
                                                    onToggle={() => onFieldChange({name: "hideFromList", value: formFields["hideFromList"].value ? false : true})}
                                                /> 
                                            </div>
                                        </div>

                                        <div className="fld-question-id">
                                            <InputField
                                                id="questionId"
                                                name="questionId"
                                                label="ID Pergunta"
                                                placeHolder="Informe ID Pergunta"
                                                floatingPlaceHolder={false}
                                                afterLabel={<InformationField info="Opcional, utilizado para consultas direcionadas pela aplicação, não utilizado no uso geral do FAQ." />}
                                                maxSize={40}
                                                autoComplete="off"
                                                value={formFields["questionId"].value}
                                                errorMessage={formFields["questionId"].errorMessage}
                                                onChange={e => onFieldChange({name: e.target.name, value: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="block-col form-action">
                                        <button type="button" disabled={!isFormChanged} className="btn-save" onClick={e => onEditSave(e)}>Salvar</button>
                                    </div>

                                </div>
                            </div>
                            
                            <div className="form-row">
                                <div className="textarea-field fld-resposta">
                                    <TextareaField 
                                        id="titulo"
                                        name="titulo"
                                        label="Pergunta/Título"
                                        requiredMark={true}
                                        value={formFields["titulo"].value}
                                        errorMessage={formFields["titulo"].errorMessage}
                                        counter={{
                                            top: true,
                                            minCount: fieldMinSize.titulo,
                                            maxCount: 500
                                        }}
                                        rows={3}
                                        onChange={e => onFieldChange({name: e.target.name, value: e.target.value})} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="textarea-field fld-resposta">
                                    <TextareaField 
                                        id="resposta"
                                        name="resposta"
                                        label="Resposta"
                                        requiredMark={true}
                                        value={formFields["resposta"].value}
                                        errorMessage={formFields["resposta"].errorMessage}
                                        counter={{
                                            top: true,
                                            minCount: fieldMinSize.resposta,
                                            maxCount: 1500
                                        }}
                                        rows={4}
                                        onChange={e => onFieldChange({name: e.target.name, value: e.target.value})} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="input-field">
                                    <InputField
                                        id="tags"
                                        name="tags"
                                        label="Assuntos/Tags"
                                        placeHolder="Informe os assuntos/tags"
                                        floatingPlaceHolder={false}
                                        afterLabel={<InformationField info="Multiplos assuntos/tags devem ser separados por ponto e vírgula ';'" />}
                                        maxSize={75}
                                        autoComplete="off"
                                        requiredMark={true}
                                        value={formFields["tags"].value}
                                        errorMessage={formFields["tags"].errorMessage}
                                        onChange={e => onFieldChange({name: e.target.name, value: e.target.value})} />
                                </div>
                            </div>

                        </form>

                        { formError && 
                        <AppExternalServiceInfoMessage id="msgEditErrorID" className="info-error">
                            {formError}
                        </AppExternalServiceInfoMessage>
                        }

                    </div>
                    }

                    { previewedData &&
                    <div className="faq-preview-wrapper">
                        <div className="preview-content">
                            <FaqContent 
                                listFaq={[ previewedData ]}
                                hideTags={false}
                                hideImages={false}
                                onAddTag={null} />
                        </div>
                    </div>
                    }
                </div>
            </AppModal>
        </div>
    );
}

export default FaqEditModal;