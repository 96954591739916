/*
** @name: Meu Clínicas - appServiceList
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Provém objeto com constantes das lista dos serviços para padronização na aplicação. 
** Reliza a valização contra as configurações da aplicação
**
** @date: Junho 2021, Julho 2021, Agosto 2021
** @description: Adição de novos módulos
**
** @date: Janeiro 2022
** @description: Criada separacao de modulos: requeridos (configuracao obrigatoria) e extras (configuracao opcional)
**
** @update: Agosto 2024
** @description: Removido validação de serviços requeridos (passou para <App />)
*/

const APP_REQUIRED_SERVICES = {
    ALTERAR_SENHA: "alterarSenha",
    CADASTRO: "cadastro",
    CONFIRMAR_TELEFONE: "confirmarTelefone",
    FAQ: "faq",
    INFORMAR_LOCALIZADOR: "informarLocalizador",
    LOGOUT: "logout",
    RECUPERAR_SENHA: "recuperarSenha"
}

const APP_EXTRA_SERVICES = {
    AGENDAMENTO_CONSULTAS: "agendamentoConsultas",
    ALL_SERVICES: "allServices",
    CARTAO_PACIENTE: "cartaoPaciente",
    COMPARTILHAR_ESTUDO: "compartilharEstudo",
    CONSULTAS: "consultas",
    EDITAR_DADOS: "editarDados",
    ETIQUETAS_MATERIAIS: "etiquetasMateriais",
    EXAMES: "exames",
    LAUDOS_ATESTADOS: "laudosAtestados",
    MEUS_DADOS: "meusDados",
    OBTER_LOCALIZADOR: "obterLocalizador",
    PESQUISA_EXPERIENCIA: "pesquisaExperiencia",
    REAGENDAMENTO_CONSULTA_CANCELADA_COVID: "reagendamentoConsultaCanceladaCovid",
    RECEITAS: "receitas",
    REQUERIMENTO_DOCUMENTOS_PRONTUARIO: "requerimentoDocumentosProntuario",
    SOLICITACAO_AGENDAMENTO_CONSULTA: "solicitacaoAgendamentoConsulta",
    TELEATENDIMENTO: "teleatendimento",
    TERMO_CONSENTIMENTO: "termoConsentimento",
    VINCULAR_CADASTRO: "vincularCadastro"
}

const APP_SERVICE_LIST = {
    ...APP_REQUIRED_SERVICES,
    ...APP_EXTRA_SERVICES
}

const getAppServiceIdByConfigName = (cfgName) => {
    return Object.keys(APP_SERVICE_LIST).find(key => APP_SERVICE_LIST[key] === cfgName);
}

export { 
    APP_REQUIRED_SERVICES,
    APP_SERVICE_LIST,
    getAppServiceIdByConfigName
};