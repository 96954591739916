/*
** @name: Meu Clínicas - utils
** @author:
** @date:
**
** @description: Conjunto de rotinas utilitárias para uso geral na aplicação
** @update: Dezembro 2020 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Ampliado com 20 novas rotinas de uso geral (Token, Scrollbars, Loading, Card, App, Fromatação, etc...)
*/

import $ from 'jquery';
import moment from 'moment';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import sessionStorageManager from './sessionStorageManager.js';
import { loadingTools } from '../components/general/appLoading/appLoading.js';

const AUTOMATIC_CLOSE_TIMEOUT = 5000;
const EMPTY_HREF = "#";
const MAX_SYNC_TIME_MS = 900000; // 15 minutos
const MOBILE_INDICATOR_SELECTOR = "#root .app-viewport.mobile";

class Utils {
    arrayCompare = (a, b) => {
        return genesysUtils.typeCheck.isArray(a) && genesysUtils.typeCheck.isArray(b) &&
                a.length === b.length && a.every((elem, indx) => elem === b[indx]);
    }

    buildEpoch = () => {
        const m = process.env.REACT_APP_BUILD_MOMENT;
        if(m && m.match(/^\d+$/)) {
            return parseInt(m);
        } else if(this.isDev()) {
            return new Date().getTime();
        }
        return null;
    }

    calculateDateDiff = (initialDate, finalDate, period='days') => {
        const initial = moment(initialDate, 'DD/MM/YYYY');
        const final = moment(finalDate, 'DD/MM/YYYY');

        return final.diff(initial, period, true);
    }

    checkAppVersion(appVersion, requiredVersion) {
        const versionParts = (version) => {
            if(version) {
                const noRC = version.replace(/-RC$/, "");
                const reParts = /^(\d+)\.(\d+)\.(\d+)$/;
                if(noRC.match(reParts)) {
                    const major = parseInt(noRC.replace(reParts, "$1"));
                    const minor = parseInt(noRC.replace(reParts, "$2"));
                    const revision = parseInt(noRC.replace(reParts, "$3"));
                    return ({ major, minor, revision });
                }
            }
            return null;
        }

        const rvParts = versionParts(requiredVersion);
        const cvParts = versionParts(appVersion);
        if(!cvParts || !rvParts) {
            return null;
        }

        // check major version
        if(cvParts.major > rvParts.major) { return true; }
        if(cvParts.major < rvParts.major) { return false; }

        // compare minor version (if major is the same)
        if(cvParts.minor > rvParts.minor) { return true; }
        if(cvParts.minor < rvParts.minor) { return false; }

        // compare revision version (if major and minor are the same)
        if(cvParts.revision >= rvParts.revision) { return true; }
        
        return false;
    }

    convertDateToEpochAtMidday = (date) => {
        let epoch = /^\d{2}\/\d{2}\/\d{4}$/.test(date) ? moment(date + "12:00:00", 'DD/MM/YYYY hh:mm:ss').valueOf() : null;
        return !isNaN(epoch) ? epoch : null;
    }

    checkRequiredFunctionProperty(prop, name, module) {
        if(!genesysUtils.typeCheck.isFunction(prop)) {
            throw new Error(`Missing or invalid '${name}' property for '${module}'.`);
        }
    }

    elementVisibility = (container, elem) => { // retorna percentual do elemento visível em relacção ao container
        if(!container || !elem) {
            return null;
        }
    
        const ch = $(container).height(); // container height
        const yt = $(elem).offset().top - $(container).offset().top; // element top vertical position (relative to container)
        const eh = $(elem).height(); // element height
        const yb = yt + eh; // element bottom vertical position (relative to container)
        const pa = yt < 0 ? Math.abs(yt) : 0; /* pixels above container view */
        const pb = yb > ch ? yb - ch : 0; /* pixels below container view */

        return Math.max(0, (eh-pa-pb)) / eh;
    }    

    formatGuidProtocol = (key) => {
        if(!key) {
            return null;
        }
        while(`${key}`.length < 24) {
            key = "0" + key;
        }
        return key.match(/.{1,6}/g).join("-");
    }

    formatProntuario = (prontuario) => {
        return prontuario ? prontuario.slice(0, (prontuario.length-1)) + '/' + prontuario.slice(-1) : null;
    }

    getPublicPath = slashAsEmpty  => {
        const appPublicUrl = String(process.env.PUBLIC_URL || "").replace(/\/+$/g, '') || "/";
        return (slashAsEmpty && appPublicUrl === '/') ? "" : appPublicUrl;
    }

    goToHome() {
        window.location = process.env.REACT_APP_LOGIN_ADDRESS;
    }

    scrollAppCardModuleContentTo = (top) => {
        const moduleContent = document.getElementById("appCardModuleContentId");
        if(moduleContent) {
            moduleContent.scrollTo(0, (top ? top : 0));
        }
    }

    setAutomaticCardClose(appControllerContext, moduleName, timeout) {
        if(appControllerContext) {
            timeout = genesysUtils.typeCheck.isInteger(timeout) && timeout > 0 ? timeout : AUTOMATIC_CLOSE_TIMEOUT;
            return setTimeout(() => appControllerContext.methods.doCardFadeOut(moduleName), timeout);
        }
    }

    setLoadingVisibility(visible, appControllerContext) {
        if(appControllerContext && appControllerContext.methods) {
            if(visible) {
                appControllerContext.methods.doShowLoading();
            } else {
                appControllerContext.methods.doHideLoading();
            }
        } else {
            if(visible) {
                this.appLoading.show();
            } else {
                this.appLoading.hide();
            }
        }
    }

    stringToDateBR = (strDate, defaultValue) => {
        if(/^\d{2}\/\d{2}\/\d{4}$/.test(strDate)) {
            var dateParts = strDate.split("/");
            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);    
        }
        return defaultValue ? defaultValue : null;
    }

    updateScrollableArea(container, content) {
        if(container && content) {
            const isMobileView = $(MOBILE_INDICATOR_SELECTOR).length>0 ? true : false;
            const isScrollActive = !isMobileView && genesysUtils.general.isScrollable(container);
            const marginRight = isScrollActive ? `-${Math.min(10, genesysUtils.general.getScrollbarWidth())}px` : "0";
            $(content).css({ "margin-right": marginRight });
        }
    }

    // === General check
    isUserInSyncAwayTime = (userData) => {
        const user = userData ? userData : sessionStorageManager.auth.getUserData();
        const localCriadoEm = (user.criadoEm || 0) + (user.localClockDifference || 0);
        const elapsedTime = (Date.now() - localCriadoEm);

        return (elapsedTime <= MAX_SYNC_TIME_MS);
    }

    isValidDate(strData) {     
        if (!strData) {
            return true;
        }

        var strDataSemMascara = strData.replace(/_/g, '', true);
        if (strDataSemMascara.length !== 10) {
            return false;
        }

        return moment(strData, 'DD/MM/YYYY').isValid();
    }

    // === Environment check
    isDev = () => process.env.REACT_APP_ENVIRONMENT === 'DEV';

    isHml = () => process.env.REACT_APP_ENVIRONMENT === 'HML';

    isProd = () => process.env.REACT_APP_ENVIRONMENT === 'PRD';

    // === App Loading utilities
    appLoading = loadingTools;
}

const utils = new Utils();
export default utils;
export {
    EMPTY_HREF,
    MOBILE_INDICATOR_SELECTOR
}