/*
** @name: Meu Clínicas - anexarArquivos
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Componente para renderizar instrucoes sobre anexar arquivos
*/

import React, { useState, useEffect } from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import FaqContent from '../../modules/faq/faqContent.js';


const AnexarArquivos = (props) => {
    const { instrucoes, onAvancar, onAnexar } = props;
    const [ instructionsContent, setInstructionsContent ] = useState(null);

    useEffect(() => { // ativar item faq
        if(genesysUtils.typeCheck.isArray(instrucoes)) {
            instrucoes[0].active = true;
            setInstructionsContent([ instrucoes[0] ]);
        }
    }, [instrucoes]);

    return(
        <div className='instrucoes-wrapper'>
            { instructionsContent &&
            <div className='instrucoes-box'>
                <FaqContent 
                    listFaq={instructionsContent}
                    disableAutoPlay={false}
                    hideTitle={true}
                    hideTags={true}
                    hideAnswer={true}
                    hideImages={false}
                    onAddTag={null}
                />
            </div>
            }

            { instructionsContent &&
            <div className='main-action'>
                { onAnexar &&
                <button type="button" className='app-form-button' onClick={onAnexar}>Anexar Arquivos</button>
                }
                <button type="button" className="app-form-button" onClick={onAvancar}>Avançar</button>
           </div>
            }
        </div>
    );
}

export default AnexarArquivos;
