import React from 'react';


// Import module styles
import './toggleButton.scss'; 


const ToggleButton = (props) => {
    const { id, className, disabled, onToggle } = props;
    const switchId = id ? id : "switch";

    const _handleToggle = () => {
        if(!disabled && onToggle) {
            onToggle();
        }
    }

    return(
        <div className={`toggle-button switch__container${className ? ` ${className}` : ""}${disabled ? " disabled" : ""}`}>
            <input                          
                id={switchId}
                className="switch switch--shadow" 
                type="checkbox" 
                checked={props.value ? true : false}
                onChange={() => {}}
            />
            <label htmlFor={switchId} onClick={_handleToggle}></label>
        </div>                
    );  
}

export default ToggleButton;
