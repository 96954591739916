/*
** @name: Meu Clínicas - appMessageDialog
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para construir uma caixa de diálogo modal customizada com dois botões. Aceita 
** definir descrição dos botões, cabecalho, texto, ação dos botões.
*/

import React, { Component } from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';


// Import module styles
import './appMessageDialog.scss';


const ESCAPE_KEY = 27;

const MessageDialogWrapper = (props) => {
    const { wrapper, wrapperClassName, children } = props;
    if(!wrapper) {
        return <>{children}</>;
    }
    let CustomWrapper;
    if(genesysUtils.typeCheck.isFunction(wrapper)) {
        CustomWrapper = wrapper;
    } else {
        CustomWrapper = (props) => <div id="appMessageDialogWrapperID" className={wrapperClassName}>{props.children}</div>
    }
    return(
        <CustomWrapper>
            {children}
        </CustomWrapper>
    );
}

class AppMessageDialog extends Component {
    constructor(props) {
        super(props);

        this.state = { }
    }

    _handleKeyDown = (event) => {
        if(!this.props.ignoreEsc) {
            switch( event.keyCode ) {
                case ESCAPE_KEY:
                    event.stopPropagation();
                    event.preventDefault();
                    if(this.props.buttonTwoClick) {
                        this.props.buttonTwoClick();
                    } else if(this.props.buttonOneClick) {
                        this.props.buttonOneClick();
                    }
                    break;
                default: 
                    break;
            }
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this._handleKeyDown, true);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown, true);
    }

    render() {
        const btnClass = `${this.props.buttonClass ? `${this.props.buttonClass} ` : ""}btn-autosize`;

        return(
            <MessageDialogWrapper
                wrapper={this.props.wrapper}
                wrapperClassName={this.props.wrapperClassName}
            >
                <div id="appMessageDialogBoxID" className={this.props.className}>
                    <div className="dialog-header">{this.props.header}</div>
                    <div className="dialog-content">
                        <div className={this.props.titleClass}>{this.props.title}</div>
                        <div className={this.props.messageClass}>{this.props.message}</div>
                        <div className="dialog-actions">
                            { this.props.buttonOneClick && 
                            <button 
                                type="button" 
                                className={btnClass}
                                onClick={this.props.buttonOneClick} >{this.props.buttonOneText ? this.props.buttonOneText : "Ok"}</button>
                            }
                            { this.props.buttonTwoClick && 
                            <button 
                                type="button" 
                                className={btnClass + (this.props.buttonOneClick ? " btn-separator" : "")}
                                onClick={this.props.buttonTwoClick} >{this.props.buttonTwoText ? this.props.buttonTwoText : "Cancelar"}</button>
                            }
                        </div>
                    </div>
                </div>
            </MessageDialogWrapper>
        );
    }
}

export default AppMessageDialog;