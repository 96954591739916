/*
** @name: Meu Clínicas - viewerActionButtons
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para renderizar barra de ações na visualização
*/
import React from 'react';

const ViewerActionButtons = (props) => {
    const { dispensacaoPermitida, isVisibleFormDispensacao, onGetPdf, onDispensar } = props;
    const disableDownload = dispensacaoPermitida || isVisibleFormDispensacao;
    const disabledRegistro = !dispensacaoPermitida || isVisibleFormDispensacao;

    return(
        <div className="panel-action-buttons">
            { onGetPdf && 
            <button 
                type="button" 
                className="btn-servicos btn-pdf"
                disabled={disableDownload}
                onClick={onGetPdf} >Abrir PDF</button>
            }
            { onDispensar && 
            <button 
                type="button" 
                className="btn-servicos btn-dispensa"
                disabled={disabledRegistro}
                onClick={onDispensar} >Registrar dispensação</button>
            }
        </div>
    );
}

export default ViewerActionButtons;
