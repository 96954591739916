import React from "react";

import { HangupButton, UploadButton } from "./customToolbarButtons.js";


const CustomToolbar = (props) => {
    const { visible, onHangup, onUpload } = props;
    const active = onUpload || onHangup;
    if(!active) {
        return null;
    }
    return(
        <div className={`custom-toolbar${visible ? " visible" : ""}`}>
            { onUpload && <UploadButton onClick={onUpload} /> }
            { onHangup && <HangupButton onClick={onHangup} /> }
        </div>
    );
}

export default CustomToolbar;