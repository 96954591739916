/*
** @name: Meu Clínicas - camshotFrame
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Janeiro 2021
** @description: Componente wrapper para componente 'react-responsive-carousel' com configuracoes usadas na aplicação
** FAQ e notícias
*/

import React, { Fragment } from 'react';
import { Carousel }  from 'react-responsive-carousel';

import { EMPTY_HREF } from '../../../core/utils.js';

import { useAppControllerContext } from '../../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppServiceIdByConfigName } from '../../../core/appServiceList.js';
import { getAppGeneralSettingsPropertyByName } from '../../../core/appSpecificConfigHandler.js';


// Import module styles
import './imageCarousel.scss'; 


const ALLOWED_IMAGES = {
    "image/png": { sig: "iVBORw", allowed: true }, 
    "image/jpeg": { sig: "/9j/4AAQSkZJRg", allowed: true },
    "image/gif": { sig: "R0lGODl", allowed: false } 
};


const _countAvailableImages = (imageList) => {
    let count = 0;
    if(imageList && imageList.constructor===Array) {
        imageList.forEach((image) => {
            if(isValidImageType(image.imageBase64)) {
                count++;
            }
        });
    }
    return count;
}

const _getModuleData = (imgData) => {
    if(!imgData.link) {
        if(getAppServiceIdByConfigName(imgData.openModule)) {
            let moduleParam = null;
            try { moduleParam = JSON.parse(imgData.paramModule) } catch(e) {}
            return {
                moduleId: imgData.openModule,
                moduleParam
            }
        }
    }
    return {};
}

const getImageType = (imgB64) => {
    if(!imgB64) {
        return null;
    }
    return Object.keys(ALLOWED_IMAGES).find(k => ALLOWED_IMAGES[k].sig && ALLOWED_IMAGES[k].sig===imgB64.substr(0, ALLOWED_IMAGES[k].sig.length));
}

const isValidImageType = (imgB64) => {
    return imgB64 && Object.values(ALLOWED_IMAGES).find(ai => ai.allowed && ai.sig && ai.sig===imgB64.substr(0, ai.sig.length)) ? true : false;
}

const ImageCarousel = (props) => {
    const { keyBase, imageList, interval, disableAutoPlay } = props;
    const appContextConfig = useAppConfigContext().getContextConfig();
    const appControllerContext = useAppControllerContext();
    const availableImages = _countAvailableImages(imageList);
    const allowAutoPlay = getAppGeneralSettingsPropertyByName(appContextConfig, "allowCarouselAutoPlay");
    const autoPlay = (!disableAutoPlay && allowAutoPlay && (availableImages > 1)) ? true : false;
    const emptyFunction = () => {};
    if(availableImages < 1) {
        return null;
    }

    return(
        <div className="carousel-wrapper">
            <Carousel
                statusFormatter={(current, total) => `${total > 1 ? `${current}/${total}` : ""}`}
                onChange={emptyFunction}
                onClickItem={emptyFunction}
                onClickThumb={emptyFunction}
                autoPlay={autoPlay}
                dynamicHeight={true}
                infiniteLoop={autoPlay}
                showArrows={true}
                showStatus={true}
                showIndicators={true}
                showThumbs={false}
                useKeyboardArrows={true}
                stopOnHover={true}
                swipeable={true}
                emulateTouch={true}
                thumbWidth={100}
                selectedItem={0}
                interval={interval ? interval : 3000}
                transitionTime={500}
                preventMovementUntilSwipeScrollTolerance={false}
                swipeScrollTolerance={5}
            >
                {
                    imageList.map((image, indxImage) => {
                        const base64 = image.imageBase64;
                        const imageType = getImageType(base64);
                        if(!isValidImageType(base64) || !imageType) {
                            return null;
                        }

                        const imageData = `data:${imageType};base64,${base64}`;
                        const legenda = image.legenda ? image.legenda : null;
                        const link = image.link ? image.link : null;
                        const linkTarget = image.linkTarget ? image.linkTarget : "_blank";
                        const { moduleId, moduleParam } = _getModuleData(image);
                        const sliderContent =
                            <div className="crousel-image-wrapper">
                                <img src={imageData} alt="" />
                                { legenda &&
                                <p className="legend">{legenda}</p>
                                }
                            </div>;
                        const anchorParams = (!link && !moduleId) ? null : {
                            href: link ? link : EMPTY_HREF,
                            rel: "noopener noreferrer",
                            target: link ? linkTarget : undefined,
                            onClick: moduleId ? () => appControllerContext.methods.doAddCardModule(moduleId, moduleParam, false) : undefined
                        }
                        return(
                            <Fragment key={`${keyBase}_img_${indxImage}`}>
                                { anchorParams ? <a {...anchorParams}>{sliderContent}</a> : sliderContent }
                            </Fragment>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}
export default ImageCarousel;

export {
    getImageType,
    isValidImageType
}
