import React from 'react';

import ToggleButton from '../../components/fields/toggleButton/toggleButton.js';


const ManterConectado = (props) => {
    const { visible, value, onToggle } = props;

    if(!visible) {
        return null;
    }

    return(
        <div className="manter-conectado">
            <div className="wrapper">
                <div>
                    <ToggleButton 
                        id="mcSwitchId"
                        value={value}
                        onToggle={onToggle} /> 
                </div>
                <label>Mantenha-me conectado</label>
            </div>
        </div>
    );  
}

export default ManterConectado;
