import React from "react";
import { TextArea } from 'semantic-ui-react';
import { getFieldContentClasses, FormBuilderSupportFields } from 'react-dj-forms-builder';


const TextareaField = (props) => {
    const { id, name, label, placeHolder, disabled, rows, counter, requiredMark, onChange, value, errorMessage } = props;
    const inputClassName = errorMessage ? "error" : "";
    const enableCounter = counter && (counter.top || counter.bottom) && counter.maxCount;
    const currentCount = value ? value.length : 0;

    return(
        <>
            { label &&
            <div className="field-label">{label}{requiredMark ? <FormBuilderSupportFields.RequiredMark /> : null}</div>
            }
            <div className={`input-wrapper ${getFieldContentClasses(value, errorMessage, disabled)} ${enableCounter ? "with-counter" : "no-counter"}`}>
                { enableCounter && counter.top &&
                <FormBuilderSupportFields.Counter id={`counter_${id}`} current={currentCount} min={counter.minCount} max={counter.maxCount} />
                }
                <TextArea 
                    id={id}
                    name={name}
                    disabled={disabled}
                    className={inputClassName}
                    placeholder={placeHolder}
                    rows={rows}
                    onChange={(e) => onChange(e, {name: name, value: e.target.value})}
                    value={value ? value : ""}
                />
                { enableCounter && counter.bottom &&
                <FormBuilderSupportFields.Counter id={`counter_${id}`} current={currentCount} min={counter.minCount} max={counter.maxCount} />
                }
            </div>
            { errorMessage &&
            <div className="field-error">{errorMessage}</div>
            }
        </>
    );
}

export default TextareaField;