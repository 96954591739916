import React from 'react';

import { getImageThemeContext, isAppServiceEnabled } from '../../core/appSpecificConfigHandler.js';
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';


const ExtraActions = (props) => {
    const { appContextConfig, onVoltar, onCadastro, onRecuperarSenha, onAjuda } = props;

    return (
        <div className="extra-actions">
            { onVoltar && 
            <div className="button-wrapper">
                <AppCustomImage imageContextFn={getImageThemeContext} 
                    module="login"
                    imageId="icon_voltar"
                    className="action-image" 
                    onClick={onVoltar} />
            </div>
            }

            { (onCadastro && isAppServiceEnabled(appContextConfig, APP_SERVICE_LIST.CADASTRO)) && 
            <div className="button-wrapper">
                <AppCustomImage imageContextFn={getImageThemeContext} 
                    module="login"
                    imageId="icon_cadastre-se"
                    className="action-image" 
                    onClick={onCadastro} />
            </div>
            }

            { (onRecuperarSenha && isAppServiceEnabled(appContextConfig, APP_SERVICE_LIST.RECUPERAR_SENHA)) &&
            <div className="button-wrapper">
                <AppCustomImage imageContextFn={getImageThemeContext} 
                    module="login"
                    imageId="icon_recuperar-senha"
                    className="action-image" 
                    onClick={onRecuperarSenha} />
            </div>
            }

            { (onAjuda && isAppServiceEnabled(appContextConfig, APP_SERVICE_LIST.FAQ)) && 
            <div className="button-wrapper">
                <AppCustomImage imageContextFn={getImageThemeContext}
                    module="login"
                    imageId="icon_ajuda"
                    className="action-image"
                    onClick={onAjuda} />
            </div>
            }
        </div>  
    );
}

export default ExtraActions;