/*
** @name: Meu Clínicas - appCardModuleConfig
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2021
** @description: Modulo para centralizar definir as configuracoes dos módulos
*/

import React from 'react';
import { upperCase } from '@hcpa-react-components/string-utils';

import sessionStorageManager from './sessionStorageManager.js';
import { getAppServiceSettingsByName } from './appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from './appServiceList.js';

import AgendamentoConsultas from '../modules/agendamentoConsultas/agendamentoConsultas.js';
import AllServices from '../modules/allServices/allServices.js';
import AlterarSenha from '../modules/alterarSenha/alterarSenha.js';
import Cadastro from '../modules/cadastro/cadastro.js';
import CartaoPaciente from '../modules/cartaoPaciente/cartaoPaciente.js';
import CompartilharEstudo from '../modules/compartilharEstudo/compartilharEstudo.js';
import ConfirmarTelefone from '../modules/confirmarTelefone/confirmarTelefone.js';
import Consultas from '../modules/consultas/consultas.js';
import EditarDados from '../modules/editarDados/editarDados.js';
import EtiquetasMateriais from '../modules/etiquetasMateriais/etiquetasMateriais.js';
import Exames from '../modules/exames/exames.js';
import Faq from '../modules/faq/faq.js';
import InformarLocalizador from '../modules/informarLocalizador/informarLocalizador.js';
import LaudosAtestados from '../modules/laudosAtestados/laudosAtestados.js';
import MeusDados from '../modules/meusDados/meusDados.js';
import ObterLocalizador from '../modules/obterLocalizador/obterLocalizador.js';
import PesquisaExperiencia from '../modules/pesquisaExperiencia/pesquisaExperiencia.js';
import ReagendamentoConsultaCanceladaCovid from '../modules/reagendamentoConsultaCanceladaCovid/reagendamentoConsultaCanceladaCovid.js';
import Receitas from '../modules/receitas/receitas.js';
import RecuperarSenha from '../modules/recuperarSenha/recuperarSenha.js';
import RequerimentoDocumentosProntuario from '../modules/requerimentoDocumentosProntuario/requerimentoDocumentosProntuario.js';
import SolicitacaoAgendamentoConsulta from '../modules/solicitacaoAgendamentoConsulta/solicitacaoAgendamentoConsulta.js';
import Teleatendimento from '../modules/teleatendimento/teleatendimento.js';
import TermoConsentimento, { isRespostaConsentimentoVigente } from '../modules/termoConsentimento/termoConsentimento.js';
import VincularCadastro from '../modules/vincularCadastro/vincularCadastro.js';


const getCardModuleConfig = (authContext, appContextConfig, cardServiceName) => {
    // Get current card
    if(!cardServiceName) {
        return null;
    }

    const serviceConfig = getAppServiceSettingsByName(appContextConfig, cardServiceName);
    if(!serviceConfig) {
        sessionStorageManager.navigation.clear();
        throw new Error(`No service configuration for '${cardServiceName}' in 'appController'.`);
    }

    const user = authContext.properties.user;
    const cardConfig = { };
    cardConfig.cardSchema = serviceConfig.cardSchema;
    switch(cardServiceName) {
        case APP_SERVICE_LIST.AGENDAMENTO_CONSULTAS:
            cardConfig.module = <AgendamentoConsultas />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = { };
            break;
        case APP_SERVICE_LIST.ALL_SERVICES:
            cardConfig.module = <AllServices />;
            cardConfig.title = serviceConfig.moduleTitle || `OLÁ, ${upperCase(user.nome)}`;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.ALTERAR_SENHA:
            cardConfig.module = <AlterarSenha />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.CADASTRO:
            cardConfig.module = <Cadastro />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = { 
                tagList: ["cadastro"]
            };
            break;
        case APP_SERVICE_LIST.CARTAO_PACIENTE:
            cardConfig.module = <CartaoPaciente />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = null;
            break;
        case APP_SERVICE_LIST.COMPARTILHAR_ESTUDO:
            cardConfig.module = <CompartilharEstudo />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = { };
            break;
        case APP_SERVICE_LIST.CONFIRMAR_TELEFONE:
            cardConfig.module = <ConfirmarTelefone />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.CONSULTAS:
            cardConfig.module = <Consultas />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  
                tagList: ["consultas", "agendamento"]
            };
            break;
        case APP_SERVICE_LIST.EDITAR_DADOS:
            cardConfig.module = <EditarDados />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.ETIQUETAS_MATERIAIS:
            cardConfig.module = <EtiquetasMateriais />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {
                tagList: ["etiquetas", "materiais"]
            };
            break;
        case APP_SERVICE_LIST.EXAMES:
            cardConfig.module = <Exames />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {
                tagList: ["exames"]
            };
            break;
        case APP_SERVICE_LIST.FAQ:
            cardConfig.module = <Faq />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = null;
            break;
        case APP_SERVICE_LIST.INFORMAR_LOCALIZADOR:
            cardConfig.module = <InformarLocalizador />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.LAUDOS_ATESTADOS:
            cardConfig.module = <LaudosAtestados />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.MEUS_DADOS:
            cardConfig.module = <MeusDados />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.OBTER_LOCALIZADOR:
            cardConfig.module = <ObterLocalizador />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.PESQUISA_EXPERIENCIA:
            cardConfig.module = <PesquisaExperiencia />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.REAGENDAMENTO_CONSULTA_CANCELADA_COVID:
            cardConfig.module = <ReagendamentoConsultaCanceladaCovid />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.RECEITAS:
            cardConfig.module = <Receitas />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {
                tagList: ["receitas"]
            };
            break;
        case APP_SERVICE_LIST.RECUPERAR_SENHA:
            cardConfig.module = <RecuperarSenha />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.REQUERIMENTO_DOCUMENTOS_PRONTUARIO:
            cardConfig.module = <RequerimentoDocumentosProntuario />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.SOLICITACAO_AGENDAMENTO_CONSULTA:
            cardConfig.module = <SolicitacaoAgendamentoConsulta />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            break;
        case APP_SERVICE_LIST.TELEATENDIMENTO:
            cardConfig.module = <Teleatendimento />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  
                tagList: ["teleatendimento", "teleconsulta", "consulta remota"]
            };
            break;
        case APP_SERVICE_LIST.TERMO_CONSENTIMENTO:
            cardConfig.module = <TermoConsentimento />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = {  };
            cardConfig.disableCardClose = (isRespostaConsentimentoVigente(user)===false);
            break;
        case APP_SERVICE_LIST.VINCULAR_CADASTRO:
            cardConfig.module = <VincularCadastro />;
            cardConfig.title = serviceConfig.moduleTitle;
            cardConfig.helpContext = null;
            cardConfig.disableCardClose = false;
            break;
        default:
            sessionStorageManager.navigation.clear();
            throw new Error(`Invalid module '${cardServiceName}' to open in 'appController'.`);
        }

    return cardConfig;
}

export {
    getCardModuleConfig
};