/*
** @name: Meu Clínicas - sectionEstudos
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Componente para renderizar seção de estudos/Imagens na listagem da tela de exames
*/

import React, { Fragment } from 'react';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from "@hcpa-react-components/genesys-utils";
import { pascalCase } from '@hcpa-react-components/string-utils';

import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';


const SectionEstudos = (props) => {
    const { groupIndex, estudosPACS, existeEstudoNovo, enableShare, enableRevokeShare, checkStatusNovo, onView, onShare, onRevokeShare } = props;

    if(!genesysUtils.typeCheck.isArray(estudosPACS)) {
        return <></>;
    }

    return(
        <>
            <div className={`content-row${existeEstudoNovo ? " novo" : ""}`}>
                <div className="titulo-resultados">IMAGENS</div>
            </div>

            { estudosPACS.map((estudo, index) => {
                const indicativoNovoEstudo = checkStatusNovo(estudo.statusLeituraEstudo);
                const habilitarRevogarCompartilhamento = enableRevokeShare && estudo.contadorCompartilhamento > 0;
                
                return(
                    <Fragment key={`estudoId_${groupIndex}-${index}`}>
                        <div className={`content-row${indicativoNovoEstudo ? " novo" : ""}`}>
                            <div className="estudos-wrapper">
                                <div className="buttons-wrapper">
                                    <button 
                                        type="button"
                                        className="btn-visualizar"
                                        title="Visualizar imagens"
                                        onClick={() => onView(estudo)}>
                                        <AppCustomImage imageContextFn={getImageThemeContext} 
                                            module="exames"
                                            imageId={`icon_botao-visualizar${indicativoNovoEstudo ? "-new" : ""}`}
                                            className="img-buttomimg" />

                                    </button>
                                    { enableShare && 
                                    <button 
                                        type="button"
                                        className="btn-compartilhar"
                                        title="Compartilhar imagens"
                                        onClick={() => onShare(estudo)}>
                                        <AppCustomImage imageContextFn={getImageThemeContext}
                                            module="exames"
                                            imageId={`icon_compartilhar${indicativoNovoEstudo ? "-new" : ""}`}
                                            className="img-buttomimg" />
                                    </button>
                                    }
                                    { habilitarRevogarCompartilhamento && 
                                    <button 
                                        type="button"
                                        className="btn-revogar"
                                        title="Remover compartilhamentos"
                                        onClick={() => onRevokeShare(estudo)}>
                                        <AppCustomImage imageContextFn={getImageThemeContext}
                                            module="exames"
                                            imageId={`icon_cancelar-compartilhamento${indicativoNovoEstudo ? "-new" : ""}`}
                                            className="img-buttomimg" />
                                    </button>
                                    }
                                </div>   
                                <div className="info-wrapper">
                                    <div className="descricao-estudo">
                                        <span className="bold-text">Descrição:</span><br/>
                                        {pascalCase(estudo.descricao)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        { estudo.visualizacaoError &&
                        <div className="content-row">
                            <div className="download-error">{estudo.visualizacaoError}</div>
                        </div>
                        }
                    </Fragment>
                );
            })}
        </>
    );
}

export default SectionEstudos;