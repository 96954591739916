/*
** @name: Meu Clínicas - authContext
** @author: 
** @date:
** @description: Prove contexto de autenticação utilizando do controle de sessão da aplicação
*/

import React, { useContext, useState } from 'react';

import sessionStorageManager from './sessionStorageManager.js';


const AuthContext = React.createContext();
const AuthContextConsumer = AuthContext.Consumer;

const AuthContextProvider = (props) => {
    const [token, setToken] = useState(sessionStorageManager.auth.getToken());
    const [fingerprint, setFingerprint] = useState(sessionStorageManager.auth.getFingerprint());
    const [user, setUser] = useState(sessionStorageManager.auth.getUserData());
    const [lifetimeData, setLifetimeData] = useState(sessionStorageManager.auth.getTokenLifetimeData());

    const isAuthenticated = () => {
        return token !== null 
            && fingerprint !== null
            && user !== null;
    }

    const refresh = () => {
        setToken(sessionStorageManager.auth.getToken());
        setFingerprint(sessionStorageManager.auth.getFingerprint());
        setUser(sessionStorageManager.auth.getUserData());
        setLifetimeData(sessionStorageManager.auth.getTokenLifetimeData());
    }

    const context = {
        properties: {
            token: token,
            fingerprint: fingerprint,
            user: user,
            lifetimeData: lifetimeData
        },
        
        methods: {
            refresh: refresh,
            isAuthenticated: isAuthenticated
        }
    }

    return (
        <AuthContext.Provider value={context} >
            {props.children}
        </AuthContext.Provider>
    );
}

const useAuthContext = () => {
    const state = useContext(AuthContext);
    return {
        properties: state.properties,
        methods: state.methods
    };
}

export { 
    AuthContextConsumer, 
    AuthContextProvider, 
    useAuthContext 
};
