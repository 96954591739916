import React from 'react';
import { Form } from 'semantic-ui-react';

import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import ExtraActions from './extraActions.js';
import MainButton from './mainButton.js';
import ManterConectado from './manterConectado.js';
import WelcomeMessage from './welcomeMessage.js';


const StepPerguntas = (props) => {
    const { appContextConfig, questions, disableActionButton, manterConectado, showManterConectado, errorMessage,
            onManterConectadoToggle, onActionButton, onVoltar, onAjuda } = props;
    const isLast = questions.current >= (questions.in_use.length-1);
    const questionName = questions.current === null ? '' : questions.in_use[questions.current];
    const question = questions[questionName];
    const button = isLast ? 'entrar' : 'proximo';
    const buttonText = isLast ? 'Entrar' : 'Próximo';
    
    return(
        <Form className="login-form perguntas">
            <WelcomeMessage 
                title="Bem Vindo!" 
                message={<AppCustomMessage messageId="login_welcome-step-questions" />}
            />
            
            { (errorMessage=== null) && question && question.fieldRender(question.value, questionName) }

            { (errorMessage === null) 
                ?
                <>
                    <ManterConectado visible={showManterConectado} value={manterConectado} onToggle={onManterConectadoToggle} />
                    <MainButton
                        id={`button-${button}`} 
                        type="default"
                        wrapperClassName="main-action"
                        className="app-form-button"
                        disabled={disableActionButton}
                        onClick={onActionButton}
                        text={buttonText}
                    />
                </>
                :
                <MainButton
                    id="button-voltar" wrapperClassName="main-action voltar" className="app-form-button"
                    disabled={false} onClick={onVoltar} text="Voltar" />
            }

            <ExtraActions 
                appContextConfig={appContextConfig}
                onVoltar={errorMessage===null ? onVoltar : null} 
                onCadastro={null} onRecuperarSenha={null} onAjuda={onAjuda} />

            { errorMessage ?
            <AppMessageBox
                id="msg-login-error"
                className="error login-message-box"
                messageData={errorMessage} />
            :
            <div className="login-message-box"></div>
            }
        </Form>
    );
}

export default StepPerguntas;
