/*
** @name: Meu Clínicas - sectionSolicitacao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Componente para renderizar seção da solicitacao na listagem da tela de exames
*/

import React from 'react';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from "@hcpa-react-components/genesys-utils";
import { pascalCase } from '@hcpa-react-components/string-utils';

import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';


const SectionSolicitacao = (props) => {
    const { grupo, checkStatusNovo, exibeSituacao, habilitaDownload, onView, onDownloadPdf } = props;

    if(!genesysUtils.typeCheck.isObject(grupo)) {
        return <></>;
    }

    const indicativoNovoSolic = checkStatusNovo(grupo.statusLeituraSolicitacao);
    return(
        <div className={`content-row${habilitaDownload ? `${indicativoNovoSolic ? " novo" : ""}` : " disabled"}`}>
            <div className="titulo-solicitacao">SOLICITAÇÃO/ PREPARO</div>
            <div className="solicitacao-wrapper">
                <div className="buttons-wrapper">
                    <button
                        disabled={!habilitaDownload}
                        type="button"
                        className={`btn-visualizar${habilitaDownload ? "" : " disabled"}`}
                        title="Visualizar solicitação"
                        onClick={() => onView(grupo)}>
                        <AppCustomImage imageContextFn={getImageThemeContext}
                            module="exames"
                            imageId={`icon_botao-visualizar${indicativoNovoSolic ? "-new" : ""}`}
                            className="img-buttomimg" />
                    </button>
                    <button
                        disabled={!habilitaDownload}
                        type="button"
                        className={`btn-baixar-pdf${habilitaDownload ? "" : " disabled"}`}
                        title="Baixar arquivo da solicitação"
                        onClick={() => onDownloadPdf(grupo)}>
                        <AppCustomImage imageContextFn={getImageThemeContext}
                            module="exames"
                            imageId={`icon_botao-pdf${indicativoNovoSolic ? "-new" : ""}`}
                            className="img-buttomimg" />
                    </button>
                </div>
                <div className="info-wrapper">
                    <div className="especialidade-exame"><span className="bold-text">Especialidade:</span> { pascalCase(grupo.especialidade) }</div>
                    <div className="solicitante-exame"><span className="bold-text">Solicitante:</span> { pascalCase(grupo.nomeSolicitante) }</div>
                </div>
            </div>
            { exibeSituacao &&
            <div className="situacao-solicitacao">
                <span className="bold-text">Situação:</span> { pascalCase(grupo.situacaoDescricao) }
            </div>
            }
            { (grupo.dadosSolicitacao && grupo.dadosSolicitacao.existeItemAgendado) &&
                <div className="mensagem-item-agendado"><strong>ATENÇÃO:</strong> existe agendamento para esta solicitacao</div>
            }
            { grupo.downloadError &&
            <div className="download-error">{grupo.downloadError}</div>
            }
        </div>
    );
}

export default SectionSolicitacao;