/*
** @name: Meu Clínicas - receitaValidada
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2023
** @description: Renderiza a eceita validada e controles
*/

import React, { useState } from 'react';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import ReceitaView from '../../components/general/receitaView/receitaView.js';
import ViewerModal from '../../components/general/viewerModal/viewerModal.js';
import Watermark from '../../components/general/watermark/watermark.js';

import FormDispensacao from './formDispensacao.js';
import ModalPdfDownloadError from './modalPdfDownloadError.js';
import ViewerActionButtons from './viewerActionButtons.js';
import ViewerHeader from './viewerHeader.js';


const ReceitaValidada = (props) => {
    const [ isVisibleFormDispensacao, setVisibleFormDispensacao ] = useState(false);
    const { erroDispensacao, resultadoDispensacao, resultadoValidacao, resultadoDownloadPDF, fieldsData,
            onClearDownloadError, onClose, onFieldFocus, onObterPDF, onRegistrarDispensacao } = props;

    const _handleDispensacao = () => {
        setVisibleFormDispensacao(true);
        onFieldFocus("anotacaoDispensacao");
    }

    const _isPeriodoDispensado = (receita) => {
        if(!receita || !receita.grupos || !receita.grupos[0]) {
            return null;
        }

        const dispensacoes = receita.grupos[0].dispensacoesPeriodos;
        const periodoVisualizacao = receita.grupos[0].periodoVisualizacao;
        const registrosPeriodo = dispensacoes ? dispensacoes[periodoVisualizacao] : null;

        return registrosPeriodo ? registrosPeriodo[0] : null;
    }

    const _isReceitaExpirada = (receita) => {
        if(!receita || !receita.grupos || !receita.grupos[0]) {
            return null;
        }

        return receita.grupos[0].receitaExpirada;
    }

    const sucessoDispensacao = resultadoDispensacao && resultadoDispensacao.sucessoDispensacao;
    const receita = resultadoValidacao.receita;
    const validade = receita && receita.grupos && receita.grupos[0] && receita.grupos[0].validade;
    const periodo = validade && (receita.grupos[0].periodoVisualizacao < validade) ? `${receita.grupos[0].periodoVisualizacao}${String.fromCharCode(170)} ` : "";
    const watermarkMessage = _isReceitaExpirada(receita) ? 
            "Receita Expirada" :
            (_isPeriodoDispensado(receita) ? `${periodo}Receita Dispensada` : null);
    
    const origemValidacao = receita.grupos.length === 1 ? "(" + receita.grupos[0].localizador + ")" : null;
    const viewerTitle = <AppCustomMessage elemType="fragment" messageId="validador_viewer-receita-validada" params={[ origemValidacao ]} />

    useState(() => {
        setVisibleFormDispensacao(false);
    }, []);

    return(
        <div className="documento-valido-viwer-wrapper">
            { watermarkMessage &&
            <Watermark message={watermarkMessage} messageClassName="watermark-text" />
            }
            <ViewerModal 
                key={"receita" + (sucessoDispensacao ? "Dispensada" : "") + "ID"}
                header={<ViewerHeader title={viewerTitle} onClose={() => onClose()} />}
                footer={null}
            >
                <ReceitaView 
                    receita={receita} 
                    tooltip={false} 
                    validadaEm={resultadoValidacao.validadaEm}
                    chaveAutenticacao={resultadoValidacao.chaveAutenticacao}
                    enphasisValidacao={true} />
            </ViewerModal>

            { isVisibleFormDispensacao &&
            <FormDispensacao
                fieldsData={fieldsData}
                erroDispensacao={erroDispensacao}
                resultadoDispensacao={resultadoDispensacao}
                onClose={() => setVisibleFormDispensacao(false)}
                onRegistrar={onRegistrarDispensacao}
            />
            }

            <ViewerActionButtons
                dispensacaoPermitida={!_isPeriodoDispensado(receita) && !_isReceitaExpirada(receita)}
                isVisibleFormDispensacao={isVisibleFormDispensacao}
                onGetPdf={() => onObterPDF(receita)}
                onDispensar={() => _handleDispensacao()}
            />

            { resultadoDownloadPDF.error && 
            <ModalPdfDownloadError
                onConfirm={()=> onClearDownloadError()}
                message={resultadoDownloadPDF.message} />
            }
        </div>
    );
}
export default ReceitaValidada;