/*
** @name: Meu Clínicas - viewerHeader
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para renderizar o cabeçalho do visualizador de documento
*/
import React from 'react';

const CloseButton = (props) => {
    const { onClick } = props;
    return(
        <div className="btn-close-wrapper" onClick={onClick} ><div className="close"></div></div>
    );
}

const ViewerHeader = (props) => {
    const { title, onClose } = props;
    return(
        <div className="vwhc-wrapper">
            <div className="vwhc-content">
                <h2>{title}</h2>
            </div>
            <CloseButton onClick={onClose} />
        </div>
    );
}

export default ViewerHeader;