/*
** @name: Meu Clínicas - modalConfirmarRegistroDispensacao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para renderizar modal de confirmação de registro de dispensação
*/
import React from 'react';
import AppMessageDialog from '../../components/general/appMessageDialog/appMessageDialog.js';

const ModalConfirmarRegistroDispensacao = (props) => {
    const { onConfirm, onCancel } = props;
    return(
        <AppMessageDialog
            wrapper={true}
            wrapperClassName="wrapper-confirmacao-dispensacao"
            className="confirmation-box dialog-shadow"
            title="Atenção"
            titleClass="title"
            message="Após o registro da dispensação não será mais possível alterá-lo, indisponibilizando assim, a receita para nova utilização."
            messageClass="message"
            buttonClass="btn-servicos btn-confirmacao"
            buttonOneText="Ok"
            buttonTwoText="Cancelar"
            buttonOneClick={onConfirm}
            buttonTwoClick={onCancel}
        />
    );
}

export default ModalConfirmarRegistroDispensacao;