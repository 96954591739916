/*
** @name: Meu Clínicas - connectionIndicator
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2022
** @description: Componente para renderizar o indicador de conexao no card
*/

import React from 'react';


// Import module styles
import './scss/connectionIndicator.scss'; 


const ConnectionIndicator = (props) => {
    const { connected, visible } = props;

    return(
        <>
        { visible &&
        <div id="connectionIndicatorId" className={`connection-indicator${connected ? ' connected' : ' disconnected'}`}>
            <div className="indicator-box">
                <div className="interior"></div>
            </div>
        </div>
        }
        </>
    );
}

export default ConnectionIndicator;