/*
** @name: Meu Clínicas - tools
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Rotinas de uso geral no componente multiFileSelect
*/
const formatFileSize = sizeBytes => {
    sizeBytes = sizeBytes || 0;
    const unity = sizeBytes >= 10240 ? "Kb" : "bytes";
    const value = sizeBytes >= 10240 ? sizeBytes/1024 : sizeBytes;
    return value.toLocaleString("pt-BR", {useGrouping: false, minimumFractionDigits: 0, maximumFractionDigits: 2}) + ` ${unity}`;
}

export {
    formatFileSize
}