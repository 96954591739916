/*
** @name: Meu Clínicas - mensagens
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Módulo para renderizar blocos de mensagens
*/
import React from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const ActionButtonFormReqDoc = (props) => {
    const { text, onClick, id } = props;
    if((!text || !genesysUtils.typeCheck.isFunction(onClick))) {
        return <></>;
    }
    return (
        <div className="main-action">
            <button id={id} className="app-form-button" onClick={onClick} >{text}</button>
        </div> 
    )
}

const MensagemErroInicializacaoFormReqDoc = (props) => {
    return (
        <>
            <div className="information-section">
                <div className="section-box">
                    <div className="section-content">
                        <AppMessageBox id="rdpMsgErrorId" className="error" messageData={{ message: <AppCustomMessage elemType="fragment" messageId="_general_modulo-indisponivel" /> }} />
                    </div>
                </div>
            </div>

            <ActionButtonFormReqDoc id="rdpBtnErrorCloseId" text="OK" onClick={props.onClick} />
        </>
    )
}

const MensagemSucessoReqDoc = (props) => {
    const { emailAtendimento, emailPaciente, registroProtocolo, registradoEm } = props;
    const registroDataHora = new Date(registradoEm).toLocaleTimeString('pt-BR', { day: 'numeric', month: 'numeric', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit" }).replace(',', '');

    return(
        <>
            <div className="information-section">
                <div className="section-box">
                    <AppCustomMessage id="rdpMsgSucessoId" 
                        elemType="html" messageId="req-doc-prontuario_sucesso" className="section-content"
                        params={[emailPaciente, emailAtendimento, registroProtocolo, registroDataHora]} />
                </div>
            </div>

            <ActionButtonFormReqDoc id="rdpBtnSucessoCloseId" onClick={props.onClick} text="OK" />
        </>
    )
}

export {
    ActionButtonFormReqDoc,
    MensagemErroInicializacaoFormReqDoc,
    MensagemSucessoReqDoc
}