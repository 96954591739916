/*
** @name: Meu Clínicas - visualizaEstudoPACS
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Julho 2021
** @description: Módulo para os serviços externos (páginas fora do fluxo normal da aplicação)
** para exibir compatilhamento de estudosPACS
*/

import React, { Component } from 'react';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';

import examesClient from '../../apiClients/exames/examesClient.js';


// Import module styles
import './visualizaEstudoPACS.scss';


const SERVICE_PATH = '/servicos/imagens/';
const RETRY_MIN_TIME_MS = 30000;

const VisualizaEstudoPACS = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <VisualizaEstudoPACSImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class VisualizaEstudoPACSImplem extends Component {

    constructor(props) {
        super(props);

        const param = this._extractPageInfo(this.props.location.pathname);
        this.state = { 
            token: param,
            tokenError: param ? false : true,
            erroValidacao: null,
            resultadoValidacao: null,
            retryStart: null
        }
    }

    _calculateRemainingTime = (startTime, duration) => {
        if(!startTime) {
            return false;
        }
        return Math.max(0, Math.floor((duration - (new Date().getTime() - startTime))/1000));
    }

    _executeCounterTick = () => {
        const { retryStart } = this.state;
        if(!retryStart) {
            return;
        }
        this.setState({ });
    }

    _extractPageInfo = (currentPath) => {  
        const re = new RegExp("^" + SERVICE_PATH);
        const parameter = currentPath.replace(re, '').replace(/\/$/, '');

        // Validar identificador
        if(this._isValidToken(parameter)) {
            return parameter;
        } else {
            return "";
        }
    }

    _isValidToken = (token) => {
        return /^[a-zA-Z0-9-_=]+$/.test(token); // Base64 charset, trocando '+' por '-' e '/' por '_'
    }

    _redirectToURL = (url) => {
        window.location.href = url;
    }

    _updateGeneralErrors = (response) => {
        const statusCode = response ? response.status : null;
        let allowRetry = false;
        let errorMessage;
        switch(statusCode) {
            case 400: // Requisição com erro (token inválido, ...)
                errorMessage = "Compartilhamento inexistente ou inválido.";
                break;
            case 401: // Token expirado
                errorMessage = "Compartilhamento expirado.";
            break;
            default: // 500 (Internal Server Error) and others
                allowRetry = true;
                errorMessage = "Ops ... ocorreu um erro processando sua solicitação.";
                break;
        }

        this.setState({
            erroValidacao: errorMessage,
            retryStart: allowRetry ? new Date().getTime() : null
        });
    }

    _validarCompartilhamento = () => {
        this.setState({ 
            erroValidacao: null,
            resultadoValidacao: null,
            retryStart: null
        });

        const { token } = this.state;
        if(!this._isValidToken(token)) {
            this.setState({ tokenError: true });
            return;
        }

        examesClient.obterUrlXeroViewerByToken(token, {}, {})
            .then(res => {    
                if(!res.data) {
                    this._updateGeneralErrors(null);
                } else {
                    this.setState({ 
                        resultadoValidacao: res.data
                    });
                }
            })
            .catch(err => {                      
                this._updateGeneralErrors(err.response);
            });
    }

    componentDidMount(){
        if(this.state.token) {
            window.setTimeout(() => { this._validarCompartilhamento() }, 500);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { resultadoValidacao, retryStart } = this.state;
        const urlXero = resultadoValidacao && resultadoValidacao.urlXero;

        if(prevState.resultadoValidacao !== resultadoValidacao && urlXero) {
            setTimeout(this._redirectToURL(urlXero), 50);
        }

        if(this._calculateRemainingTime(retryStart, RETRY_MIN_TIME_MS)) {
            this.counterTimeoutId = setTimeout(this._executeCounterTick.bind(this), 1000);
        } else {
            clearTimeout(this.counterTimeoutId);
            this.counterTimeoutId = null;
        }
    }

    render() {
        const { resultadoValidacao, erroValidacao, tokenError, retryStart } = this.state;
        const waitToRetry = this._calculateRemainingTime(retryStart, RETRY_MIN_TIME_MS);
        const urlXero = resultadoValidacao && resultadoValidacao.urlXero;
        if(urlXero) {
            return null;
        }

        return(
            <div className="visualizador-estudo-pacs-wrapper">
                <AppExtraDocumentHead subTitle="Compartilhamento de Imagens" robots="noindex,nofollow" />

                <div className="content-box">

                    <div className="header-card">
                        <AppExternalServiceHeader linkToHome={false}>
                            <h1><AppCustomMessage elemType="fragment" messageId="vizualizar-estudo_header-identificacao" /></h1>
                            <h2>Compartilhamento de Imagens</h2>
                        </AppExternalServiceHeader>
                    </div>

                    { erroValidacao && 
                    <AppExternalServiceInfoMessage id="validacaoErrID" className="info-error">
                        {erroValidacao}
                    </AppExternalServiceInfoMessage>
                    }

                    { tokenError && 
                    <AppExternalServiceInfoMessage id="tokenErrID" className="info-error">
                        Endereço de compartilhamento inválido, verifique a corretude do mesmo para tentar novamente.
                    </AppExternalServiceInfoMessage>
                    }

                    { retryStart &&
                    <div className="action-buttons">
                        <button 
                            type="button" 
                            className="btn-servicos retry-button"
                            disabled={waitToRetry ? true : false}
                            onClick={()=> this._validarCompartilhamento()} >
                                {`TENTAR NOVAMENTE${waitToRetry ? ` (${waitToRetry})` : ""}`}
                        </button>
                    </div>
                    }

                </div>

            </div>
        );
    }    

} export default VisualizaEstudoPACS;