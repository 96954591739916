/*
** @name: Meu Clínicas - textInputCounter
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2022
** @description: Componente para gera um contador para campos de edição
** 
*/

import React from 'react';


const TextInputCounter = (props) => {
    const { id, current, min, max } = props;
    const currentClassState = (current===0 ? "zero" : (current >= (min ? min : 0) ? "valid" : "invalid"));
    return (
        <div id={`counter_${id}`} className="input-counter">
            <small className={`count-current ${currentClassState}`}>{current}</small>
            <small>/</small>
            <small className="count-max">{max}</small>
        </div>
    )
}

export default TextInputCounter;