/*
** @name: Meu Clínicas - deviceInfo
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Componente para renderizar informacoes sobre o dispositivo
*/

import React from 'react';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const DeviceInfo = (props) => {
    const { errorMessage, onClose } = props;

    return(
        <div className='device-info-wrapper'>
            { errorMessage &&
            <>
                <AppMessageBox 
                    id="msg-instrucoes-error" 
                    className="error" 
                    messageData={{ message: errorMessage }}
                />
                <div className='main-action'>
                    <button className="app-form-button" onClick={onClose}>Fechar</button>
                </div>
            </>
            }
        </div>
    );
}

export default DeviceInfo;
