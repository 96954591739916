/*
** @name: Meu Clínicas - aceiteTermosSessao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Julho 2021
** @description: Componente para exibir do aceite de cookies (apenas na versão site - fora do app).
*/

import React, { Component } from 'react';
import Cookies from 'universal-cookie';


// Import module styles
import './aceiteCookies.scss';


const ACCEPT_COOKIE_NAME = "appAcceptCookie";

class AceiteCookies extends Component {

    _cookies = new Cookies();

    constructor(props) {
        super(props);

        this.state = {
            renderAceite: false
        }
    }

    _enableRender = () => {
        this.setState({ renderAceite: true });
    }

    _handleAceitar = () => {
        //this._cookies.set(ACCEPT_COOKIE_NAME, true);
        localStorage.setItem(ACCEPT_COOKIE_NAME, true);
        this.setState({ });
    }

    _isAcceptCookieOk = () => {
        //return this._cookies.get(ACCEPT_COOKIE_NAME);
        return localStorage.getItem(ACCEPT_COOKIE_NAME);
    }

    componentDidMount() {
        setTimeout(() => this._enableRender(), 1000);
    }

    render() {
        const rnIntegration = window.rnIntegration;
        const exibirBarraAceite = this.state.renderAceite && !(rnIntegration && rnIntegration.isAppRunning()) && !this._isAcceptCookieOk();
            
        if(!exibirBarraAceite) {
            return null;
        }

        return(
            <div id="aceiteCookiesWrapperId" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                <div id="aceiteCookiesContentId">
                    <div className="aceite-cookies-box">
                        <div className="message">Este site utiliza cookies para viabilizar as funcionalidades e para garantir que você obtenha a melhor experiência.</div>
                        <div className="aceite-cookies-btn-wrapper">
                            <button className="app-form-button" onClick={this._handleAceitar}>Aceitar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
} export default AceiteCookies;
