/*
** @name: Meu Clínicas - footer
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Módulo para renderizar rodapé dos formulários de pesquisa
*/

import React from 'react';
import { AppCustomLink, AppCustomMessage } from '@hcpa-react-components/app-customization';

const Footer = () => {
    return(
        <AppCustomMessage
            elemType="div"
            className="footer-content"
            messageId="pesq-experiencia_footer"
            params={[
                <AppCustomLink linkName="portal-hospital" target="_blank" className="link" />,
                <AppCustomLink linkName="email-ouvidoria" className="link" />,
                <AppCustomMessage messageId="pesq-experiencia_ouvidoria-presencial" />,
                <AppCustomLink linkName="pesquisa-experiencia-contato-telefone" className="link" />
            ]}
        />
    )
}

export {
    Footer
};