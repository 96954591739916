/*
** @name: Meu Clínicas - alterarSenha
** @author: 
** @date:
** @description: Módulo para alteração de senha do paciente
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para o novo layout da aplicação e para funcinar dentro de cards
*/

import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import Password from '../../components/fields/password/password.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';

// Import module styles
import './alterarSenha.scss'; 


class AlterarSenha extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                fields: {
                    senhaAtual: {
                        value: null
                    },
                    novaSenha: {
                        value: null,
                        valid: null
                    },
                    novaSenhaConfirmar: {
                        value: null,
                        valid: null
                    }
                }
            },
            msgErro: null,
            msgSucesso: null
        } 
    }

    _clearErrorMessage = () => {
        this.setState({ msgErro: null }); 
    }

    _handleSenhaAtualChange = (value) => {
        let updatedForm = this.state.form;
        updatedForm.fields.senhaAtual.value = value;

        this.setState({ form: updatedForm });
        this._clearErrorMessage();
    }

    _handleNovaSenhaChange = (value, valid, name) => {
        let updatedForm = this.state.form;
        if(!updatedForm.fields.hasOwnProperty(name)) {
            console.error(`Invalid field '${name}'.`);
            return;
        }

        updatedForm.fields[name].value = value;
        updatedForm.fields[name].valid = valid;
        updatedForm.fields[name].errorMessage = null;

        this.setState({ form: updatedForm });
        this._clearErrorMessage();
    }

    _processaAlteracao = (e) => {
        e.stopPropagation();
        e.preventDefault(); 
        this._clearErrorMessage();

        const { fields } = this.state.form;
        usuarioClient.alterarSenha(fields.senhaAtual.value, fields.novaSenha.value, fields.novaSenhaConfirmar.value, {}, {})
            .then(res => {                
                let header = "Sucesso!";
                let message = "Senha alterada.";
                this.setState({
                    msgSucesso: {
                        header, 
                        message
                    }
                });
            })
            .catch(err => {  
                let header = null;
                let message = null;
                if (err.response && err.response.data) {
                    header = "Atenção";
                    message = err.response.data;
                } else {
                    header = "Ops!";
                    message = "Ocorreu um erro ao processar sua requisição.";
                } 

                this.setState({
                    msgErro: { header, message }
                }); 

            });
    }

    _validateForm = () => {
        const { novaSenha } = this.state.form.fields;
        return novaSenha.valid;
    }

    render() {
        return(
            <AppCardModuleBasicWrapper wrapperName="alterar-senha">

                <div className="instructions-section">
                    <p>Informe os seguintes dados para alterar sua senha:</p>
                </div>

                <Form className="alterar-senha-form">

                    <div className="float-label-field senha-atual">
                        <Password 
                            id="input-senha-atual"
                            name="senhaAtual"
                            disabled={this.state.msgSucesso}
                            className=""
                            darkerInfo={true}
                            labelText="Senha atual"
                            strengthMeter={false}
                            onChange={this._handleSenhaAtualChange}
                        />     
                    </div>

                    <div className="float-label-field nova-senha">
                        <Password 
                            id="input-nova-senha"
                            name="novaSenha"
                            disabled={this.state.msgSucesso}
                            className=""
                            darkerInfo={true}
                            labelText="Nova senha"
                            strengthMeter={true}
                            onChange={this._handleNovaSenhaChange}
                        />     
                    </div>

                    <div className="float-label-field nova-senha-confirmar">
                        <Password 
                            id="input-nova-senha-confirmar"
                            name="novaSenhaConfirmar"
                            disabled={this.state.msgSucesso}
                            className=""
                            darkerInfo={true}
                            labelText="Confirme a nova senha"
                            strengthMeter={false}
                            onChange={this._handleNovaSenhaChange}
                        />     
                    </div>

                    {(this.state.msgErro && !this.state.msgSucesso) &&
                    <AppMessageBox 
                        id="msg-alterar-senha-error" 
                        className="error" 
                        messageData={this.state.msgErro} />
                    }

                    {(!this.state.msgErro && this.state.msgSucesso) &&
                    <AppMessageBox 
                        id="msg-alterar-senha-success" 
                        className="information"
                        messageData={this.state.msgSucesso} />
                    }  

                    <div className="main-action">
                        {!this.state.msgSucesso &&
                        <button 
                            id="button-alterar-senha"
                            type="default"
                            disabled={!this._validateForm()}
                            className="app-form-button"
                            onClick={(e) => this._processaAlteracao(e)}>
                            Alterar
                        </button>   
                        }
                    </div>

                </Form> 

            </AppCardModuleBasicWrapper>
        );
    }
}

export default AlterarSenha;