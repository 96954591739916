/*
** @name: Meu Clínicas - dadosValidacao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2023
** @description: Módulo para renderizar solicitacoa de dados para validação
*/
import React, { useState, useEffect, useCallback } from 'react';

import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';

import MainHeader from './mainHeader.js';
import ConfirmarValidacaoModal from './confirmarValidacaoModal.js';


const DadosValidacao = (props) => {
    const { tokenValue, tokenError, documentoValidado, resultadoValidacao, isValidToken, onFieldFocus, onValidar, onTokenError } = props;
    const [ token, setToken ] = useState(tokenValue);
    const [ exibeConfirmacaoValidacao, setExibeConfirmacaoValidacao ] = useState(false);
    
    const _fieldFocus = useCallback(() => {
        const fname = exibeConfirmacaoValidacao ? "dataNascimento" : "token";
        onFieldFocus(fname);
    }, [exibeConfirmacaoValidacao, onFieldFocus]);

    const _handleCloseModalConfirmacao = () => {
        setExibeConfirmacaoValidacao(false);
    }

    const _handleConfirmacaoOk = dtNasc => {
        _handleCloseModalConfirmacao();
        onValidar(token, dtNasc);
    }

    const _handleConfirmarValidacao = useCallback(() => {
        if(!isValidToken(token)) {
            onTokenError(true);
            return;
        }
        setExibeConfirmacaoValidacao(true);
    }, [token, isValidToken, onTokenError]);

    const _handleTokenChange = value => {
        setToken(value);
        onTokenError(false);
    }

    useEffect(() => setToken(tokenValue), [tokenValue]);

    useEffect(() => { // must trigger only component mount
        if(tokenValue && isValidToken(tokenValue) && !resultadoValidacao) {
            setExibeConfirmacaoValidacao(true);
        }
    }, []); // eslint-disable-line

    useEffect(() => _fieldFocus(), [_fieldFocus, exibeConfirmacaoValidacao]);

    let classInputToken = "";
    const infoMessage = [];
    if(documentoValidado) {
        if(!resultadoValidacao) {
            infoMessage.push(
                <AppExternalServiceInfoMessage key="msgValidacaoID" id="msgValidacaoID" className="info-error">
                    Ocorreu um erro realizando a validação do documento. Por favor, tente novamente mais tarde.
                </AppExternalServiceInfoMessage>
            );
        } else {
            if(!resultadoValidacao.valida) {
                classInputToken = " mc-border-warning";
                infoMessage.push(
                    <AppExternalServiceInfoMessage key="msgValidacaoID" id="msgValidacaoID" className="info-error">
                        Token informado não foi encontrado ou dados do paciente não conferem.
                    </AppExternalServiceInfoMessage>
                );
            }
        }
    }

    if(tokenError) {
        classInputToken = " mc-border-warning";
        infoMessage.push(
            <AppExternalServiceInfoMessage key="msgTokenErrorID" id="msgTokenErrorID" className="info-error">
                Token informado não é válido.
            </AppExternalServiceInfoMessage>
        );
    }

    return(
        <div className="landing-page">
            <div className="main-wrapper">
                <div className="main-content">

                    { exibeConfirmacaoValidacao &&
                    <ConfirmarValidacaoModal 
                        onCancel={() => _handleCloseModalConfirmacao()} 
                        onConfirm={dn => _handleConfirmacaoOk(dn)} />
                    }

                    <MainHeader />

                    { infoMessage }
                    <div className="primary-card">
                        <form action="#" name="formEntrada" className="ui form" onSubmit={e => e.preventDefault()}>
                            <div className="field-group input-field">
                                <label htmlFor="tokenId">Token do documento</label>
                                <input 
                                    id="tokenId"
                                    name="token"
                                    className={"field-control" + classInputToken}
                                    type="text"
                                    aria-describedby="tokenHelp" 
                                    placeholder="Informe o token do documento"
                                    onChange={e => _handleTokenChange(e.target.value)}
                                    value={token || ""} />
                                <small id="tokenHelp" className="form-text text-muted">Token que permite identificar o documento para validação.</small>
                            </div>
                            <button 
                                type="default" 
                                className="btn-servicos"
                                disabled={token ? false : true}
                                onClick={()=> _handleConfirmarValidacao()} >Validar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DadosValidacao;