import React from 'react';


const MainButton = (props) => {
    const { id, type, text, wrapperClassName, className, disabled, onClick } = props;
    return(
        <div className={wrapperClassName}>
            <button 
                id={id}
                type={type ? type : "button"}
                className={className}
                disabled={disabled}
                onClick={onClick}>
                {text}
            </button>
        </div> 
    );
}

export default MainButton;