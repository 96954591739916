/*
** @name: Meu Clínicas - appErrorPage
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
**
** @date: Agosto 2024
** @description: Módulo para rederização das páginas de erro da aplicação ('cookies-not-enabled' | 'not-supported-browser' | 'home-shortcut-not-allowed')
*/

import React, { useRef, useState, useEffect } from 'react';
import { isAndroid, isIOS, isSafari, isMobile, isTablet } from 'react-device-detect';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { importCustomImage, getImageThemeContext } from '../../../core/appSpecificConfigHandler.js';
import { AppCustomImage, AppCustomMessage } from '@hcpa-react-components/app-customization';
import AppMessageBox from '../appMessageBox/appMessageBox.js';

import './appErrorPage.scss';

const RELOAD_DELAY_MS = 15 * 1000;


const CookiesNotEnabled = (props) => {
    const { images } = props;
    var imagePath = isMobile ? images.errorCookiesMobile : images.errorCookiesDesktop;
    return (
        <div className='app-error'>       
            <img src={imagePath} alt="Erro cookies não habilitados"/>
        </div>
    );
}

const NotSupportedBrowser = (props) => {
    const { images } = props;
    const getBrowserErrorImage = () => {
        if(isMobile || isTablet) {
            if(isAndroid) {
                return images.errorMobileAndroid;
            }

            if(isIOS) {
                return images.errorMobileIOS;
            }
        }
        if(isIOS && !isSafari) {
            return images.errorDesktopIOS;
        }
        return images.errorDesktopGeneral;
    }

    return (
        <div className='app-error'>
            <img src={getBrowserErrorImage()} alt="Erro browser não suportado" />
        </div>
    );
}

const HomeShortcutNotAllowed = (props) => {
    const { images } = props;
    return(
        <div className='app-error'>
            <img src={images.errorMobileIOSStandalone} alt="Erro acesso por atalho" className='mh' />
        </div>
    );
}

const MissingConfiguration = (props) => {
    const { onReload } = props;
    const [ retryEnabled, setRetryEnabled] = useState(null);
    const retryTimeout = useRef(null);
    const disabled = !genesysUtils.typeCheck.isFunction(onReload) || !retryEnabled;

    const _clearCheckRetryTimeout = () => {
        clearTimeout(retryTimeout.current);
    }

    const _handleReload = () => {
        setRetryEnabled(false);
        onReload();
    }

    useEffect(() => {
        if(!retryEnabled) {
            retryTimeout.current = setTimeout(() => setRetryEnabled(true), RELOAD_DELAY_MS);
        }
        return () => _clearCheckRetryTimeout();
    }, [retryEnabled]);

    return(
        <div className='missing-configuration-wrapper'>

            <div className="app-logo">
                <AppCustomImage imageContextFn={getImageThemeContext} module="login" imageId="logo_app-horizontal" />
            </div>

            <AppMessageBox
                id="missingConfigMessageId"
                className="error message-wrapper"
                messageData={{
                    message: <AppCustomMessage messageId="_general_sistema-indisponivel" elemType="fragment" />
                }} />

            <div className='reload-button-wrapper'>
                <button 
                    id="btnReloadId"
                    type="button"
                    className="app-form-button"
                    disabled={disabled}
                    onClick={_handleReload}>
                    Tentar Novamente
                </button>
            </div>
        </div>
    );
}

const ErrorPage = (props) => {
    const { appConfiguration, page } = props;
    if(!genesysUtils.typeCheck.isObject(appConfiguration)) {
        throw new Error("Missing or invalid 'appConfiguration' property to build error page.");
    }

    // Import Images
    const images = {
        errorCookiesDesktop: importCustomImage(appConfiguration, 'app', 'error-cookies_desktop'),
        errorCookiesMobile: importCustomImage(appConfiguration, 'app', 'error-cookies_mobile'),
        errorDesktopGeneral: importCustomImage(appConfiguration, 'app', 'error-browser_desktop-general'),
        errorDesktopIOS: importCustomImage(appConfiguration, 'app', 'error-browser_desktop-ios'),
        errorMobileAndroid: importCustomImage(appConfiguration, 'app', 'error-browser_mobile-android'),
        errorMobileIOS: importCustomImage(appConfiguration, 'app', 'error-browser_mobile-ios'),
        errorMobileIOSStandalone: importCustomImage(appConfiguration, 'app', 'error-browser_mobile-ios-standalone')
    }

    const extraProps = { ...props };
    delete extraProps.appConfiguration;
    delete extraProps.page;

    switch(page) {
        case 'cookies-not-enabled': return <CookiesNotEnabled images={images} {...extraProps} />
        case 'not-supported-browser': return <NotSupportedBrowser images={images} {...extraProps} />
        case 'home-shortcut-not-allowed': return <HomeShortcutNotAllowed images={images} {...extraProps} />
        case 'missing-configuration': return <MissingConfiguration images={images} {...extraProps} />
        default: 
            throw new Error(`Inexistent error page requested '${page}'.`);
    }
}

export default ErrorPage;
