/*
** @name: Meu Clínicas - sessionStorageManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Prove rotinas para gerenciar os token de integracao no logon do usuário
** 
*/

import { AES, enc, mode, pad } from 'crypto-js';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { getAppServiceIdByConfigName } from './appServiceList.js';


import packageJson from '../../package.json';


const _cipherOptions = (key) => {
    return {
        iv: key,
        mode: mode.CBC,
        padding: pad.Pkcs7
    };
}

const _dencryptToken = (cipher, pass) => {
    try {
        const key = _keyFromPass(pass);
        const uncypheredBytes = AES.decrypt(cipher, key, _cipherOptions(key));
        return uncypheredBytes.toString(enc.Utf8);
    } catch(e) {
        return null;
    }
}

const _encryptToken = (token, pass) => {
    try {
        const key = _keyFromPass(pass);
        return AES.encrypt(token, key, _cipherOptions(key)).toString();
    } catch(e) {
        return null;
    }
}

const _keyFromPass = (pass) => {
    const key = String(pass + "0000000000000000").substring(0, 16);
    return enc.Utf8.parse(key);
}

const _urlEscapeBase64 = (base64) => {
    return base64.replace(/[+]/g, "-").replace(/\//g, "_").replace(/[=]/g, "*");
}

const _urlUescapeBase64 = (escaped) => {
    return escaped.replace(/[-]/g, "+").replace(/_/g, "/").replace(/[*]/g, "=");
}


class IntegrationToken {

    generate = (module, param, pacCodigo) => {
        if(!getAppServiceIdByConfigName(module)) {
            return undefined;
        }
        if(param && !genesysUtils.typeCheck.isObject(param)) {
            return undefined;
        }
        const pass = pacCodigo ? pacCodigo : packageJson.version.replace(/-RC$/, "");
        const tokenPayLoad = `${module}${param ? ";" + JSON.stringify(param) : ""}`;

        try {
            const cypher = _encryptToken(tokenPayLoad, pass);
            const escaped = _urlEscapeBase64(cypher);
            return escaped;
        } catch(e) {
            console.error("Erro creating logon integration token", e.message);
            return null;
        }
    }

    decode = (token, pacCodigo) => {
        if(!token) {
            return undefined;
        }

        try {
            const unescaped = _urlUescapeBase64(token);
            const defaultPass = packageJson.version.replace(/-RC$/, "");
            let plainText = _dencryptToken(unescaped, defaultPass);
            if(!plainText && pacCodigo) {
                plainText = _dencryptToken(unescaped, pacCodigo);
            }
            return plainText;
        } catch(e) {
            return null;
        }
    }
}

const integrationToken = new IntegrationToken();
export default integrationToken;
