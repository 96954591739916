/*
** @name: Meu Clínicas - userHome
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Módulo para renderizar a seção menu de serviços principais superior na home
*/

import React, { Fragment } from 'react';
import { Accordion } from 'semantic-ui-react';

import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';

import { ServiceButtonList } from '../allServices/allServices.js';


const HomeMenu = (props) => {
    const { position, menu, onAccordionClick } = props;
    const sectionTitle = menu.title ? menu.title : "Serviços Principais";

    return(
        <Fragment key={`keyMenu${position}`}>
            { (menu && menu.serviceList && menu.serviceList.length>0) ?
            <div id="serviceMnuSectionId" className="service-menu-section">
                <Accordion fluid styled>
                    <div className={`accordion-item${menu.active ? " active" : ""}`}>
                        <Accordion.Title onClick={(e) => onAccordionClick(e, menu)}>
                            <div className="title-info-wrapper">
                                <div className="information">{sectionTitle}</div>
                            </div>
                            <div className="collapsible-wrapper">
                                <AccordionCollapsible active={menu.active} iconVariant={null} />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content>
                            <div className="buttons-wrapper">
                                <ServiceButtonList 
                                    serviceList={menu.serviceList} 
                                    useAlternativeImage={menu.useAlternativeButtonImage}
                                    useButtonCardStyle={false} />
                            </div>
                        </Accordion.Content>
                    </div>
                </Accordion>
            </div>
            :
            <>
                { !menu.hideWhenEmpty &&
                <div className="empty-section">
                    <div className="title">{sectionTitle}</div>
                </div>
                }
            </>
            }
        </Fragment>
    );
}

export default HomeMenu;