/*
** @name: Meu Clínicas - receitasClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de receitas
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';

import moment from "moment";

const API_PORT = utils.isDev() ? 8085 : null;
const API_SERVICE = utils.isDev() ? null : 'receitas-service';
const API_VERSION = 'v2';

class ReceitasClient {
    #apiRequest;

    constructor() {
        this.#apiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#apiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    buscarListaReceitas(pacCodigo, dtInicial, dtFinal, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   

        return this.#apiRequest.auth.get('/receitas/lista', queryParams, null, extraAxiosConfig, options);
    }

    buscarPDF(id, pacCodigo, grupoId, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, grupoId };
        return this.#apiRequest.auth.get(`/receitas/${id}/pdf`, queryParams, null, extraAxiosConfig, options);
    }

    buscarReceita(id, pacCodigo, grupoId, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, grupoId };
        return this.#apiRequest.auth.get(`/receitas/${id}`, queryParams, null, extraAxiosConfig, options);
    }

    /* Servico - Validação */
    buscarPDFReceitaDispensada(requestData, extraAxiosConfig, options) {
        return this.#apiRequest.noAuth.post(`/public/pdf`, requestData, null, extraAxiosConfig, options);
    }

    registrarDispensacao(requestData, extraAxiosConfig, options) {
        return this.#apiRequest.noAuth.post(`/public/dispensacao`, requestData, null, extraAxiosConfig, options);
    }

    validarReceita(requestData, extraAxiosConfig, options) {
        return this.#apiRequest.noAuth.post(`/public/validar`, requestData, null, extraAxiosConfig, options);
    }
}

const receitasClient = new ReceitasClient();
export default receitasClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};