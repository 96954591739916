/*
** @name: Meu Clínicas - mensagens
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Módulo para renderizar blocos de mensagens
*/

import React from 'react';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const ActionButtonFormPesq = (props) => {
    const { id, children, text, onClick } = props;
    if(!text || !genesysUtils.typeCheck.isFunction(onClick)) {
        return <></>;
    }
    return (
        <div className="main-action">
            <button id={id} onClick={onClick} className="app-form-button" >{text}</button>
            {children}
        </div> 
    )
}

const MensagemErroInicializacaoFormPesq = (props) => {
    return (
        <>
            <div className="information-section">
                <div className="section-box">
                    <div className="section-content">
                        <AppMessageBox id="peMsgErrorId" className="error" messageData={{ message: <AppCustomMessage elemType="fragment" messageId="_general_modulo-indisponivel" /> }} />
                    </div>
                </div>
            </div>

            <ActionButtonFormPesq id="peBtnErrorCloseId" text="OK" onClick={props.onClick} />
        </>
    )
}

const MensagemSucessoPesq = (props) => {
    return (
        <>
            <div className="information-section">
                <div className="section-box">
                    <div className="section-content">
                        <div className="sucesso">Sucesso</div>
                        <AppCustomMessage id="peMsgSucessoId" elemType="fragment" messageId="pesq-experiencia_sucesso" />
                    </div>
                </div>
            </div>

            <ActionButtonFormPesq id="peBtnSucessoCloseId" text="OK" onClick={props.onClick} />
        </>
    )
}

export {
    ActionButtonFormPesq,
    MensagemErroInicializacaoFormPesq,
    MensagemSucessoPesq
};