import React, { Fragment } from 'react';
import { Radio } from 'semantic-ui-react';
import { FormBuilderSupportFields } from 'react-dj-forms-builder';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';


const RadioGroupField = (props) => {
    const { id, name, label, disabled, requiredMark, onChange, value, errorMessage,
            toggle, slider, items, children, renderChild, hideChildren,
            uncheckOnDoubleClick, uncheckOnContextMenu } = props;
    const uncheckGroup = (e) => {
        e.preventDefault();
        onChange(e, { name, value: null});
    }
    if(!items || items.constructor !== Array || items.length<1) {
        throw new Error("Undefined or invalid radio-group items.");
    }

    return(
        <>
            { label &&
            <div className="field-label">{label}{requiredMark ? <FormBuilderSupportFields.RequiredMark /> : null}</div>
            }
            { items.map((item, indxItem) => {
                const radioClassName = ((item.className ? item.className : "") + (disabled ? " disabled" : "")).trim();
                const radioId = `${id}_radio_${indxItem}`;
                const isChecked = value===item.value;
                const itemChildrenIndex = item.childrenIndex && item.childrenIndex.constructor===Array ? item.childrenIndex : [];

                return(
                    <Fragment key={radioId}>
                        <div className={`radio-wrapper ${radioClassName}`}>
                            <Radio
                                id={radioId}
                                name={name}
                                disabled={disabled || item.disabled ? true : false}
                                label={item.label}
                                checked={isChecked}
                                value={item.value}
                                toggle={toggle ? true: false}
                                slider={slider ? true : false}
                                onDoubleClick={isChecked && uncheckOnDoubleClick ? uncheckGroup : null}
                                onContextMenu={isChecked && uncheckOnContextMenu ? uncheckGroup : null}
                                onChange={(e, elem) => onChange(e, {name: name, value: elem.value})} />
                        </div>
                        { !(hideChildren && !isChecked) && children && children.constructor===Array && renderChild && 
                            itemChildrenIndex.map(childIndx => {
                                if(genesysUtils.typeCheck.isInteger(childIndx) && children[childIndx]) {
                                    return renderChild(children[childIndx], childIndx);
                                } else {
                                    return null;
                                }
                            }) 
                        }
                    </Fragment>
                )
            })
            }
            { errorMessage &&
            <div className="field-error">{errorMessage}</div>
            }
        </>
    )
}

export default RadioGroupField;