import React from 'react';
import ReactDOM from 'react-dom/client';

import { mobileViewHeightFix } from '@hcpa-react-components/mobile-view-height-fix';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import App from './modules/app/app.js';


// Fix mobile view height
if(genesysUtils.deviceCheck.isMobile()) {
    mobileViewHeightFix();
}

/* --------------------------------------------------------------------------
 * Render Application Component
 * --------------------------------------------------------------------------
 */
const root = ReactDOM.createRoot(document.getElementById('root'));
const disableStrictModel = process.env.REACT_APP_DEV_DISABLE_STRICT_MODE;
root.render(
    <>
        {disableStrictModel ? 
        <App /> :
        <React.StrictMode>
            <App />
        </React.StrictMode>
        }
    </>
);
