import React from 'react';

/*
** @name: Meu Clínicas - agendamentoConsultas
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Conteúdo de seleção do horário de agendamento
*/

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppBooking from '../../components/general/appBooking/appBooking.js';


const _getCurrentData = (pageSize, currentPage, selectedMonthIndex, availability) => {
    const pageData = [];

    if(availability && availability.length>0) {
        const offset = selectedMonthIndex ? selectedMonthIndex : Math.max(0, currentPage-1) * pageSize;
        for(let i=0; i<pageSize; i++) {
            const j = offset + i;
            const dayData = j<availability.length ? availability[j] : {}
            pageData.push(dayData);
        }
    }

    return pageData;
}

const _getMesAtual = (currentMonth, pageData, monthNames) => {
    const firstDay = pageData && pageData.length>0 ? pageData[0] : {};
    const { month, year } = currentMonth || firstDay;
    if(!genesysUtils.typeCheck.isInteger(month) || !genesysUtils.typeCheck.isInteger(year) || year<0 || month<1 || month>12) {
        return "SELECIONE O MÊS";
    }
    return `${monthNames[month-1]} ${year}`;
}

const selecaoHorario = (props) => {
    const { pageSize, pageCount, currentPage, currentMonth, registroEspecialidade, selectedBooking, selectedMonthIndex, 
            availability, monthNames, onMonthPicker, onScheduleSelect, onSchedulePagination } = props;
    const availablePageData = _getCurrentData(pageSize, currentPage, selectedMonthIndex, availability);
    const mesAtual = _getMesAtual(currentMonth, availablePageData, monthNames);
    const descricaoEspecialidade = registroEspecialidade ? registroEspecialidade.nomeEspecialidade : null;
    const allowBack = (selectedMonthIndex || currentPage>1) ? true : false;
    const allowForward = (currentPage<pageCount && 
        (selectedMonthIndex===null || (selectedMonthIndex+pageSize)<availability.length)) ? true : false

    return(
        <div className="step-two-wrapper">
            <div className="especialidade-atual"><span className="bold-text">Agenda:</span> {descricaoEspecialidade}</div>

            { (availability.length > 0) &&
            <>
                { mesAtual &&
                <div className="mes-atual">
                    <div>
                        <div className="descricao">{mesAtual}</div>
                        <div className="picker-wrapper">
                            <button type="button" onClick={onMonthPicker}>
                                <AppCustomImage imageContextFn={getImageThemeContext} 
                                    module="agendamentoConsultas"
                                    imageId="icon_calendar"
                                    className="trigger-icon" />
                            </button>
                        </div>
                    </div>
                </div>
                }

                <div className="grade-agendamento">
                    <AppBooking
                        available={availablePageData}
                        allowBack={allowBack}
                        allowForward={allowForward}
                        pages={pageCount}
                        selected={selectedBooking}
                        onDayClick={null}
                        onHourClick={onScheduleSelect}
                        onPagination={onSchedulePagination}
                    />
                </div>

                <div className="legenda-section">
                    <div className="legenda">
                        <div className="hour-field">HH:MM</div>
                        <div className="info-legenda">Presencial</div>
                    </div>
                    <div className="legenda">
                        <div className="hour-field teleatendimento">HH:MM</div>
                        <div className="info-legenda">Teleatendimento</div>
                    </div>
                </div>
            </>
            }
        </div>
    )
}

export default selecaoHorario;