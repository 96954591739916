/*
** @name: Meu Clínicas - mainHeader
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para renderizar o cabecalho do modulo
*/
import React from 'react';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';

const MainHeader = () => {
    return(
        <div className="header-card">
            <AppExternalServiceHeader linkToHome={false}>
                <h1><AppCustomMessage elemType='frag' messageId='validador_header-identificacao' /></h1>
                <h2>Validação de Documentos</h2>
            </AppExternalServiceHeader>
        </div>
    );
}

export default MainHeader;