import React from 'react';

/*
** @name: Meu Clínicas - agendamentoConsultas
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Conteúdo de confirmação dos dados do agendamento
*/

const confirmacaoDados = (props) => {
    const { reserva, reservaDuracao } = props;

    return(
        <div className="step-three-wrapper">
            <div className="step-title">Confira os dados da reserva abaixo e confirme</div>
            
            <div className="summary-wrapper">
                <div className="info-row">
                    <span className="bold-text">{reserva.dataHora}</span>
                </div>
                <div className="info-row">
                    {reserva.tipo}
                </div>
                <div className="info-row">
                    <span className="bold-text">Agenda:</span> {reserva.especialidade}
                </div>
                <div className="info-row">
                    <span className="bold-text">Equipe:</span> {reserva.equipe}
                </div>
                <div className="info-row">
                    <span className="bold-text">Local:</span> {reserva.zona}
                </div>
                <div className="info-row">
                    <span className="bold-text">Sala:</span> {reserva.sala}
                </div>
            </div>

            <div className="information-wrapper">
                Atenção: esta consulta pode ter a modalidade de atendimento alterada (presencial/tele atendimento)
            </div>

            <div className="validade-wrapper">
                <span className="bold-text">Reserva válida por:</span> {reservaDuracao ? reservaDuracao : 0}s
            </div>
        </div>
    )
}

export default confirmacaoDados;