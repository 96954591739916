/*
** @name: Meu Clínicas - receitas
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Julho 2020
** @description: Módulo para acesso a receitas pelo paciente (listagem, visualização e download)
**
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
*/

import React, { Component, Fragment } from 'react';
import { Accordion } from 'semantic-ui-react';
import Moment from 'react-moment';
import { pascalCase, startCase } from '@hcpa-react-components/string-utils';
import { AppCustomImage, AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppDateFilter from '../../components/general/appDateFilter/appDateFilter.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import ReceitaView from '../../components/general/receitaView/receitaView.js';
import ViewerModal from '../../components/general/viewerModal/viewerModal.js';
import Watermark from '../../components/general/watermark/watermark.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';

import receitasClient from '../../apiClients/receitas/receitasClient.js';


// Import module styles
import './receitas.scss'; 


const ESCAPE_KEY = 27;
const TIPO_RECEITA_ABREV = {
    "G": "Rct. Geral",
    "E": "Rct. Especial",
    "C": "Rct. Cuidados"
}

const Receitas = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    return(
        <ReceitasImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class ReceitasImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFiltered: false,
            filterError: null,
            searchResult: [],
            searchErrorMessage: null,
            searchInfoMessage: null,
            showReceita: false,
            dadosReceita: null,
        };
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(this.state.showReceita && this.state.dadosReceita) {
                rnIntegration.backAction.push(() => { this._handleCloseReceita() });
            }
        }
    }

    _buildFilename = (receita, grupo) => {
        const idReceita = receita.idReceita;
        const grupoId = grupo.grupoId;

        return `receita_${idReceita}_${grupoId}`;
    } 

    _getListaReceitas = (params) => {
        const filtro = (params && params.filtro) || {};
        const filterError = params && params.filterError;

        this.setState({ filterError });
        if (filterError) {
            return;
        }        

        this.setState({ 
            searchErrorMessage: null,
            searchInfoMessage: null,
        });

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        receitasClient.buscarListaReceitas(pacCodigo, filtro.dtInicial, filtro.dtFinal, {}, {})
            .then(res => {
                const isFiltered = (filtro.dtInicial || filtro.dtFinal) ? true : false;
                const searchInfoMessage = res.data.receitas.length > 0 ? null :
                    (!isFiltered && utils.isUserInSyncAwayTime() ? <AppCustomMessage messageId="_general_mensagem-possivel-sincronismo-pendente" /> : "Nenhuma receita encontrada");
                this.setState({
                    isFiltered: isFiltered,
                    searchResult: res.data.receitas,
                    searchInfoMessage: searchInfoMessage
                });
            })
            .catch(err => {                      
                this.setState({
                    searchResult: [], 
                    searchErrorMessage: "Ocorreu um erro ao processar sua requisição"
                });
            });
    }

    _getPDF = (receita, grupo) => {
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        receitasClient.buscarPDF(receita.id, pacCodigo, grupo.grupoId, {}, {})
            .then(response => { 
                if(!response.data || !response.data.pdfBase64) {
                    this._updateDownloadError(grupo, "Retorno sem dados para consulta.");
                    return;
                }
                this._updateDownloadError(grupo, null);

                const base64 = response.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const fileName = this._buildFilename(receita, grupo) + ".pdf";
                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }

                receita.statusLeitura = response.data.statusLeitura ? response.data.statusLeitura : receita.statusLeitura;
                grupo.statusLeitura = 'LIDO';

                this.setState(this.state);
            })
            .catch(err => {
                this._updateDownloadError(grupo, "Erro obtendo dados da receita."); 
            }); 
    }

    _getReceita = (receita, grupo) => {
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        receitasClient.buscarReceita(receita.id, pacCodigo, grupo.grupoId, {}, {})
            .then(response => { 
                if(!response.data) {
                    this._updateDownloadError(grupo, "Retorno sem dados para consulta.");
                    return;
                }
                this._updateDownloadError(grupo, null);

                receita.statusLeitura = response.data.statusLeitura;
                grupo.statusLeitura = 'LIDO';
                this.setState({
                    showReceita: true,
                    dadosReceita: response.data 
                });
            })
            .catch(err => {
                this._updateDownloadError(grupo, "Erro obtendo dados da receita."); 
            });
    }

    _handleAccordionClick = (e, receita) => {
        receita.active = !receita.active;
        this.setState({});
    }

    _handleClearFilter = () => {
        const { isFiltered } = this.state;
        this.setState({
            isFiltered: false,
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null,
        });

        if(isFiltered) {
            this._getListaReceitas();
        }
    }

    _handleCloseReceita = () => {
        if(this.state.showReceita) {
            this.setState({ 
                showReceita: false,
                dadosReceita: null
            });
        }
    }

    _handleKeyDown = (event) => {
        if(this.state.showReceita && this.state.dadosReceita) {
            switch( event.keyCode ) {
                case ESCAPE_KEY:
                    event.stopPropagation();
                    event.preventDefault();
                    this._handleCloseReceita();
                    break;
                default: 
                    break;
            }
        }
    }

    _handleUpdateFilter = () => {
        this.setState({ 
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null,
        });
    }

    _isNull(value, nullvalue) {
        if(!value) {
            return nullvalue;
        } else {
            return value;
        }
    }

    _isStatusNovo = (statusLeitura) => {
        if (statusLeitura==='NAO_LIDO' || statusLeitura==='ATUALIZACAO_NAO_LIDA') {
            return true;
        }
        return false;
    }

    _isPeriodoDispensado = (grupo) => {
        if(!grupo) {
            return null;
        }

        const dispensacoes = grupo.dispensacoesPeriodos;
        const periodoVisualizacao = grupo.periodoVisualizacao;
        const registrosPeriodo = dispensacoes ? dispensacoes[periodoVisualizacao] : null;

        return registrosPeriodo ? registrosPeriodo[0] : null;
    }

    _isReceitaExpirada = (grupo) => {
        if(!grupo) {
            return null;
        }

        return grupo.receitaExpirada;
    }

    _updateDownloadError = (grupo, error) => {
        grupo.downloadError = error;
        this.setState(this.state);
    }

    RenderReceita = () => {
        const { showReceita, dadosReceita } = this.state;

        genesysUtils.general.setViewPortRescale(false);
        if(showReceita && dadosReceita) {
            const receita = dadosReceita;
            const grupo = receita.grupos[0];  // A consulta é feita a uma Receita a a um Grupo de Exibição específico
            const viwerTitle = <>Data: <Moment format="DD/MM/YYYY" >{receita.receituarioDthrCriacao}</Moment> - Receita: {grupo.grupoId}</>;
            const validade = grupo && grupo.validade;
            const periodo = validade && (grupo.periodoVisualizacao < validade) ? `${grupo.periodoVisualizacao}${String.fromCharCode(170)} ` : "";
            const watermarkMessage = this._isReceitaExpirada(grupo) ? 
                    "Receita Expirada" :
                    (this._isPeriodoDispensado(grupo) ? `${periodo}Receita Dispensada` : null);

            return (
                <div className="receita-viwer-wrapper">
                    { watermarkMessage &&
                    <Watermark message={watermarkMessage} messageClassName="watermark-text" />
                    }

                    <ViewerModal 
                        title={<div className="titulo-modal">{viwerTitle}</div>}
                        onCloseButton={this._handleCloseReceita}>
                        <ReceitaView receita={dadosReceita} tooltip={true} />
                    </ViewerModal>
                </div>
            );
        } else {
            return null;
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown, true);
        this._getListaReceitas();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.showReceita!==prevState.showReceita) {
            this._appSetBackAction();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown, true);
    }

    render() {
        return(
            <AppCardModuleBasicWrapper wrapperName="receitas">

                { this.RenderReceita() }

                <AppDateFilter
                    filterError={this.state.filterError}
                    onFilterClear={this._handleClearFilter}
                    onFilterUpdate={this._handleUpdateFilter}
                    onFilter={this._getListaReceitas}
                />

                {this.state.searchErrorMessage &&
                <AppMessageBox 
                    id="msg-receitas-error" 
                    className="error" 
                    messageData={{ message: this.state.searchErrorMessage }} />
                }

                {this.state.searchInfoMessage &&
                <AppMessageBox 
                    id="msg-receitas-information" 
                    className="information" 
                    messageData={{ message: this.state.searchInfoMessage }} />
                }

                <div className="receitas-section">
                    <Accordion fluid styled>
                        { this.state.searchResult.map((receita, indexReceita) => {
                            const identificacaoServidor = (this._isNull(receita.servidorTitulo, "") + " " + this._isNull(receita.servidorNome, "")).trim();
                            const tipoReceita = TIPO_RECEITA_ABREV[receita.receituarioTipo.toUpperCase()];
                            const existeNovaReceita = this._isStatusNovo(receita.statusLeitura);

                            return (
                                (receita.grupos && receita.grupos.length > 0) &&
                                <div 
                                    key={'receita-' + indexReceita}
                                    className={`accordion-item${receita.active ? " active": ""}${existeNovaReceita ? " emphasis" : ""}`}
                                >
                                    <Accordion.Title onClick={(e) => this._handleAccordionClick(e, receita)}>
                                        <div className="title-info-wrapper">
                                            { existeNovaReceita &&
                                            <div className="indicativo-novo bold-text">[NOVO]</div>
                                            }
                                            <div className="information">
                                                <div className={`data bold-text${receita.especialidadeNome ? " separator" : ""}`}>
                                                    Data: <Moment format="DD/MM/YYYY" >{receita.receituarioDthrCriacao}</Moment>
                                                </div>
                                                <div className="especialidade bold-text">{startCase(receita.especialidadeNome)}</div>
                                            </div>
                                        </div>
                                        <div className="collapsible-wrapper">
                                            <AccordionCollapsible active={receita.active} iconVariant={existeNovaReceita ? "new" : null} />
                                        </div>
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <div className={`dados-receita${existeNovaReceita ? " novo" : ""}`}>
                                            <div className="nome-servidor">
                                                <div className="caption bold-text">Solicitante:</div>
                                                <div className="info">{pascalCase(identificacaoServidor)}</div>
                                            </div>
                                            <div className="tipo-receita">
                                                <div className="caption bold-text">Tipo:</div>
                                                <div className="info">{tipoReceita}</div>
                                            </div>
                                        </div>
                                        { receita.grupos.map((grupo, indexGrp) => {
                                            const indicativoNovaReceita = this._isStatusNovo(grupo.statusLeitura);

                                            return(
                                                <Fragment key={'receita_grupo-' + indexReceita + '-' + indexGrp}>
                                                    <div className={`content-row${indicativoNovaReceita ? " novo" : ""}`}>
                                                        <div className="receitas-grupo-wrapper">
                                                            <div className="buttons-wrapper">
                                                                <button 
                                                                    type="button"
                                                                    className="btn-visualizar"
                                                                    title="Visualizar receita"
                                                                    onClick={() => this._getReceita(receita, grupo)}>
                                                                    <AppCustomImage imageContextFn={getImageThemeContext} 
                                                                        module="receitas"
                                                                        imageId={`icon_botao-visualizar${indicativoNovaReceita ? "-new" : ""}`}
                                                                        className="img-buttomimg" />
    
                                                                </button>                                               
                                                                <button 
                                                                    type="button"
                                                                    className="btn-baixar-pdf"
                                                                    title="Baixar arquivo PDF da receita"
                                                                    onClick={() => this._getPDF(receita, grupo)}>
                                                                    <AppCustomImage imageContextFn={getImageThemeContext}
                                                                        module="receitas"
                                                                        imageId={`icon_botao-pdf${indicativoNovaReceita ? "-new" : ""}`}
                                                                        className="img-buttomimg" />
                                                                </button>
                                                            </div>
                                                            <div className="info-wrapper">
                                                                <div className="receita-titulo">Receita: {grupo.grupoId}</div>
                                                            </div>                    
                                                        </div>
                                                    </div>

                                                    { grupo.downloadError &&
                                                    <div className="content-row">
                                                        <div className="download-error">{grupo.downloadError}</div>
                                                    </div>
                                                    }
                                                </Fragment>
                                            )}
                                        )}
                                    </Accordion.Content>
                                </div>
                            )
                        })}
                    </Accordion>  
                    <div className="padding-rodape"></div>
                </div> 

            </AppCardModuleBasicWrapper>
        );
    }
}

export default Receitas;
