/*
** @name: Meu Clínicas - appDateFilter
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render da caixa de filtragem padrão usada em módulos da aplicação
*/

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import moment from 'moment';

import utils from '../../../core/utils.js';


// Import module styles
import './appDateFilter.scss'; 


class AppDateFilter extends Component {
    constructor(props) {
        super(props);

        utils.checkRequiredFunctionProperty(props.onFilter, 'onFilter', 'AppDateFilter');
        this.state = {
            filtro: {
                dtInicial: null,
                dtFinal: null,                
            },
            filterError: null,
        };
    }

    _getFilterError = (filtro) => {
        const { dtInicial, dtFinal } = filtro ? filtro : this.state.filtro;
        if (dtInicial) {
            const today = moment();
            const diff = utils.calculateDateDiff(dtInicial, today);
            if (diff < 0) {
                return "Data inicial não pode ser posterior ao dia de hoje.";
            }
        }
        if (dtInicial && dtFinal) {
            const diff = utils.calculateDateDiff(dtInicial, dtFinal);
            if (diff < 0) {
                return "Data inicial não pode ser posterior à data final.";
            }
        }
        return null;
    }

    _handleClearFilter = () => {
        const filtro = { dtInicial: null, dtFinal: null };
        const filterError = null;
        this.setState({
            filtro,
            filterError
        });

        if(this.props.onFilterClear) {
            this.props.onFilterClear({
                filtro,
                filterError
            });
        }
    }

    _handleDoFilter = (e) => {
        e.stopPropagation();
        e.preventDefault(); 

        this.props.onFilter({
            filtro: this.state.filtro, 
            filterError: this.state.filterError
        });
    }

    _handleUpdateFilter = (property, value) => {
        const filtro = this.state.filtro;
        filtro[property] = value;
        const filterError = this._isFilterDisabled() ? null : this._getFilterError(filtro);
        this.setState({ 
            filtro,
            filterError
        });

        if(this.props.onFilterUpdate) {
            this.props.onFilterUpdate({ 
                property,
                value,
                filtro,
                filterError
            });
        }
    }

    _isFilterDisabled = () => { 
        if (this.state.filtro.dtInicial && !utils.isValidDate(this.state.filtro.dtInicial)) {
            return true;
        }

        if (this.state.filtro.dtFinal && !utils.isValidDate(this.state.filtro.dtFinal)) {
            return true;
        }

        if(this.props.filterError || this.state.filterError) {
            return true;
        }

        return false;
    }

    render() {
        const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
        const filterError = this.props.filterError || this.state.filterError;

        return(
            <div id="appFilterWrapperId">
                <div className="filter-section">
                    <Form className="filter-form">
                        <div className="filter-box">
                            <div className="filter-values">
                                <div className="inicio-wrapper">
                                    <div className="filter-input">
                                        <Input
                                            fluid
                                            children={
                                                <MaskedInput
                                                    id="input-filter-de"
                                                    className=""
                                                    onChange={(e) => this._handleUpdateFilter('dtInicial', e.target.value )} 
                                                    keepCharPositions="true"
                                                    placeholder="de: dd/mm/aaaa"
                                                    pipe={autoCorrectedDatePipe}
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                    value={this.state.filtro.dtInicial}                                  
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            
                                <div className="fim-wrapper">
                                    <div className="filter-input">
                                        <Input
                                            fluid
                                            children={
                                                <MaskedInput
                                                    id="input-filter-ate"
                                                    className=""
                                                    onChange={(e) => this._handleUpdateFilter('dtFinal', e.target.value )} 
                                                    keepCharPositions="true"
                                                    placeholder="até: dd/mm/aaaa"
                                                    pipe={autoCorrectedDatePipe}
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                    value={this.state.filtro.dtFinal}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            {filterError &&
                            <div className="filter-error">
                                {filterError}
                            </div>
                            }
                        </div>

                        <div className="filter-action">
                            <div>
                                <button 
                                    type="default"
                                    className="app-compact-button" 
                                    onClick={(e) => this._handleDoFilter(e)}
                                    disabled={this._isFilterDisabled()}>
                                    FILTRAR
                                </button>
                            </div>
                            { (this.state.filtro.dtInicial || this.state.filtro.dtFinal) && 
                            <div>
                                <button 
                                    type="button"
                                    className="app-compact-button" 
                                    onClick={this._handleClearFilter}>
                                    LIMPAR
                                </button>
                            </div>
                            }
                        </div>
                    </Form>
                </div>          
            </div>
        )
    }
}

export default AppDateFilter;