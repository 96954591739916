/*
** @name: Meu Clínicas - textInput
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2022
** @description: Componente para gera um item de imagem (com seus atributos) na lista de edição de uma notícia
** 
*/

import React from 'react';
import { TextArea } from 'semantic-ui-react';

import TextInputCounter from './textInputCounter.js';


const TextInput = (props) => {
    const { fieldData, id, name, className, title, placeHolder, info, onChange, disabled, required, rows, counter } = props || {};
    const fieldErrorMessage = fieldData.errorMessage;
    const fieldProps = {
        id: id,
        name: name,
        className: "field-control" + (fieldErrorMessage ? " mc-border-warning" : "") + (disabled ? " disabled" : ""),
        placeholder: placeHolder,
        onChange: onChange,
        disabled: disabled,
        autoComplete: "off",
        value: fieldData.value ? fieldData.value : ""
    };
    const isTextArea = rows && rows > 1;
    const enableCounter = counter && (counter.top || counter.bottom) && counter.maxCount;
    const currentCount = fieldData.value ? fieldData.value.length : 0;
   
    return(
        <div className={`field-group ${isTextArea ? "textarea-field" : "input-field"}${(className ? " " + className : "")}`}>
            <div className="field-label">{title}{required && <i className="field-required" />}{ info && <i className="field-information" title={info} /> }</div>
            <div className={`input-wrapper${disabled ? " disabled" : ""}`}>
                { enableCounter &&
                <TextInputCounter id={`counter_${id}`} current={currentCount} min={counter.minCount} max={counter.maxCount} />
                }
                { isTextArea ? <TextArea {...fieldProps} /> : <input type="text" {...fieldProps} /> }
                { fieldErrorMessage &&
                <div className="field-message mc-warning">{fieldErrorMessage}</div>
                }
            </div>
        </div>
    );
}

export default TextInput;