/*
** @name: Meu Clínicas - smsValidationModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Maio 2021
** @description: Componente para render parametrizado de uma modal para controle da validação do celular.
*/

import React from 'react';

import utils from '../../../core/utils.js';
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../../core/appSpecificConfigHandler.js';

import AppModal from '../appModal/appModal.js';

import SmsValidation from './smsValidation.js';


// Import module styles
import './smsValidationModal.scss'; 



const SmsValidationModal = (props) => {
    const { allowSkip, cpf, numCelular, smsActivationToken, onTokenChange, onComplete, onCancel } = props;
    const module = 'SmsValidationModal';
    utils.checkRequiredFunctionProperty(onTokenChange, 'onTokenChange', module);
    utils.checkRequiredFunctionProperty(onComplete, 'onComplete', module);
    utils.checkRequiredFunctionProperty(onCancel, 'onCancel', module);

    return(
        <div id="smsValidationModalWrapperId">
            <AppModal
                closeOnEsc={true}
                closeOnClickOutside={true}
                onClose={() => onCancel()}
                headerContent={
                    <div className="modal-header-wrapper">
                        <div className="close-button" onClick={() => onCancel()}>
                            <AppCustomImage imageContextFn={getImageThemeContext} module="appCard" imageId="forms-icon_back" />
                        </div>
                    </div>
                }
            >

                <SmsValidation
                    allowSkip={allowSkip}
                    cpf={cpf}
                    numCelular={numCelular} 
                    smsActivationToken={smsActivationToken}
                    onTokenChange={onTokenChange}
                    onComplete={onComplete}
                    onCancel={onCancel}
                />

            </AppModal>
        </div>
    );
}

export default SmsValidationModal;
