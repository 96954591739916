/*
** @name: Meu Clínicas - formulariosClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de formulários
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';
import moment from "moment";

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';


const API_PORT = utils.isDev() ? 8086 : null;
const API_SERVICE = utils.isDev() ? null : 'formularios-service';
const API_VERSION = 'v2';

class FormulariosClient {
    #apiRequest;

    constructor() {
        this.#apiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#apiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    getPermissoes(pacCodigo, extraAxiosConfig, options) {
        return this.#apiRequest.auth.get(`/permissoes/${pacCodigo}`, null, null, extraAxiosConfig, options);
    }

    contadorSolicitacoesEspecialidade(pacCodigo, extraAxiosConfig, options) {
        return this.#apiRequest.auth.get(`/obter-contador-solicitacoes-agendamento/${pacCodigo}`, null, null, extraAxiosConfig, options);
    }

    exportarPesquisaExperiencia(jwtServiceToken, fingerprint, tipoRegistro, tipoExportacao, versaoFormulario, filtro, extraAxiosConfig, options) {
        var queryParams = { jwtServiceToken, fingerprint, tipoRegistro, tipoExportacao, versaoFormulario };
        if (filtro.dataInicial) {            
            queryParams.dataInicial = moment(filtro.dataInicial, 'DD/MM/YYYY').toISOString(true);
        }
        if (filtro.dataFinal) {
            queryParams.dataFinal = moment(filtro.dataFinal, 'DD/MM/YYYY').toISOString(true);
        }

        return this.#apiRequest.noAuth.get('/public/exportar-pesquisa-experiencia', queryParams, null, extraAxiosConfig, options);
    }

    exportarSolicitacaoConsulta(jwtServiceToken, fingerprint, tipoExportacao, filtro, extraAxiosConfig, options) {
        var queryParams = { jwtServiceToken, fingerprint, tipoExportacao };
        if (filtro.dataInicial) {            
            queryParams.dataInicial = moment(filtro.dataInicial, 'DD/MM/YYYY').toISOString(true);
        }
        if (filtro.dataFinal) {
            queryParams.dataFinal = moment(filtro.dataFinal, 'DD/MM/YYYY').toISOString(true);
        }

        return this.#apiRequest.noAuth.get('/public/exportar-solicitacao-consulta', queryParams, null, extraAxiosConfig, options);
    }

    notificarSolicitacaoConsulta(jwtServiceToken, fingerprint, fields, extraAxiosConfig, options) {
        const request = { jwtServiceToken: jwtServiceToken, fingerprint: fingerprint };
        const dateFields = ["dataInicial", "dataFinal"];
        Object.keys(fields).forEach((key) => {
            let value = fields[key];
            if(value && dateFields.includes(key)) {
                value = moment(value, 'DD/MM/YYYY').toISOString(true);
            }
            request[key] = value ? value : null;
        });
        
        return this.#apiRequest.noAuth.post('/public/notificar-solicitacao-consulta', request, null, extraAxiosConfig, options);
    }

    obterConfiguracoesFormularios(listId, extraAxiosConfig, options) {
        const request = { listId };
        return this.#apiRequest.auth.post('/formulario/config', request, null, extraAxiosConfig, options);
    }


    pesquisaExperiencia(request, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/pesquisa-experiencia', request, null, extraAxiosConfig, options);
    }

    requerimentoDocumentosProntuario(request, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/requerimento-documentos-prontuario', request, null, extraAxiosConfig, options);
    }

    solicitacaoReagendConsultaCanceladaCovid(request, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/solicitacao-reagendamento-consulta-cancelada', request, null, extraAxiosConfig, options);
    }

    solicitacaoAgendamentoConsulta(request, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/solicitacao-agendamento-consulta', request, null, extraAxiosConfig, options);
    }

}

const formulariosClient = new FormulariosClient();
export default formulariosClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};