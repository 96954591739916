/*
** @name: Meu Clínicas - appExtraDocumentHead
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2023
** @description: Componente para adicionar/alterar elementos do <head> da página para módulos de servico
*/

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppGeneralSettingsPropertyByName } from '../../../core/appSpecificConfigHandler.js';


const AppExtraDocumentHead = (props) => {
    const { subTitle, robots } = props;
    const appContextConfig = useAppConfigContext().getContextConfig();
    const appName = getAppGeneralSettingsPropertyByName(appContextConfig, "app-name");
    const title = subTitle ? `${appName} - ${subTitle}` : null;
    if(title || robots) {
        return (
            <HelmetProvider>
                <Helmet>
                    { title && <title>{title}</title> }
                    { robots && <meta name="robots" content={robots} /> }
                </Helmet>
            </HelmetProvider>
        )
    }
    return null;
}

export default AppExtraDocumentHead;