/*
** @name: Meu Clínicas - obterLocalizador
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Junho 2021
** @description: Módulo para o paciente obter o localizador por meio de um questionário
*/

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { AppCustomImage, AppCustomMessage, AppCustomLink, AppCustomText } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';
import wikiClient from '../../apiClients/wiki/wikiClient.js';


// Import module styles
import './obterLocalizador.scss'; 


const OBTER_LOCALIZADOR_INSTRUCOES = 'OBTER-LOCALIZADOR-INSTRUCOES';

const STEP_INTRUCOES_LOADING = 'instrucoes-loading';
const STEP_INTRUCOES = 'instrucoes';
const STEP_PERGUNTAS = 'cadastro';
const STEP_ENVIADO = 'enviado';

const ObterLocalizador = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    return(
        <ObterLocalizadorImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class ObterLocalizadorImplem extends Component {
    constructor(props) {
        super(props);

        const initialFocus = 'prontuario';
        const user = this.props.authContext.properties.user;
        this.state = {
            currentStep: STEP_INTRUCOES_LOADING,
            instrucoesObterLocalizador: null,
            questionFormError: null,
            confirmarEnvio: false,
            finalTelefoneEnvio: "",
            form: {
                fields: {
                    prontuario: {
                        value: '',
                        backendName: 'prontuario',
                        regex: /^[0-9]{0,8}$/
                    },
                    nomeMae: {
                        value: '',
                        backendName: 'nomeMae',
                        regex: /^[a-zà-ýA-ZÀ-Ý]+\s?([a-zà-ýA-ZÀ-Ý]\s?)*$/,
                        maxSize: 75
                    },
                    naturalidade: {
                        value: '',
                        backendName: 'naturalidade',
                        regex: /^[a-zà-ýA-ZÀ-Ý]+\s?([a-zà-ýA-ZÀ-Ý]\s?)*$/,
                        maxSize: 50
                    },
                    cpf: {
                        value: user.cpf,
                        backendName: 'cpf'
                    },
                    dataNascimento: {
                        value: user.dtNascimento,
                        backendName: 'dtNascimento'
                    },
                },
                fieldToFocus: initialFocus
            },
        };
    }

    _clearErrors = () => {
        this.setState({ 
            questionFormError: null
         });
    }

    _focusFormField = () => {
        if(!this.state.form.fieldToFocus) {
            return;
        }

        let l_obj = document.getElementsByName(this.state.form.fieldToFocus);
        if(l_obj.length > 0) {
            let obj = l_obj[0];
            obj.focus();

            let form = this.state.form;
            form.fieldToFocus = false;
            this.setState({
                form: form
            });
        }
    }

    _getFieldClasses = (fieldName) => {
        let classes = "";
        classes += this.state.form.fields[fieldName].value ? 'has-content' : 'empty';
        classes += ' valid';
        return classes;
    }

    _getFormValuesToSubmit = () => {
        const submitValues = {};
        const form = this.state.form;

        for (var prop in form.fields) {
            const field = form.fields[prop];
            if(genesysUtils.array.inArray(prop, ["dataNascimento"])) {
                submitValues[field.backendName] = utils.convertDateToEpochAtMidday(field.value);
            } else {
                submitValues[field.backendName] = field.value;
            }
        }

        return submitValues;
    }

    _handleAvancar = () => {
        this.setState({
            currentStep: STEP_PERGUNTAS
        });
    }

    _handleConfirmationOk = () => {
        this._handleConfirmationCancel();
        this._processarEnvio();
    }

    _handleConfirmationCancel = () => {
        this.setState({
            confirmarEnvio: false
        });
    }

    _handleChange = (e, { name, value }) => {
        const updatedForm = this.state.form;
        const disabled = updatedForm.fields[name].disabled;
        const fieldRegex = updatedForm.fields[name].regex;
        const maxSize = updatedForm.fields[name].maxSize;

        // Valida campo
        if(disabled || (value && fieldRegex && !fieldRegex.test(value)) ||
            (value && maxSize && value.length > maxSize)) {
            return;
        }

        // Atualiza 'case' se for o caso
        if(genesysUtils.array.inArray(name, ["nomeMae", "naturalidade"])) {
            updatedForm.fields[name].value = value ? value.toUpperCase() : "";
        } else {
            updatedForm.fields[name].value = value;
        }

        updatedForm.fields[name].errorMessage = null;
        updatedForm.fieldToFocus = false;

        this.setState({ 
            form: updatedForm
        });
        this._clearErrors();
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleEnviar = () => {
        this.setState({
            confirmarEnvio: true
        });
    }

    _loadInstructions = () => {
        if(this.state.instrucoesObterLocalizador) {
            return;
        }

        // Buscar instrucoes de cadastro => userFaq questionId === OBTER_LOCALIZADOR_INSTRUCOES
        wikiClient.userFaq(OBTER_LOCALIZADOR_INSTRUCOES, false, null, {}, {})
            .then(res => {
                const result = res.data.listUserFaq;
                const newState = result.constructor===Array && result.length===1 ? STEP_INTRUCOES : STEP_PERGUNTAS;
                this.setState({ 
                    currentStep: newState,
                    instrucoesObterLocalizador: result[0].resposta
                });
            })
            .catch(err => {
                this.setState({ currentStep: STEP_PERGUNTAS });
            });
    }
    
    _processarEnvio = () => {
        this._clearErrors();
        usuarioClient.obterLocalizador(this._getFormValuesToSubmit(), {}, {})
            .then(res => {
                this.setState({
                    finalTelefoneEnvio: res.data,
                    currentStep: STEP_ENVIADO
                });
            })
            .catch(err => {
                if(this._updateGeneralErrors(err.response)) {
                    return;
                }

                this.setState({
                    questionFormError: {
                        header: 'Ops...',
                        message: 'Ocorreu um erro ao processar sua requisição'
                    }
                });
            });
    }

    _updateGeneralErrors = (response) => {
        if(response && response.status === 400) {
            this.setState({
                questionFormError: {
                    header: "Atenção",
                    message: "Dados informados não conferem e/ou incompletos para validação."
                }
            });
            return true;
        }
        if(response && response.status === 406) {
            this.setState({
                questionFormError: {
                    header: "Atenção",
                    message: "Você deve responder a todas as perguntas."
                }
            });
            return true;
        }
        if(response && response.status === 429) {
            this.setState({
                questionFormError: {
                    header: "Atenção",
                    message:
                        <AppCustomMessage
                            elemType="div"
                            messageId="obter-localizador_muitas-tentativas" 
                            className="contato-central"
                            params={[
                                <AppCustomLink linkName="faq-contato-telefone" className="central-phone-number" />,
                                <AppCustomText 
                                    propertyPath="applicationConfig.linksSettings.faq-contato-telefone" 
                                    propertyName="horario-atendimento" />
                            ]}
                        />
                }
            });
            return true;
        }
        return false;
    }

    _validateForm = () => {
        const fields = this.state.form.fields;
        return fields.prontuario.value && fields.nomeMae.value && fields.naturalidade.value ? true : false;
    }

    Render_Enviado = () => {
        const finalTelefone = this.state.finalTelefoneEnvio;
        const msgTelefone = `(**) *****-${finalTelefone}`;
        return(
            <div className="confirmacao-envio">
                <div className="info-principal">
                    <h2>Envio do localizador realizado com sucesso!</h2>
                </div>

                <div className="localizador-enviado">
                    <AppCustomImage imageContextFn={getImageThemeContext} module="obterLocalizador" imageId="logo_envio" />
                </div>

                <div className="info-adicional">
                    <p>O seu localizador foi enviado para o telefone {msgTelefone}.</p>
                    <p>Inclua o código localizador disponibilizado quando solicitado para ter acesso a todas as funcionalidades disponíveis.</p>
                </div>

                <div className="main-action">
                    <button 
                        id="button-envio-ok"
                        className="app-form-button"
                        onClick={() => this._handleClose()} >
                        OK
                    </button>
                </div>
            </div>
        );
    }

    Render_Instruction = () => {
        return(
            <div className="obter-localizador-instrucoes">
                <div className="instrucoes-content">
                    <AppCustomMessage messageTemplate={this.state.instrucoesObterLocalizador} />
                </div>

                <div className="main-action">
                    <button
                        id="button-avancar"
                        className="app-form-button"
                        onClick={() => { this._handleAvancar() }}
                    >
                        AVANÇAR
                    </button>
                </div>
            </div>
        );
    }

    Render_QuestionsForm = () => {
        const fields = this.state.form.fields;

        return(
            <Form className="obter-localizador-form">

                <div className="float-label-field prontuario">
                    <Input 
                        fluid
                        id="input-prontuario"
                        name="prontuario"
                        className={`input-wrapper ${this._getFieldClasses("prontuario")}`}
                        onChange={(e) => this._handleChange(e, {name: "prontuario", value: e.target.value})}
                        placeholder=""
                        autoComplete="off"
                        label="Prontuário"
                        labelPosition="right"
                        value={fields.prontuario.value}
                    />
                </div>

                <div className="float-label-field nome-mae">
                    <Input 
                        fluid
                        id="input-nome-mae"
                        name="nomeMae"
                        className={`input-wrapper ${this._getFieldClasses("nomeMae")}`}
                        onChange={(e) => this._handleChange(e, {name: "nomeMae", value: e.target.value})}
                        placeholder=""
                        autoComplete="nameMae"
                        label="Nome completo da mãe"
                        labelPosition="right"
                        value={fields.nomeMae.value}
                    />
                </div>

                <div className="float-label-field nome-mae">
                    <Input 
                        fluid
                        id="input-nome-mae"
                        name="naturalidade"
                        className={`input-wrapper ${this._getFieldClasses("naturalidade")}`}
                        onChange={(e) => this._handleChange(e, {name: "naturalidade", value: e.target.value})}
                        placeholder=""
                        autoComplete="naturalidade"
                        label="Naturalidade"
                        labelPosition="right"
                        value={fields.naturalidade.value}
                    />
                </div>

                <div className="float-label-field cpf">
                    <Input 
                        fluid
                        id="input-cpf"
                        name="cpf"
                        disabled={true}
                        className={`input-wrapper ${this._getFieldClasses("cpf")}`}
                        onChange={() => {}}
                        placeholder=""
                        autoComplete="cpf"
                        label="CPF"
                        labelPosition="right"
                        value={fields.cpf.value}
                    />
                </div>

                <div className="float-label-field data-nascimento">
                    <Input 
                        fluid
                        id="input-data-nascimento"
                        name="dataNascimento"
                        disabled={true}
                        className={`input-wrapper ${this._getFieldClasses("dataNascimento")}`}
                        onChange={() => {}}
                        placeholder=""
                        autoComplete="bday"
                        label="Data de nascimento"
                        labelPosition="right"
                        value={fields.dataNascimento.value}
                    />
                </div>

                { this.state.questionFormError &&
                <AppMessageBox 
                    id="msg-question-error" 
                    className="error" 
                    messageData={this.state.questionFormError} />
                }

                <div className="main-action">
                    <button
                        id="button-enviar"
                        className="app-form-button"
                        disabled={!this._validateForm()}
                        onClick={() => this._handleEnviar()}
                    >
                        ENVIAR
                    </button>
                </div>
            </Form>
        );
    }

    Render_Switch = () => {
        const { currentStep } = this.state;
        switch(currentStep) {
            case STEP_INTRUCOES_LOADING:
                return null;
            case STEP_INTRUCOES:
                return this.Render_Instruction();
            case STEP_PERGUNTAS:
                return this.Render_QuestionsForm();
            case STEP_ENVIADO:
                return this.Render_Enviado();
            default:
                return null;
        }
    }

    componentDidMount() {
        if(this.state.currentStep===STEP_INTRUCOES_LOADING) {
            this._loadInstructions();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.currentStep===STEP_PERGUNTAS) {
            this._focusFormField();
        }
    }

    render() {
        return(
            <AppCardModuleBasicWrapper wrapperName="obter-localizador">

                { this.state.confirmarEnvio &&
                <AppConfirmationDialog
                    title="Atenção"
                    message="Confirma envio do formulário?"
                    hideCancelButton={false}
                    onConfirm={() => this._handleConfirmationOk()}
                    onCancel={() => this._handleConfirmationCancel()}
                />
                }

                { this.Render_Switch() }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default ObterLocalizador;