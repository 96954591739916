/*
** @name: Meu Clínicas - appExternalServiceHeader
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render do cabeçalho padrão para módulos de serviços externos
** (páginas não atreladas ao fluxo normal da aplicação)
*/

import React from 'react';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../../core/appSpecificConfigHandler.js';


const LogoImage = () => {
    return(
        <AppCustomImage imageContextFn={getImageThemeContext} 
            module="appExternalServicesCommon" 
            imageId="logo_app-vertical" />
    )
}

const AppExternalServiceHeader = (props) => {
    return(
        <div className="header-wrapper">
            <div className="logo-wrapper">
                <div className="logo-box">
                    { props.linkToHome 
                    ?
                    <a href="/" id="link-pagina-inicial">
                        <LogoImage />
                    </a>
                    :
                    <LogoImage />
                    }
                </div>
            </div>
            <div className="header-content">
                {props.children}
            </div>
        </div>
    );
}

export default AppExternalServiceHeader;