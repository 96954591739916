/*
** @name: Meu Clínicas - userHome
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Módulo para renderizar a seção de novidades na home
*/

import React, { Fragment } from 'react';
import { Accordion } from 'semantic-ui-react';

import ImageCarousel from '../../components/general/imageCarousel/imageCarousel.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';


const Novidades = (props) => {
    const { position, news, onAccordionClick } = props;
    const sectionTitle = news.title ? news.title : "Novidades";

    return(
        <Fragment key={`keyNovidades${position}`}>
            { (news && news.list && news.list.length>0) ?
            <div className="news-section">
                <Accordion fluid styled>
                    <div className={`accordion-item${news.active ? " active" : ""}`}>
                        <Accordion.Title onClick={(e) => onAccordionClick(e, news)}>
                            <div className="title-info-wrapper">
                                <div className="information">{sectionTitle}</div>
                            </div>
                            <div className="collapsible-wrapper">
                                <AccordionCollapsible active={news.active} iconVariant={null} />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content>

                            {news.list.map((item, index) => {
                                const { imagens, visivel } = item;
                                if(!visivel) {
                                    return null;
                                }
                                return(
                                    <div key={`news_${index}`} className="content-row">
                                        <div className="news-carousel-wrapper">

                                            <ImageCarousel 
                                                keyBase="carousel" 
                                                interval={10000}
                                                imageList={imagens} />

                                        </div>
                                    </div> 
                                )
                            })}

                        </Accordion.Content>
                    </div>
                </Accordion>
            </div>
            :
            <>
                { !news.hideWhenEmpty &&
                <div className="empty-section">
                    <div className="title">{sectionTitle}</div>
                </div>
                }
            </>
            }
        </Fragment>
    )
}

export default Novidades;