/*
** @name: Meu Clínicas - instrucoesCadasto
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar passo de setup do cadastro
*/
import React from "react";

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const Setup = (props) => {
    const { setupError, onClose } = props;
    return(
        <div className="module-setup">

            { setupError &&
            <>
                <AppMessageBox 
                    id="msg-cadastro-error" 
                    className="error" 
                    messageData={setupError} />

                <div className="main-action">
                    <button id="button-fechar" className="app-form-button" onClick={onClose}>OK</button>
                </div>
            </>
            }

        </div>
    );
}

export default Setup;
