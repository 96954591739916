import React from "react";
import { Dropdown } from 'semantic-ui-react';
import { FormBuilderSupportFields } from 'react-dj-forms-builder';


const DropdownField = (props) => {
    const { id, name, label, placeHolder, disabled, requiredMark, options, value, otherParams, onChange, errorMessage } = props;
    const dropdownClassName = `dropdown-field-wrapper${(disabled ? " disabled" : "")}`;
    if(otherParams && !otherParams.noResultsMessage) {
        otherParams.noResultsMessage = "";
    }

    return(
        <div className={dropdownClassName}>
            { label &&
            <div className="field-label">{label}{requiredMark ? <FormBuilderSupportFields.RequiredMark /> : null}</div>
            }
            <input 
                id={id}
                name={name}
                disabled={disabled}
                type="hidden"
                value={value ? value : ""}
            />
            <Dropdown
                id={`${id}_dropdown`}
                disabled={disabled}
                placeholder={placeHolder}
                options={options}
                value={value}
                onChange={(e, elem) => onChange(e, {name: name, value: elem.value }) }
                selection
                {...otherParams} />
            { errorMessage &&
            <div className="field-error">{errorMessage}</div>
            }
        </div>
    )
}

export default DropdownField;