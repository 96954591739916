import React from 'react';

import { Input } from 'semantic-ui-react';


const TEXT_MASK = null;


const QuestionTextField = (props) => {
    const { id, name, className, message, value, onChange } = props;
    return(
        <div className={`${className} float-label-field`}>
            <Input 
                fluid
                id={id}
                name={name}
                className={`input-wrapper ${value ? 'has-content': 'empty'}`}
                onChange={(e) => onChange({ name: name, value: e.target.value })}
                placeholder=""
                mask={TEXT_MASK}
                label={message}
                labelPosition="right"
                value={value}
            />
        </div>
    );        
}

export default QuestionTextField;
