/*
** @name: Meu Clínicas - consultasClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de consultas
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';


const API_PORT = utils.isDev() ? 8084 : null;
const API_SERVICE = utils.isDev() ? null : 'consultas-service';
const API_VERSION = 'v2';

class ConsultasClient {
    #apiRequest;

    constructor() {
        this.#apiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#apiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    buscarListaConsultas(pacCodigo, dtInicial, dtFinal, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, dtInicial, dtFinal };
        return this.#apiRequest.auth.get('/consultas/lista', queryParams, null, extraAxiosConfig, options);
    }

    buscarPDFTicketConsulta(pacCodigo, numero, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, numero };
        return this.#apiRequest.auth.get('/consultas/obter-ticket', queryParams, null, extraAxiosConfig, options);
    }

    buscarUltimaConsultaPorEspecialidade(pacCodigo, espSeq, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, espSeq };
        return this.#apiRequest.auth.get('/consultas/obter-ultima-por-especialidade', queryParams, null, extraAxiosConfig, options);
    }

    buscarListaEspecialidadeAgendamento(pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#apiRequest.auth.get('/agendamento/especialidades', queryParams, null, extraAxiosConfig, options);
    }

    buscarListaConsultasDisponiveisAgendamento(pacCodigo, espSeq, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, espSeq };
        return this.#apiRequest.auth.get('/agendamento/consultas-disponiveis', queryParams, null, extraAxiosConfig, options);
    }

    obterReservaConsulta(payload, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/agendamento/reserva', payload, null, extraAxiosConfig, options);
    }

    marcarConsulta(payload, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/agendamento/marcar', payload, null, extraAxiosConfig, options);
    }

    documentList(pacCodigo, numeroConsulta, extraAxiosConfig, options) {
        const queryParams = { pacCodigo, numeroConsulta };
        return this.#apiRequest.auth.get('/document/list', queryParams, null, extraAxiosConfig, options);
    }

    documentDelete(payload, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/document/delete', payload, null, extraAxiosConfig, options);
    }

    documentUpload(payload, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/document/upload', payload, null, extraAxiosConfig, options);
    }

    documentSaveBach(payload, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/document/save-batch', payload, null, extraAxiosConfig, options);
    }
}

const consultasClient = new ConsultasClient();
export default consultasClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};