import React from "react";

const WelcomeMessage = (props) => {
    const { title, message } = props;
    return(
        <div className="login-welcome-message">
            <div className="title">{title}</div>
            <div className="message">
                {message}
            </div>
        </div>
    );
}

export default WelcomeMessage;