import React from 'react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { Input } from 'semantic-ui-react';


const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/, /\d/, /\d/];


const QuestionDateField = (props) => {
    const { id, name, className, message, value, onChange } = props;
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

    return(
        <div className={`${className} float-label-field`}>
            <Input
                fluid
                children={
                    <div className={`masked-input-wrapper ${value ? 'has-content': 'empty'}`}>
                        <MaskedInput 
                            id={id}
                            name={name}
                            onChange={(e) => onChange({ name: name, value: e.target.value })}
                            keepCharPositions="true"
                            placeholder="dd/mm/aaaa"
                            pipe={autoCorrectedDatePipe}
                            mask={DATE_MASK}
                            autoComplete="bday"
                            value={value}
                        />
                        <div className='ui label label'>{message}</div>
                    </div>
                }
            />
        </div>
    );        
}

export default QuestionDateField;