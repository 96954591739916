/*
** @name: Meu Clínicas - externalServicesSessionLifetime
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2022
** @description: Módulo para exibição de barra indicativa de sessão expirando (Serviços externos)
** 
*/

import React, { useState, useEffect } from 'react';


// Import module styles
import './externalServicesSessionLifetime.scss';


const ExternalServicesSessionLifetime = (props) => {
    const { sessionStartTime, onExpire, onRefresh, sessionExpireTimeMs, expirationWarningSec } = props;
    const [ expired, setExpired ] = useState(false);
    const [ remainingSeconds, setRemainingSeconds ] = useState(null);
    const [ refreshed, setRefreshed ] = useState(false);
    useEffect(() => {
        if(sessionStartTime) {
            const calcRemainingSeconds = () => Math.max(0, Math.round((sessionExpireTimeMs - (Date.now() - sessionStartTime))/1000));
            const updateCounter = () => setRemainingSeconds(calcRemainingSeconds());
            const timerId = setInterval(() => updateCounter(), 1000);
            setExpired(false);
            setRefreshed(false);
            return () => clearInterval(timerId);
        }
        return () => {};
    }, [sessionStartTime, sessionExpireTimeMs]);

    if(remainingSeconds===null || remainingSeconds > expirationWarningSec) {
        return null;
    }

    if(remainingSeconds<=0 && !expired) {
        setExpired(true);
        if(onExpire) {
            onExpire();
        }
    }

    return(
        <div id="esSessionLifetimeId">
            <div className="session-lifetime-shadow"></div>
            <div className="session-lifetime-box">
                { expired ? "Sessão expirou." : 
                <>
                    Sua sessão expira em {remainingSeconds}s ...
                    { onRefresh &&
                    <div className="refresh-wrapper">
                        <button type="default" className="btn-refresh" disabled={refreshed} onClick={e => { setRefreshed(true); onRefresh(); }}>Atualizar</button>
                    </div>
                    }
                </>
                }
            </div>
        </div>
    );
}

export default ExternalServicesSessionLifetime;