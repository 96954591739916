/*
** @name: Meu Clínicas - validatorLocalizador
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2020
** @description: Validador de Localizador de Paciente (utilizado em modulos cadastrais e login)
*/

class ValidatorLocalizador {
    
    validate(localizador) {
        return /^[a-kA-K][a-zA-Z0-9]{7}$/.test(localizador);
    }

}

const validatorLocalizador = new ValidatorLocalizador();
export default validatorLocalizador;