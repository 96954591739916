/*
** @name: Meu Clínicas - formDispensacao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2023
** @description: Renderiza formulario de dispensação da receita
*/

import React, { useState, useEffect } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { pascalCase } from '@hcpa-react-components/string-utils';

import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import { InputField, TextareaField } from '../../components/fields/formsBuilderCustoms/';

import ModalConfirmarRegistroDispensacao from './modalConfirmarRegistroDispensacao.js';

import dispensacaoFormConfig from './dispensacaoForm.json';


const CNPJ_MASK = [/\d/, /\d/, '.',/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

const FormDispensacao = (props) => {
    const { erroDispensacao, resultadoDispensacao, onClose, onRegistrar } = props;
    const [exibeConfirmacao, setExibeConfirmacao] = useState(false);
    const [fields, setFields] = useState(props.fieldsData);
    const sucessoDispensacao = resultadoDispensacao && resultadoDispensacao.sucessoDispensacao;

    const _handleConfirmationOk = () => {
        setExibeConfirmacao(false);
        onRegistrar(fields);
    }

    const _handleFormChange = (fldState, name, value) => {
        if(name==="nomeFarmaceutico" && fldState[name]) {
            fldState[name].value = pascalCase(value);
        }
        setFields(fldState);
    }

    useEffect(() => {
        setConfigFieldProperty(dispensacaoFormConfig, ["cnpjFarmacia"], "masked.mask", CNPJ_MASK)
    }, []);

    return(
        <div className="wrapper-form-dispensacao">
            <div className="floating-form-dispensacao">
                { exibeConfirmacao && 
                <ModalConfirmarRegistroDispensacao
                    onConfirm={()=> _handleConfirmationOk()}
                    onCancel={() => setExibeConfirmacao(false)} /> }

                <div className="card-dispensacao no-select">
                    <div className="card-box">
                        <div className="card-content">
                            { erroDispensacao && 
                            <AppExternalServiceInfoMessage id="dispensacaoErrID" className="info-error">
                                {erroDispensacao}
                            </AppExternalServiceInfoMessage>
                            }

                            { sucessoDispensacao &&
                            <AppExternalServiceInfoMessage id="dispensacaoOkID" className="info-success">
                                Dispensação registrada com sucesso.
                            </AppExternalServiceInfoMessage> 
                            }

                            <FormBuilder
                                rootAsForm={true}
                                name="formDispensacao"
                                blockFieldUpdate={false}
                                disableClearErrorOnFieldChange={false}
                                config={dispensacaoFormConfig}
                                page={0}
                                fields={fields}
                                className="form-content" 
                                onChange={_handleFormChange}
                                overrideFieldRender={{
                                    'input': InputField,
                                    'textarea': TextareaField
                                }}
                                customComponents={{}}
                            />
                        </div>
                    </div>
                    { sucessoDispensacao 
                    ?
                    <div className="action-buttons">
                        <button 
                            type="button" 
                            className="btn-servicos btn-form-dispensacao"
                            onClick={()=> onClose()} >Ok</button>
                    </div>
                    :
                    <div className="action-buttons">
                        <div className="btn-wrapper">
                            <button 
                                type="button" 
                                className="btn-servicos btn-form-dispensacao"
                                onClick={()=> onClose()} >Cancelar</button>
                        </div>
                        <div className="btn-wrapper">
                            <button 
                                type="button" 
                                className="btn-servicos btn-form-dispensacao"
                                onClick={()=> setExibeConfirmacao(true)} >Registrar</button>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default FormDispensacao;