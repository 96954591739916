import React from "react";

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../../core/appSpecificConfigHandler.js';


const HangupButton = (props) => {
    const { onClick } = props;
    return(
        <div className="ctb-button button-hangup" onClick={onClick}>
            <div className="icon-wrapper">
                <AppCustomImage imageContextFn={getImageThemeContext} className="svg-invert" module="teleatendimento" imageId="icon_hangup" />
            </div>
        </div>
    );
}

const UploadButton = (props) => {
    const { onClick } = props;
    return(
        <div className="ctb-button button-upload" onClick={onClick}>
            <div className="icon-wrapper">
                <AppCustomImage imageContextFn={getImageThemeContext} className="svg-invert" module="teleatendimento" imageId="icon_upload" />
            </div>
        </div>
    );
}

export {
    HangupButton,
    UploadButton
};