/*
** @name: Meu Clínicas - appRequest
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: 16/setembro/2024
** @description: Rotinas para complemento ao módulo de chamdas REST
*/

import ApiRequest from '@hcpa-react-components/api-request';

import utils from './utils';
import sessionStorageManager from './sessionStorageManager';


const DISABLE_FORCED_LOGOUT = { disableForcedLogout: true };
const DISABLE_LOADING = { disableLoading: true };

const appForceLogout = (resp, resource) => {
    sessionStorageManager.clear();
    window.location.reload();
    appLoadingVisibility(false);
}

const appGetAuthenticationHeaders = () => {
    return {
        'jwt-header': sessionStorageManager.auth.getToken(),
        'fingerprint': sessionStorageManager.auth.getFingerprint(),
    };
}

const appLoadingVisibility = (visible) => {
    utils.setLoadingVisibility(visible);
}

const buildApiRequestInstance = (apiBaseRootUrl, apiServiceName, apiServicePort, apiVersion, disableLog) => {
    return ApiRequest.getInstance({
        apiBaseRootUrl,
        apiServiceName,
        apiServicePort,
        apiVersion,
        disableLog,
        onForceLogout: appForceLogout,
        onGetAuthenticationHeaders: appGetAuthenticationHeaders,
        onSetLoadingVisibility: appLoadingVisibility
    });
}

export {
    DISABLE_FORCED_LOGOUT,
    DISABLE_LOADING,
    appForceLogout,
    appGetAuthenticationHeaders,
    appLoadingVisibility,
    buildApiRequestInstance
}