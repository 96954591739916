/*
** @name: Meu Clínicas - mensagens
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Módulo para renderizar blocos de mensagens
*/
import React from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const ActionButtonFormSolicReagend = (props) => {
    const { id, text, onClick } = props;
    return (
        <>
            { (text && genesysUtils.typeCheck.isFunction(onClick)) &&
            <div className="main-action">
                <button id={id} className="app-form-button" onClick={onClick}>{text}</button>
            </div> 
            }
        </>
    )
}

const MensagemErroInicializacaoFormSolicReagend = (props) => {
    return (
        <>
            <div className="information-section">
                <div className="section-box">
                    <div className="section-content">
                        <AppMessageBox id="rcccMsgErrorId" className="error" messageData={{ message: <AppCustomMessage elemType="fragment" messageId="_general_modulo-indisponivel" /> }} />
                    </div>
                </div>
            </div>

            <ActionButtonFormSolicReagend id="rcccBtnErrorCloseId" text="OK" onClick={props.onClick} />
        </>
    )
}

const MensagemPreenchidoSolicReagend = (props) => {
    return(
        <>
            <div className="information-section">
                <div className="section-box">
                    <AppCustomMessage id="rcccMsgPreenchidoId" elemType="html" messageId="reagendamento-consulta-covid_mensagem-preenchido" className="section-content" />
                </div>
            </div>

            <ActionButtonFormSolicReagend id="rcccBtnPreenchidoCloseId" text="OK" onClick={props.onClick} />
        </>
    );
}

const MensagemSucessoSolicReagend = (props) => {
    return(
        <>
            <div className="information-section">
                <div className="section-box">
                    <AppCustomMessage id="rcccMsgSucessoId" elemType="html" messageId="reagendamento-consulta-covid_mensagem-sucesso" className="section-content" />
                </div>
            </div>

            <ActionButtonFormSolicReagend id="rcccBtnSucessoCloseId" text="OK" onClick={props.onClick} />
        </>
    );
}

export {
    ActionButtonFormSolicReagend,
    MensagemErroInicializacaoFormSolicReagend,
    MensagemPreenchidoSolicReagend,
    MensagemSucessoSolicReagend
}