/*
** @name: Meu Clínicas - faq
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2020
** @description: Módulo para perguntas frequentes / ajuda da aplicação
** 
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
** 
** @update: Março 2022
** @description: Atualizado rodape para utilizar mensagens do tema
*/

import React, { Component } from 'react';

import { EMPTY_HREF } from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { AppCustomImage, AppCustomMessage, AppCustomLink, AppCustomText } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import FaqContent from './faqContent.js';

import wikiClient from '../../apiClients/wiki/wikiClient.js';


// Import module styles
import './faq.scss'; 


const Faq = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <FaqImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class FaqImplem extends Component {
    constructor(props) {
        super(props);

        const context = props.context || {};
        this.state = {
            resultadoConsulta: null,
            allTags: false,
            listTags: context.tagList || [],
            filterTextToAdd: "",
            showLoading: false,
            erroConsulta: null
        }
    }

    _getUserFaq = (listTags) => {
        // Buscar userFaq
        this._setErroConsulta(null);
        wikiClient.userFaq(null, this.state.allTags, (listTags ? listTags : this.state.listTags), {}, {})
            .then(res => {              
                this.setState({
                    resultadoConsulta: res.data
                });
            })
            .catch(err => { 
                this._setErroConsulta("Ops!, ocorreu um erro atualizando as perguntas frequentes.");
            });
    }

    _handleAddFilterTag = () => {
        const tag = this.state.filterTextToAdd;
        if(tag && this._isValidTag(tag)) {
            this._handleChangeTagText("");
            this._handleAddTag(tag.toLowerCase(), true);
        }
    }

    _handleAddTag = (tag, keepList) => {
        let updateListTags = this.state.listTags;
        if(updateListTags.indexOf(tag) < 0) {
            if(keepList !== true) {
                updateListTags = [];
            }
            updateListTags.push(tag);
            this.setState({ 
                listTags: updateListTags
            });

            this._getUserFaq(updateListTags);
        }
    }

    _handleChangeTagText = (value) => {
        if(this._isValidTag(value)) {
            this.setState({
                filterTextToAdd: value ? value.toLowerCase() : value
            });
        }
    }

    _handleTagRemove = (tag) => {
        let updateListTags = this.state.listTags;
        const index = updateListTags.indexOf(tag);
        if(index >= 0) {
            updateListTags.splice(index, 1);
            this.setState({ 
                listTags: updateListTags
            });

            this._getUserFaq(updateListTags);
        }
    }

    _isValidTag = (value) => {
        const regEx = /^[a-zà-úA-ZÀ-Ú']{1,20}$/;
        if (value && !regEx.test(value)) {
            return false;
        }
        return true;
    }

    _setErroConsulta = (msg) => {
        this.setState({ erroConsulta: msg });
    }

    componentDidMount(){
        this._getUserFaq();
    }

    render() {
        const listFaq = this.state.resultadoConsulta ? this.state.resultadoConsulta.listUserFaq : null;

        return(
            <AppCardModuleBasicWrapper wrapperName="faq">

                <div className="filter-tags-card no-select">
                    <div className="filter-row">
                        <div className="filter-add-tag">
                            <div className="filter-add-input">
                                <div className="filter-input">
                                    <input 
                                        id="filterInputId"
                                        name="filter-input"
                                        className="filter-input-text"
                                        type="text"
                                        placeholder="Assunto para filtrar"
                                        onChange={(e) => this._handleChangeTagText(e.target.value)}
                                        value={this.state.filterTextToAdd} />
                                </div>
                            </div>
                            <div className="filter-add-btn-wrapper">
                                <div className="btn-add-wrapper" onClick={() => this._handleAddFilterTag()} ><div className="add"></div></div>
                            </div>
                        </div>
                    </div>
                    { this.state.listTags.length > 0 &&
                    <div className="filter-row">
                        <div className="title-column filter-label">Filtro:</div>
                        <div className="tags-column filter-tag-list">
                        { 
                            this.state.listTags.map((tag, indxTag) => {
                                return(
                                    <div 
                                        key={'filterTagItem-' + indxTag} 
                                        className="filter-item" >
                                        { tag }<a href={EMPTY_HREF} onClick={() => this._handleTagRemove(tag)}><div className="remove">-</div></a>
                                    </div>
                                )
                            }) 
                        }
                        </div>
                    </div>
                    }
                </div>

                <div className="sub-titulo-faq">PERGUNTAS FREQUENTES</div>

                { this.state.erroConsulta &&
                <AppMessageBox 
                    id="msg-faq-error" 
                    className="error" 
                    messageData={{ message: this.state.erroConsulta }} />
                }

                { listFaq &&
                <div className="faq-content-wrapper">
                    <FaqContent 
                        listFaq={listFaq}
                        hideTags={false}
                        hideImages={false}
                        onAddTag={this._handleAddTag} />
                </div>
                }

                <div className="faq-footer">
                    <div className="footer-box">
                        <div className="content-wrapper">
                            <div className="logo">
                                <AppCustomImage imageContextFn={getImageThemeContext} module="faq" imageId="logo_footer" />
                            </div>
                            <AppCustomMessage
                                elemType="div"
                                className="moreinfo-card"
                                messageId="faq_footer-more-info"
                                params={[
                                    <AppCustomLink linkName="faq-contato-telefone" className="phone-number" />,
                                    <AppCustomText 
                                        propertyPath="applicationConfig.linksSettings.faq-contato-telefone" 
                                        propertyName="horario-atendimento" />
                                ]}
                            />
                        </div>
                    </div>
                </div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default Faq;
