/*
** @name: Meu Clínicas - userHome
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Módulo para renderizar a seção de avisos/notificações na home
*/

import React, { Fragment } from 'react';
import { Accordion } from 'semantic-ui-react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { EMPTY_HREF } from '../../core/utils.js';
import notificationManager from '../../core/notificationManager.js';
import { getAppServiceIdByConfigName } from '../../core/appServiceList.js';
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';


const NOTICE_PREFIX_ICON = {
    "TCS-AGT": "icon_teleatendimento"
}

const _buildUserNoticeList = (config) => {
    const notif = notificationManager.notifications.getData();
    const notices = [];
    if(notif && genesysUtils.typeCheck.isArray(notif["notices"])){
        let { priorityToShow, maxItemsToShow } = config || {};
        maxItemsToShow = genesysUtils.typeCheck.isInteger(maxItemsToShow) ? maxItemsToShow : false;
        priorityToShow = genesysUtils.typeCheck.isArray(priorityToShow) ? priorityToShow : [];
        priorityToShow = priorityToShow.map(p => p.toUpperCase());

        notif["notices"].forEach(n => {
            if(genesysUtils.typeCheck.isObject(n) && n.id && n.description &&
                (n.priority && genesysUtils.array.inArray(n.priority.toUpperCase(), priorityToShow)) &&
                (maxItemsToShow===false || notices.length<maxItemsToShow)) {
                notices.push(n);
            }
        });
    }
    return notices;
}

const _getModuleData = (notice) => {
    if(getAppServiceIdByConfigName(notice.openModule)) {
        let moduleParam = null;
        try { moduleParam = JSON.parse(notice.paramModule) } catch(e) {}
        return {
            moduleId: notice.openModule,
            moduleParam
        }
    }
    return {};
}


const Notificacoes = (props) => {
    const { position, notifications, onAccordionClick, onOpenModule } = props;
    const sectionTitle = notifications.title ? notifications.title : "Avisos/Notificações";
    const userNotices = _buildUserNoticeList(notifications.notices);

    return(
        <Fragment key={`keyNotificacoes${position}`}>
            { (userNotices && userNotices.length>0) ?
            <div className="notification-section">
                <Accordion fluid styled>
                    <div className={`accordion-item${notifications.active ? " active" : ""}`}>
                        <Accordion.Title onClick={(e) => onAccordionClick(e, notifications)}>
                            <div className="title-info-wrapper">
                                <div className="information">{sectionTitle}</div>
                            </div>
                            <div className="collapsible-wrapper">
                                <AccordionCollapsible active={notifications.active} iconVariant={null} />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content>
                            {userNotices.map((notice, index) => {
                                const { moduleId, moduleParam } = _getModuleData(notice);
                                const prefix = notice.id.split('_')[0];
                                const imageId = NOTICE_PREFIX_ICON[prefix];
                                const icon = imageId ? <AppCustomImage imageContextFn={getImageThemeContext} module="notificacoes" imageId={imageId} className="notice-icon" /> : null;
                                const anchorParams = !moduleId ? null : {
                                    href: EMPTY_HREF,
                                    rel: "noopener noreferrer",
                                    onClick: () => onOpenModule(moduleId, moduleParam, false)
                                }
                                const noticeContent = anchorParams ? <a {...anchorParams}>{icon}{notice.description}</a> : <>{icon}notice.description</>;

                                return(
                                    <div key={`notice_${index}`} className="content-row notice">
                                        { noticeContent }
                                    </div>
                                )
                            })}
                        </Accordion.Content>
                    </div>
                </Accordion>
            </div>
            :
            <>
                { !notifications.hideWhenEmpty &&
                <div className="empty-section">
                    <div className="title">{sectionTitle}</div>
                </div>
                }
            </>
            }
        </Fragment>
    )
}

export default Notificacoes;