import React from 'react';
import { Form } from 'semantic-ui-react';

import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import EmailCPFLocalizador from '../../components/fields/emailCPFLocalizador/emailCPFLocalizador.js';

import ExtraActions from './extraActions.js';
import MainButton from './mainButton.js';
import ManterConectado from './manterConectado.js';


const StepEntrada = (props) => {
    const { appContextConfig, disableAvancar, manterConectado, showManterConectado, errorMessage,
            onManterConectadoToggle, onLoginChange, onAvancar, onCadastro, onAjuda } = props;
    return (
        <Form className="login-form identificacao">
            <div className="float-label-field email-cpf-localizador">
                <EmailCPFLocalizador 
                    id='input-email-cpf-localizador' 
                    name='cpfEmailLocalizador' 
                    labelText={<AppCustomMessage messageId="login_login-placeholder" elemType="fragment" />}
                    onChange={onLoginChange}
                    disableCPF={false}
                    disableEmail={false}
                    disableLocalizador={false}
                />
            </div>

            <ManterConectado visible={showManterConectado} value={manterConectado} onToggle={onManterConectadoToggle} />
            <MainButton
                id="button-acessar" 
                type="default"
                wrapperClassName="main-action"
                className="app-form-button"
                disabled={disableAvancar}
                onClick={onAvancar}
                text="Avançar" />
            <ExtraActions 
                appContextConfig={appContextConfig}
                onVoltar={null}
                onCadastro={onCadastro}
                onRecuperarSenha={null}
                onAjuda={onAjuda} />

            { errorMessage ?
            <AppMessageBox 
                id="msg-login-error"
                className="error login-message-box"
                messageData={errorMessage} />
            :
            <div className="login-message-box"></div>
            }
        </Form>
    );
}

export default StepEntrada;