/*
** @name: Meu Clínicas - lgpdIntegrationClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de login (integração LGPD)
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiRequestInstance } from '../../core/appRequest.js';

import { API_PORT, API_SERVICE, API_VERSION } from './loginClient.js';


class LgpdIntegrationClient {
    #apiRequest;

    constructor() {
        this.#apiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    obterTermo(dataReferencia, forceRequest, extraAxiosConfig, options) {
        const extraPath = dataReferencia ? `/data-referencia/${dataReferencia}` : (forceRequest ? '/forcar-nova-requisicao' : "");
        return this.#apiRequest.noAuth.get(`/public/lgpd/obter-termo${extraPath}`, null, null, extraAxiosConfig, options);
    }

    obterConsentimento(pacCodigo, extraAxiosConfig, options) {
        return this.#apiRequest.auth.get(`/lgpd/obter-consentimento/${pacCodigo}`, null, null, extraAxiosConfig, options);
    }

    gravarConsentimento(pacCodigo, resposta, extraAxiosConfig, options) {
        return this.#apiRequest.auth.get(`/lgpd/gravar-consentimento/${pacCodigo}/${resposta}`, null, null, extraAxiosConfig, options);
    }

}

const lgpdIntegrationClient = new LgpdIntegrationClient();
export default lgpdIntegrationClient;
