/*
** @name: Meu Clínicas - userStatistics
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2024
** @description: Módulo para renderizar seção de estatísticas de usuários no dashboard (Refatorado do módulo principal)
*/

import React from 'react';

import { Accordion } from 'semantic-ui-react';

import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';

const UsersStatistics = (props) => {
    const { fetchingMessage, onToggle } = props;
    const users = props.data;
    if(!users) {
        return null;
    }

    return(
        <div className={`accordion-item${users.active ? " active" : ""} statistic-users`}>
            <Accordion.Title onClick={() => onToggle(users)}>
                <div className="title-info-wrapper">
                    <div className="information">Usuários</div>
                </div>
                <div className="collapsible-wrapper">
                    <AccordionCollapsible active={users.active} iconVariant={null} />
                </div>
            </Accordion.Title>
            <Accordion.Content>
                { !users.data ? fetchingMessage :
                <div className="statistic-content-wrapper">
                    <div className="statistic-row">
                        <span className="item-title">Total de usuários:</span>
                        <span className="item-info">{users.data.usuariosCadastrados}</span>
                    </div>
                    <div className="statistic-row">
                        <div className="item-title">Ativos (e-mail ativado):</div>
                        <div className="item-info">{users.data.usuariosAtivos}</div>
                    </div>
                    <div className="statistic-row">
                        <div className="item-title">Cadastro com localizador vinculado:</div>
                        <div className="item-info">{users.data.usuariosComLocalizador}</div>
                    </div>
                    <div className="statistic-row">
                        <div className="item-title">Usuários de acesso interno (sem cadastro vinculado):</div>
                        <div className="item-info">{users.data.usuariosInternoAcessoSemCadastro}</div>
                    </div>
                    <div className="statistic-row">
                        <div className="item-title">Total usuários que acessaram ao menos uma vez:</div>
                        <div className="item-info">{(users.data.usuariosComLocalizador + users.data.usuariosInternoAcessoSemCadastro)}</div>
                    </div>
                    <div className="statistic-row">
                        <div className="item-title">Novos cadastros nos 6 meses anteriores:</div>
                        <div className="item-info">{users.data.usuariosNovosSeisMesesAnteriores}</div>
                    </div>
                    <div className="statistic-row">
                        <div className="item-title">Novos cadastros no Mês antual:</div>
                        <div className="item-info">{users.data.usuariosNovosMTD}</div>
                    </div>
                    <div className="statistic-row">
                        <div className="item-title">Novos cadastros no Ano:</div>
                        <div className="item-info">{users.data.usuariosNovosYTD}</div>
                    </div>
                </div>
                }
                </Accordion.Content>
        </div>
    );
}

export default UsersStatistics;