/*
** @name: Meu Clínicas - appSpecificConfigHandler
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @update: Agosto 2024
** @description: Componente que provem rotinas para acesso às configurações em uso especificas da aplicação.
** Separado no processo de externalização/separacao do thema e configurações.
** Prove rotinas para manipulacao das configurações específicas da aplicação
**      // app specific settings getters
**      => getAppGeneralSettings - Retorna o objeto 'generalSettings' da configuração da aplicação [getAppConfig()]
**      => getAppImageCacheSettings - Retorna o objeto 'imageCacheSettings' da configuração da aplicação [getAppConfig()]
**      => getAppLinkRedirectSettings - Retorna o objeto 'linkRedirectSettings' da configuração da aplicação [getAppConfig()]
**      => getAppMobileSettings - Retorna o objeto 'mobileSettings' da configuração da aplicação [getAppConfig()]
**      => getAppNavigationSettings - Retorna o objeto 'navigationSettings' da configuração da aplicação [getAppConfig()]
**      => getAppServicesSettings - Retorna o objeto 'servicesSettings' da configuração da aplicação [getAppConfig()]
**      => getAppUserHomeSettings - Retorna o objeto 'userHomeSettings' da configuração da aplicação
**
**      // extra config/settings getters
**      => getAppGeneralSettingsPropertyByName - Retorna propriedade das configuracoes gerais pelo nome
**      => getAppLinkRedirectSettingsPropertyByName - Retorna propriedade do 'applicationConfig.linkRedirectSettings' pelo nome
**      => getAppServiceSettingsByName - Retorna objeto de configuração de um serviço específico
**
**      // special routines
**      => importCustomImage - Import a custom image based on it group/section and Id
**      => isAppServiceEnabled - Retorna informação de um servico está habilitado conforme configuração da aplicação
*/

import { getAppConfig, importAppCustomImage } from '@hcpa-react-components/app-customization';


// app specific settings getters
const getAppGeneralSettings = (ctxCfg) => {
    return getAppConfig(ctxCfg).generalSettings || {};
}

const getAppImageCacheSettings = (ctxCfg) => {
    return getAppConfig(ctxCfg).imageCacheSettings || {};
}

const getAppLinkRedirectSettings = (ctxCfg) => {
    return getAppConfig(ctxCfg).linkRedirectSettings || {};
}

const getAppMobileSettings = (ctxCfg) => {
    return getAppConfig(ctxCfg).mobileSettings || {};
}

const getAppNavigationSettings = (ctxCfg) => {
    return getAppConfig(ctxCfg).navigationSettings || {};
}

const getAppServicesSettings = (ctxCfg) => {
    return getAppConfig(ctxCfg).servicesSettings || {};
}

const getAppUserHomeSettings = (ctxCfg) => {
    return getAppConfig(ctxCfg).userHomeSettings || {};
}

// general ctxCfg getters
const getAppGeneralSettingsPropertyByName = (ctxCfg, propertyName) => {
    return getAppGeneralSettings(ctxCfg)[propertyName];
}

const getAppLinkRedirectSettingsPropertyByName = (ctxCfg, propertyName) => {
    return getAppLinkRedirectSettings(ctxCfg)[propertyName];
}

const getAppServiceSettingsByName = (ctxCfg, serviceName) => {
    return getAppServicesSettings(ctxCfg)[serviceName];
}

// special routines
const getImageThemeContext = () => require.context(`../theme/images/`, true);

const importCustomImage = (currConfig, module, imageId, disableException) => {
    return importAppCustomImage(currConfig, module, imageId, getImageThemeContext, disableException);
}

const isAppServiceEnabled = (currConfig, serviceName, exceptionOnMissingConfiguration) => {
    try {
        return getAppServiceSettingsByName(currConfig, serviceName).enable;
    } catch(e) {
        if(exceptionOnMissingConfiguration) {
            throw new Error("Appliation configuration don't contains service configuration property 'servicesSettings.<service_name>' for '" + serviceName + "'");
        }
        return false;
    }
}

export {
    getAppGeneralSettings,
    getAppImageCacheSettings,
    getAppLinkRedirectSettings,
    getAppMobileSettings,
    getAppNavigationSettings,
    getAppServicesSettings,
    getAppUserHomeSettings,
    getAppGeneralSettingsPropertyByName,
    getAppLinkRedirectSettingsPropertyByName,
    getAppServiceSettingsByName,
    getImageThemeContext,
    importCustomImage,
    isAppServiceEnabled
}
