/*
** @name: Meu Clínicas - watermark
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2020
** @description: Componente para render da marca d'água em receita dispensada
** e possibilidade customizar cabeçalho e rodapé
*/

import React from 'react';


// Import module styles
import './watermark.scss';


const Watermark = (props) => {
    const { message, messageClassName } = props;
    return(
        <div id="watermarkID">
            <div className="watermark-wrapper">
                <div className="watermark-content-wrapper">
                    <div className="watermark-text-wrapper">
                        <p className={`watermark-text ${messageClassName}`}>
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Watermark;