/*
** @name: Meu Clínicas - registerDone
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar finalizacao do cadastro
*/
import React from "react";

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';


const RegisterDone = (props) => {
    const { onClose } = props;
    return (
        <div className="confirmacao-registro">
            <div className="info-principal">
                <h2>Cadastro realizado com sucesso!</h2>
            </div>

            <div className="email-enviado">
                <AppCustomImage imageContextFn={getImageThemeContext} module="cadastro" imageId="logo_email-ativacao" />
            </div>

            <div className="info-adicional">
                <p>Te enviamos um e-mail para a ativação de sua conta.</p>
            </div>

            <div className="main-action">
                <button id="button-registro-ok" className="app-form-button" onClick={onClose}>OK</button>
            </div>
        </div>
    )
}

export default RegisterDone;
