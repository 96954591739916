const RECAPTCHA_ELEMENT_ID = 'recaptchaScriptId';

const loadReCaptcha = siteKey => {
    // Avoid including it twice
    const elem = document.getElementById(RECAPTCHA_ELEMENT_ID);
    if(elem) {
      return;
    }

    // Load ReCaptch script
    const script = document.createElement('script')

    script.id = RECAPTCHA_ELEMENT_ID;
    script.type = 'text/javascript';
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`

    document.body.appendChild(script)
}

export default loadReCaptcha
