/*
** @name: Meu Clínicas - imageCacheManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2023
** @description: Prove rotinas para o ralizar o cache de imagens da aplicação
*/

import { getAppThemeImagesSettings } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { getAppImageCacheSettings, importCustomImage } from './appSpecificConfigHandler.js';


const appCacheImages = async (appContextConfig, cacheContainer) => {
    resetCacheControl();
    const imgCacheConfig = getAppImageCacheSettings(appContextConfig);
    if(imgCacheConfig && imgCacheConfig.enabled) {
        const { requiredModules, priorityModules } = imgCacheConfig;
        const container = imgCacheConfig.renderCachedImages ? cacheContainer : null;
        if(genesysUtils.typeCheck.isArray(requiredModules) && requiredModules.length) {
            const requiredImages = buildImageList(appContextConfig, requiredModules);
            await cacheImagesList(requiredImages)
                .then(res => appendToContainer(container, res))
                .catch(() => { });
        }
        const priorityImages = genesysUtils.typeCheck.isArray(priorityModules) ? buildImageList(appContextConfig, priorityModules) : [];
        cacheImagesList(priorityImages)
            .then(res => {
                appendToContainer(container, res);
                const allImages = buildImageList(appContextConfig);
                cacheImagesList(allImages)
                    .then(res => { appendToContainer(container, res); })
                    .catch(() => { });
            })
            .catch(() => { });
    }
}

const appendToContainer = (container, cachedList) => {
    if(container && genesysUtils.typeCheck.isArray(cachedList)) {
        cachedList.forEach(ci => {
            if(ci.img) {
                container.appendChild(ci.img);
            }
        });
    }
}

const buildImageList = (appContextConfig, moduleList) => {
    const imagesSettings = genesysUtils.typeCheck.isObject(appContextConfig) ? getAppThemeImagesSettings(appContextConfig) : null;
    if(genesysUtils.typeCheck.isObject(imagesSettings)) {
        const imageList = [];
        Object.keys(imagesSettings).forEach(moduleId => {
            const moduleCfg = imagesSettings[moduleId];
            if((!genesysUtils.typeCheck.isArray(moduleList) || moduleList.includes(moduleId)) && genesysUtils.typeCheck.isObject(moduleCfg)) {
                Object.keys(moduleCfg).forEach(imageId => {
                    const image = importCustomImage(appContextConfig, moduleId, imageId, true);
                    if(image && image.substring(0, 5)!=="data:" && !imageList.includes(image)) { // ignore inline image data and duplicated items
                        imageList.push(image);
                    }
                })
            }
        });
        return imageList;
    }
    return null;
}

const cacheImage = async (src, disableCacheControl) => {
    return new Promise(resolve => {
        if(disableCacheControl || !isImageCached(src)) {
            const img = new Image();
            img.onload = () => {
                if(!disableCacheControl) {
                    updateCacheControl(src, true);
                }
                resolve({ success: true, src, img });
            };
            img.onerror = () => {
                if(!disableCacheControl) {
                    updateCacheControl(src, false);
                }
                resolve({ success: false, src, img: null });
            };
            img.src = src;
        } else {
            resolve({ success: true, src, img: null });
        }
    });
}

const cacheImagesList = async (srcList, disableCacheCheck) => {
    const promises = await srcList.map((src) => {
        return cacheImage(src, disableCacheCheck);
    })
    return Promise.all(promises);
}

const isImageCached = src => {
    return src && window.__cachedImages && window.__cachedImages[src];
}

const resetCacheControl = () => {
    window.__cachedImages = null;
}

const updateCacheControl = (src, cached) => {
    if(!genesysUtils.typeCheck.isObject(window.__cachedImages)) {
        window.__cachedImages = {};
    }
    if(src) {
        if(cached) {
            window.__cachedImages[src] = true;
        } else {
            delete window.__cachedImages[src];
        }
    }
}

export {
    appCacheImages
}