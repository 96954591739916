/*
** @name: Meu Clínicas - instrucoes
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Módulo para renderizar mensagem de instruções geral e dos formulários
*/

import React from 'react';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';


const InstrucoesGeral = (props) => {
    const { exibirMensagemSelecao } = props;
    return (
        <AppCustomMessage 
            messageId="pesq-experiencia_intrucoes-iniciais" 
            params={[exibirMensagemSelecao ? <AppCustomMessage messageId="pesq-experiencia_intrucoes-iniciais-selecao" /> : ""]} />
    );
}

const InstrucoesPesquisa = () => {
    return(
        <>
            <AppCustomMessage
                elemType="div"
                className="instruction-message"
                messageId="pesq-experiencia_intrucoes-preenchimento"
            />
            <div className="instruction-required">*Obrigatório</div>
        </>
    )
}

export {
    InstrucoesGeral,
    InstrucoesPesquisa
}