/*
** @name: Meu Clínicas - appExternalServiceInfoMessage
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render das mensagens de informação ao usuário (sucesso, error, etc ...)  
** para módulos de serviços externos (páginas não atreladas ao fluxo normal da aplicação)
*/

import React from 'react';


const AppExternalServiceInfoMessage = (props) => {
    const { id, className } = props;
    if(!props.children) {
        return null;
    }
    return(
        <div key={id} id={id} className={"information-card" + (className ? " " + className : "")}>
            <div>{props.children}</div>
        </div>
    );
}

export default AppExternalServiceInfoMessage;