import React from 'react';

const EmailUsuario = (props) => {
    const { label, className, value } = props || {};
    if(!value) {
        return null;
    }
    return(
        <div className={className}>
            { label &&
            <div className="field-label">{label}</div>
            }
            <div className="field-content">{value}</div>
        </div>
    );
}

export default EmailUsuario;