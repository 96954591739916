/*
** @name: Meu Clínicas - geradorTokenIntegracaoLogin
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Módulo para gerar tokens de integracao para login
** 
*/

import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form, Input } from 'semantic-ui-react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import integrationToken from '../../core/integrationToken.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { DropdownField, InputField, TextareaField } from '../../components/fields/formsBuilderCustoms/';


// Import module styles
import './geradorTokenIntegracaoLogin.scss';

// Import form configuration
import formConfig from './geradorTokenIntegracaoLogin.json';


const GeradorTokenIntegracaoLogin = (props) => {
    return(
        <GeradorTokenIntegracaoLoginImplem
            {...props}
        />
    )
}

class GeradorTokenIntegracaoLoginImplem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: null,
            erroGeracao: null, 
            generatedToken: null,
            copiedToClipboard: null
        }
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(genesysUtils.typeCheck.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _configureModuleList = () => {
        const options = Object.keys(APP_SERVICE_LIST)
            .sort((a, b) => a < b ? -1 : (a > b ? 1 : 0))
            .map((key, indx) => {
                return { "key": `modulo_${indx}`, "value": APP_SERVICE_LIST[key], "text": key };
            }, []);
        options.unshift({ "key": `empty`, "value": "", "text": "-" })
        setConfigFieldProperty(formConfig, ["modulo"], "options", options);
    }

    _isButtonEnabled = () => {
        return this.state.fields && this.state.fields.modulo && this.state.fields.modulo.value;
    }

    _generateToken = () => {
        const { modulo, parametro, codigoPaciente } = this.state.fields || {};
        if(!modulo || !modulo.value) {
            return;
        }
        this._clearFieldsError();
        this._setErroRegistro(null);

        let param = "";
        if(parametro.value && String(parametro.value).trim()) {
            try {
                param = JSON.parse(parametro.value);
                if(!genesysUtils.typeCheck.isObject(param)) {
                    throw new Error("parâmetro deve ser um objeto e não um array ou string.")
                }
            } catch(e) { // Invalid field 'parametro' (Invalid JSON object)
                this._setFieldError("parametro", "Parâmetro, se informado, deve ser um objeto (não um array) JSON válido.");
                this._setErroRegistro("Verifique o correto preenchimento do formulário");
                return;
            }
        }
        const pacCodigo = codigoPaciente && codigoPaciente.value ? codigoPaciente.value : null;
        const token = integrationToken.generate(modulo.value, param, pacCodigo);
        this._setGeneratedToken(token);
    }

    _handleCopyToClipboard = (value) => {
        genesysUtils.general.asyncCopyTextToClipboard(value)
            .then(() => this.setState({ copiedToClipboard: true }))
            .catch(() => this.setState({ copiedToClipboard: false }));
    }

    _handleFormUpdate = (fields, field) => {
        this._setFieldError(field, null);
        this._setErroRegistro(null);
        this._setGeneratedToken(null);
        this.setState({ fields: fields });
    }

    _setErroRegistro = (msg) => {
        this.setState({ erroGeracao: msg });
    }

    _setFieldError = (field, message) => {
        if(!field) {
            return;
        }

        const updateFields = this.state.fields;
        if(!updateFields[field]) {
            updateFields[field] = {
                value: null,
                errorMessage: null
            }
        }
        updateFields[field].errorMessage = message;
        this.setState({ fields: updateFields });
    }

    _setGeneratedToken = (token) => {
        this.setState({
            generatedToken: token,
            copiedToClipboard: null
        });
    }
    
    componentDidMount() {
        this._configureModuleList();
    }

    render() {
        const { generatedToken, copiedToClipboard } = this.state;

        return(
            <div className="gerador-token-integracao-wrapper">
                <AppExtraDocumentHead subTitle="Área Administrativa" robots="noindex,nofollow" />

                <div className="content-wrapper">
                    <div className="content-box">
                        <div className="header-card">
                            <AppExternalServiceHeader linkToHome={false}>
                                <h1>Gerador de Token de Integração</h1>
                            </AppExternalServiceHeader>
                        </div>
    
                        <Form name="formMain">
                            <div className="primary-card">
                                <div className="section-instruction">
                                    <div className="instruction-required">*Obrigatório</div>
                                </div>
        
                                <div className="form-wrapper">
                                    <FormBuilder 
                                        config={formConfig}
                                        fields={this.state.fields}
                                        page={0}
                                        className="form-generator" 
                                        onChange={this._handleFormUpdate}
                                        overrideFieldRender={{
                                            'input': InputField,
                                            'dropdown': DropdownField,
                                            'textarea': TextareaField
                                        }}
                                    />
                                </div>
                            </div>

                            { generatedToken &&
                            <div className="section-token">
                                <div className="token-message">Token de integração gerado com sucesso:</div>
                                <div className="token-content">
                                    <div className="copyable-field">
                                        <Input
                                            fluid
                                            id="token-id"
                                            name="token"
                                            className=""
                                            autoComplete="off"
                                            value={generatedToken}
                                        />
                                    </div>

                                    <div className="action-copy">
                                        <div>
                                            <button 
                                                className="app-compact-button"
                                                disabled={copiedToClipboard}
                                                onClick={() => this._handleCopyToClipboard(generatedToken)}>COPIAR</button>
                                        </div>
                                    </div>
                                </div>

                                { (copiedToClipboard !== null) &&
                                <div className={`copy-message ${copiedToClipboard ? "" : " error"}`}>
                                    {copiedToClipboard ? "Token copiado para área de transferência com sucesso" : "Sem permissão para copiar para área de transferência."}
                                </div>
                                }
                            </div>

                            }

                            { this.state.erroGeracao &&
                            <AppMessageBox
                                id="msg-error"
                                className="error"
                                messageData={{ "message": this.state.erroGeracao }} />
                            }

                            <div className="action-section">
                                <button type="default" className="btn-generate" disabled={!this._isButtonEnabled()} onClick={() => this._generateToken()}>Gerar</button>
                            </div>

                        </Form>

                    </div>
                </div>
    
            </div>
        );
    }
}

export default GeradorTokenIntegracaoLogin;