/*
** @name: Meu Clínicas - agendamentoConsultas
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Abril 2021
** @description: Módulo para paciente realizar o agendamento de consultas
*/
/*
    Modulos extras:
        SOLICITACAO_AGENDAMENTO_CONSULTA: opcional
*/

import React, { Component } from 'react';
import SimpleMonthPicker from 'react-dj-simple-month-picker';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import specialAccessManager, { PERMISSIONS } from '../../core/specialAccessManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { DISABLE_LOADING } from '../../core/appRequest.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { hideService } from '../../components/general/appNavigationControls/appNavigationControls.js';

import ConfirmacaoDados from './confirmacaoDados.js';
import SelecaoEspecialidade from './selecaoEspecialidade.js';
import SelecaoHorario from './selecaoHorario.js';
import ResultadoAgendamento from './resultadoAgendamento.js';

import consultasClient from '../../apiClients/consultas/consultasClient.js';
import loginClient from '../../apiClients/login/loginClient.js';


// Import module styles
import './scss/agendamentoConsultas.scss';

const PAGE_SIZE = 5;
const MONTH_NAME = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO'];
const MONTH_SHORT = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];


const STEP_1 = "step-1";
const STEP_2 = "step-2";
const STEP_3 = "step-3";
const STEP_4 = "step-4";

const AgendamentoConsultas = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <AgendamentoConsultasImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class AgendamentoConsultasImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: STEP_1,
            backendErrorMessage: null,
            backendInfoMessage: null,
            especialidade: null,
            especialidadeList: [],
            currentMonth: null,
            selectedMonthIndex: null,
            monthList: [],
            currentPage: 1,
            pageCount: 0,
            selectedBooking: [],
            availability: [],
            reserva: null,
            reservaInicio: null,
            reservaDuracao: null,
            showMonthPicker: null,
            resultadoAgendamento: null,
            especialidadeSoliciarPorErroAgendamento: []
        }
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            const { currentStep } = this.state;
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(currentStep===STEP_2 || currentStep===STEP_3) {
                rnIntegration.backAction.push(() => { this._handleVoltar() });
            }
            if(currentStep===STEP_4) {
                rnIntegration.backAction.push(() => { this._handleReiniciar() });
            }
        }
    }

    _buildListaEspecialidaesResponseInfoMessage = (response) => {
        let message = null;
        if(response.solicitacaoEmProcessamento) {
            message = {
                header: null,
                message: "Existe uma solicitação de agendamento em processamento, favor aguardar."
            }
        } else if(!response.especialidadesDisponiveis || response.especialidadesDisponiveis.length < 1) {
            message = {
                header: null,
                message: (utils.isUserInSyncAwayTime() ? <AppCustomMessage messageId="_general_mensagem-possivel-sincronismo-pendente" /> : "Nenhuma agenda em acompanhamento")
            }
        }
        return message;
    }

    _calculateBookinIndex = (bookKey) => {
        const { availability } = this.state;
        if(bookKey && availability) {
            for(let i=0; i<availability.length; i++) {
                const schedules = availability[i].schedules;
                for(let j=0; j<schedules.length; j++) {
                    if(bookKey===schedules[j].key) {
                        return i;
                    }
                }
            }            
        }
        return null;
    }

    _calculateMonthIndex = ({ year, month }) => {
        const { availability } = this.state;
        if(year && month && availability) {
            for(let i=0; i<availability.length; i++) {
                if(month===availability[i].month && year===availability[i].year) {
                    return i;
                }
            }            
        }
        return null;
    }

    _calculateCurrPage = (index) => {
        if(genesysUtils.typeCheck.isInteger(index)) {
            return Math.floor(index/PAGE_SIZE) + 1;
        }
        return this.state.currentPage;
    }

    _getAvailability = () => {
        const { especialidade } = this.state;
        const registroEspecialidade = this._getRegitroEspecialidade(especialidade);
        if(!registroEspecialidade || !registroEspecialidade.agendaDisponivel) {
            return;
        }

        this.setState({ 
            availability: [],
            monthList: [],
            backendErrorMessage: null,
            backendInfoMessage: null
        });
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        consultasClient.buscarListaConsultasDisponiveisAgendamento(pacCodigo, especialidade, {}, {})
            .then(res => {
                const totalRecords = res.data.availability.length;
                if(totalRecords>0) {
                    const countPages = Math.ceil(totalRecords/PAGE_SIZE);
                    this.setState({
                        currentStep: STEP_2,
                        currentPage: 1,
                        pageCount: countPages,
                        availability: this._prepareAvailability(res.data.availability),
                        monthList: res.data.monthList
                    });
                } else {
                    this.setState({
                        backendInfoMessage: {
                            header: "Atenção!",
                            message: "Infelizmente essa especialidade não possui mais agenda disponível."
                        }
                    });
                    this._getListEspecialidade();
                }
            })
            .catch(err => {
                this.setState({
                    currentStep: STEP_2,
                    backendErrorMessage: {
                        header: "Ops!",
                        message: "Ocorreu um erro consultando disponibilidade de consultas."
                    }
                });
            });
    }

    _getListEspecialidade = () => {
        this.setState({ 
            backendErrorMessage: null
        });

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        consultasClient.buscarListaEspecialidadeAgendamento(pacCodigo, {}, {})
            .then(res => {
                const { especialidade } = this.state;
                const response = res.data;
                const listaEspecialidades = response.especialidadesDisponiveis;
                const registroEspecialidade = this._getRegitroEspecialidade(especialidade, listaEspecialidades);
                const backendInfoMessage = this._buildListaEspecialidaesResponseInfoMessage(response);
                this.setState({
                    especialidade: especialidade && registroEspecialidade ? especialidade : null,
                    especialidadeList: listaEspecialidades,
                    backendInfoMessage: backendInfoMessage
                })
            })
            .catch(err => {
                this.setState({
                    especialidadeList: [],
                    backendErrorMessage: {
                        header: "Ops!",
                        message: "Ocorreu um erro consultando lista de especialidades"
                    }
                });
            });

        // Atualizar permissoes especiais de forma assíncrona
        this._updateSpecialPermissions();
    }

    _getRegitroEspecialidade = (espSeq, listEspecialidade) => {
        if(espSeq) {
            const especialidadeList = genesysUtils.typeCheck.isArray(listEspecialidade) ? listEspecialidade : this.state.especialidadeList;
            const needle = `${espSeq}`;
            return especialidadeList.find(esp => needle===`${esp.seq}`) || null;
        }
        return null;
    }

    _handleBookingHourClick = ({dayKey, hourKey}) => {
        const selectedBooking = genesysUtils.array.inArray(hourKey, this.state.selectedBooking) ? [] : [hourKey];
        this.setState({ selectedBooking });
    }

    _handleChangeEspecialidade = (value) => {
        if(isNaN(value)) {
            return;
        }
        this.setState({
            backendInfoMessage: null,
            especialidade: parseInt(value),
            selectedBooking: [],
            availability: []
        });
    }

    _handleConfirmar = () => {
        this.setState({
            backendErrorMessage: null
        });

        const user = this.props.authContext.properties.user;
        const reserva = this.state.reserva || {};
        const requestData = {
            pacCodigo: user.pacCodigo,
            numeroConsulta: reserva.numeroConsulta,
            idReserva: reserva.idReserva,
            notificacaoTelefone: user.celular,
            notificacaoEmail: user.email,
            atualizarInformacoesContato: false
        }
        consultasClient.marcarConsulta(requestData, {}, {})
            .then(res => {
                if(!genesysUtils.typeCheck.isObject(res.data)) {
                    throw new Error("Invalid response data.");
                }
                const updListaPermissao = this.state.especialidadeSoliciarPorErroAgendamento;
                const beErrMessage = res.data.sucesso ? null : {
                    header: null,
                    message: res.data.mensagemErro
                }
                if(!res.data.sucesso && res.data.permiteSolicitacao) {
                    const { especialidade } = this.state;
                    if(!updListaPermissao.includes(especialidade)) {
                        updListaPermissao.push(especialidade);
                    }
                }
                this.setState({
                    currentStep: STEP_4,
                    reservaInicio: null,
                    reservaDuracao: null,
                    resultadoAgendamento: res.data,
                    backendErrorMessage: beErrMessage,
                    especialidadeSoliciarPorErroAgendamento: updListaPermissao
                });
            })
            .catch(err => {
                this.setState({
                    currentStep: STEP_4,
                    reservaInicio: null,
                    reservaDuracao: null,
                    backendErrorMessage: {
                        header: "Ops!",
                        message: "Ocorreu um erro inexperado realizando a marcação da consulta."
                    }
                });
            });
    }

    _handleMonthPicker = () => {
        this.setState({
            showMonthPicker: true
        });
    }

    _handleMonthPickerDismiss = () => {
        this.setState({
            showMonthPicker: false
        });
    }

    _handleMonthPickerSelect = ({ year, month }) => {
        const monthIndex = this._calculateMonthIndex({ year, month });
        const newMonthIndex = monthIndex!==null ? monthIndex : this.state.selectedMonthIndex
        const newMonth = monthIndex!==null ? { year, month } : this.state.currentMonth;
        const newCurrentPage = monthIndex!==null ? this._calculateCurrPage(monthIndex) : this.state.currentPage;
        this.setState({ 
            currentMonth: newMonth,
            selectedMonthIndex: newMonthIndex,
            currentPage : newCurrentPage,
            showMonthPicker: false
        });
    }

    _handlePagination = (direction) => {
        const newPage = Math.max(1, Math.min(this.state.pageCount, this.state.currentPage+direction));
        this.setState({ 
            currentMonth: null,
            selectedMonthIndex: null,
            currentPage: newPage 
        });
    }

    _handleProsseguir = () => {
        const { currentStep } = this.state;

        if(currentStep===STEP_1) {
            this._getAvailability();
        } else if(currentStep===STEP_2) {
            this._solicitarReserva();
        }
    }

    _handleReiniciar = (keepEspecialidade) => {
        this.setState({
            currentStep: STEP_1,
            backendErrorMessage: null,
            especialidade: keepEspecialidade ? this.state.especialidade : null,
            selectedBooking: [],
            resultadoAgendamento: null
        });
        this._getListEspecialidade();
    }

    _handleSolicitarAgendamento = () => {
        const espSeq = this.state.especialidade;
        const especialidade = this._getRegitroEspecialidade(espSeq);
        const { permiteSoliciarPorErro, solicitacaoImpedida } = this._obterPermissaoSolicitacaoAgendamento(especialidade);
        if(especialidade && !solicitacaoImpedida) {
            const parameters = {
                especialidade: {
                    espSeq: especialidade.seq,
                    nomeEspecialidade: especialidade.nomeEspecialidade
                },
                solicitacaoErroAgendamento: permiteSoliciarPorErro
            }
            this.props.appControllerContext.methods.doAddCardModule(APP_SERVICE_LIST.SOLICITACAO_AGENDAMENTO_CONSULTA, parameters, false);
        }
    }

    _handleVoltar = () => {
        const { currentStep } = this.state;

        if(currentStep===STEP_3) {
            this.setState({
                currentStep: STEP_2,
                backendErrorMessage: null,
                reserva: null,
                reservaInicio: null,
                reservaDuracao: null
            });

            this._resetGridPositionBySelection();
        } else if(currentStep===STEP_2) {
            this._handleReiniciar(true);
        }
    }

    _isConfirmarEnabled = () => {
        const { reservaDuracao } = this.state;
        return reservaDuracao ? true : false;
    }

    _isProsseguirEnabled = () => {
        const { currentStep, especialidade, selectedBooking } = this.state;
        if(currentStep===STEP_1) {
            const registroEspecialidade = this._getRegitroEspecialidade(especialidade);
            const { permiteSoliciarPorErro } = this._obterPermissaoSolicitacaoAgendamento(registroEspecialidade);
            return registroEspecialidade && registroEspecialidade.agendaDisponivel && !permiteSoliciarPorErro ? true : false;
        }
        if(currentStep===STEP_2) {
            return selectedBooking && selectedBooking.length ? true : false;
        }

        return false;
    }

    _obterPermissaoSolicitacaoAgendamento = (regEspecialidade) => {
        if(!genesysUtils.typeCheck.isObject(regEspecialidade)) {
            return {};
        }

        const { authContext, appContextConfig } = this.props;
        const user = authContext.properties.user;
        const espSeq = regEspecialidade.seq;
        const pData = specialAccessManager.permissions.getDataFromUser(user, PERMISSIONS.SOLIC_AGENDAMENTO_CONSULTA);
        const beDisallowed = false===specialAccessManager.permissions.isAllowed(user, PERMISSIONS.SOLIC_AGENDAMENTO_CONSULTA);
        const possivelSolicitar = !beDisallowed && (!pData || !pData.solicitacoes || !pData.solicitacoes[espSeq]);

        const isHiddenSolicAgend = hideService(user, appContextConfig, APP_SERVICE_LIST.SOLICITACAO_AGENDAMENTO_CONSULTA);
        const permiteSoliciarPorErro = this.state.especialidadeSoliciarPorErroAgendamento.includes(espSeq);
        const solicitacaoImpedida = !possivelSolicitar || regEspecialidade.possuiAgendamentoMesmaGenerica
                || regEspecialidade.possuiSolicitacao || regEspecialidade.possuiSolicitacaoMesmaGenerica;
        const showLinkSolicAgendamento = (espSeq && regEspecialidade.nomeEspecialidade)
                && !isHiddenSolicAgend && !solicitacaoImpedida && possivelSolicitar 
                && (permiteSoliciarPorErro || regEspecialidade.solicitacaoEnabled) ? true : false;
        return { isHiddenSolicAgend, permiteSoliciarPorErro, solicitacaoImpedida, showLinkSolicAgendamento };
    }

    _prepareAvailability = (availability) => {
        // Adicionar estilo para registros de teleatendimento
        availability.forEach(av => {
            av.schedules.forEach(sch => {
                if(sch.teleatendimento) {
                    sch.className = "teleatendimento";
                }
            });
        });

        return availability;
    }

    _removeFromAvailability = (listConsultas) => {
        try {
            const { availability } = this.state;
            for(let i=0; i< availability.length; i++) {
                const index = availability[i].schedules.findIndex(sch => sch.key===listConsultas);
                if(index>=0) {
                    availability[i].schedules.splice(index, 1); // Remove hour schedule
                    if(!availability[i].schedules.length) {
                        availability.splice(i, 1); // Remove day availability
                    }               
                    break;
                }
            }
            this.setState({
                availability
            });
        } catch(e) {
            // ignore error
        }
    }

    _resetGridPositionBySelection = () => {
        const { selectedBooking } = this.state;
        const selectedBookingIndex = this._calculateBookinIndex(selectedBooking.length ? selectedBooking[0] : null);
        if(selectedBookingIndex!==null) {
            this.setState({ 
                currentMonth: null,
                selectedMonthIndex: null,
                currentPage : this._calculateCurrPage(selectedBookingIndex)
            });
        }
    }

    _solicitarReserva = () => {
        const { selectedBooking } = this.state;
        if(!selectedBooking || selectedBooking.length<1) {
            return;
        }

        this.setState({ 
            reserva: null,
            reservaInicio: null,
            reservaDuracao: null,
            backendErrorMessage: null
        });

        const listNumeroConsulta = selectedBooking[0];
        const requestData = {
            pacCodigo: this.props.authContext.properties.user.pacCodigo,
            listNumeroConsulta: listNumeroConsulta
        }
        consultasClient.obterReservaConsulta(requestData, {}, {})
            .then(res => {
                if(res.data.idReserva) {
                    this.setState({
                        currentStep: STEP_3,
                        reserva: res.data,
                        reservaInicio: new Date().getTime(),
                        reservaDuracao: res.data.validadeMs ? (res.data.validadeMs / 1000) : null
                    });
                } else {
                    this.setState({
                        selectedBooking: [],
                        backendErrorMessage: {
                            header: "Atenção",
                            message: "Infelizmente esse horário acabou de ser agendado e não está mais disponível. Favor escolher outro horário."
                        }
                    });
                    this._removeFromAvailability(listNumeroConsulta);
                }
            })
            .catch(err => {
                this.setState({
                    backendErrorMessage: {
                        header: "Ops!",
                        message: "Ocorreu um erro solicitando a reserva"
                    }
                });
            });
    }

    _updateCounterValidade = () => {
        const { reserva, reservaInicio } = this.state;
        if(!reserva || !reservaInicio) {
            return;
        }
        const now = new Date().getTime();
        const duracao = Math.max(0, Math.floor((reserva.validadeMs - (now - reservaInicio)) / 1000));
        this.setState({
            reservaDuracao: duracao
        });
    }

    _updateSpecialPermissions = () => {
        // Atualizar permissões especiais do usuário (assíncrono)
        const { authContext } = this.props;
        const user = authContext.properties.user;
        if(user.pacCodigo) { // Usuário já ativado (link e-mail) e possui pacCodigo
            loginClient.asyncUserPermissions(user.pacCodigo, {}, DISABLE_LOADING)
                .then(res => {
                    // Check desired permission
                    if(!res.data["formularios-service"]) {
                        throw new Error("Missing 'formularios-service' data.");
                    }
                    // Update user in session
                    specialAccessManager.permissions.setData(authContext, res.data);
                })
                .catch(err => { // Ignorar erro => fica vazia as permissões especiais
                    console.error('Erro obtendo permissões do paciente: ' + err.message);
                });
        }
    }

    componentDidMount() {
        this._getListEspecialidade();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.currentStep!==prevState.currentStep) {
            this._appSetBackAction();
            utils.scrollAppCardModuleContentTo(0);
        }

        if(this.state.currentStep===STEP_3 && this.state.reservaDuracao) {
            this.counterTimeoutId = setTimeout(this._updateCounterValidade.bind(this), 1000);
        } else {
            clearTimeout(this.counterTimeoutId);
            this.counterTimeoutId = null;
        }
    }

    render() {
        const { currentStep, showMonthPicker, backendErrorMessage, backendInfoMessage } = this.state;
        const languageConfig = {
            months: { 
                name: MONTH_NAME,
                shortName: MONTH_SHORT
            }
        };
        const period = {
            monthList: this.state.monthList,
        };

        return(
            <AppCardModuleBasicWrapper wrapperName="agendamento-consultas">

                { showMonthPicker &&
                <SimpleMonthPicker
                    value={this.state.currentMonth}
                    visible={true}
                    dismissOnEsc={true}
                    onlyPickerBox={false}
                    theme="dark"
                    language={languageConfig}
                    monthMask="MM/Y"
                    period={period}
                    onDismiss={this._handleMonthPickerDismiss}
                    onSelect={this._handleMonthPickerSelect}
                /> 
                }

                <div className="gauge-bar">
                    <div className={`gauge-inticator ${this.state.currentStep}`}></div>
                </div>

                { (!backendErrorMessage && currentStep===STEP_1) &&
                <SelecaoEspecialidade
                    registroEspecialidade={this._getRegitroEspecialidade(this.state.especialidade)}
                    especialidadeList={this.state.especialidadeList}
                    onObterPermissaoSolicitacao={this._obterPermissaoSolicitacaoAgendamento}
                    onChangeEspecialidade={this._handleChangeEspecialidade}
                    onSolicitarAgendameto={this._handleSolicitarAgendamento}
                />
                }
                { currentStep===STEP_2 &&
                <SelecaoHorario
                    pageSize={PAGE_SIZE}
                    pageCount={this.state.pageCount}
                    currentPage={this.state.currentPage}
                    currentMonth={this.state.currentMonth}
                    registroEspecialidade={this._getRegitroEspecialidade(this.state.especialidade)}
                    selectedBooking={this.state.selectedBooking}
                    selectedMonthIndex={this.state.selectedMonthIndex}
                    availability={this.state.availability}
                    monthNames={MONTH_NAME}
                    onMonthPicker={this._handleMonthPicker}
                    onScheduleSelect={this._handleBookingHourClick}
                    onSchedulePagination={this._handlePagination}
                />
                }
                { currentStep===STEP_3 && 
                <ConfirmacaoDados 
                    reserva={this.state.reserva}
                    reservaDuracao={this.state.reservaDuracao} />
                }
                { currentStep===STEP_4 &&
                <ResultadoAgendamento 
                    resultado={this.state.resultadoAgendamento} />
                }

                { backendInfoMessage &&
                <AppMessageBox 
                    id="msg-agendamento-information" 
                    className="information"
                    messageData={backendInfoMessage} />
                }

                { backendErrorMessage &&
                <AppMessageBox 
                    id="msg-agendamento-error" 
                    className="error"
                    messageData={backendErrorMessage} />
                }

                <div className="main-action">
                    { currentStep===STEP_3 &&
                    <button 
                        id="button-agendamento-confirmar"
                        type="button"
                        className="app-compact-button"
                        disabled={!this._isConfirmarEnabled()}
                        onClick={() => this._handleConfirmar()}>
                        CONFIRMAR
                    </button>
                    }

                    { (currentStep===STEP_1 || currentStep===STEP_2) &&
                    <button 
                        id="button-agendamento-prosseguir"
                        type="button"
                        className="app-compact-button"
                        disabled={!this._isProsseguirEnabled()}
                        onClick={() => this._handleProsseguir()}>
                        PROSSEGUIR
                    </button>
                    }

                    { (currentStep===STEP_2 || currentStep===STEP_3) &&
                    <button 
                        id="button-agendamento-voltar"
                        type="button"
                        className="app-compact-button"
                        onClick={() => this._handleVoltar()}>
                        VOLTAR
                    </button>
                    }

                    { currentStep===STEP_4 &&
                    <button 
                        id="button-agendamento-ok"
                        type="button"
                        className="app-compact-button"
                        onClick={() => this._handleReiniciar()}>
                        OK
                    </button>
                    }
                </div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default AgendamentoConsultas;
