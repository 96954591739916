/*
** @name: Meu Clínicas - mensagens
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Módulo para renderizar blocos de mensagens
*/
import React from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const ActionButtonFormSolic = (props) => {
    const { text, onClick, id } = props;
    return (
        <>
            { (genesysUtils.typeCheck.isFunction(onClick) && text) &&
            <div className="main-action">
                <button id={id} onClick={onClick} className="app-form-button">{text}</button>
            </div> 
            }
        </>
    )
}

const MensagemErroInicializacaoFormSolic = (props) => {
    const { onClick } = props;
    return (
        <>
            <div className="information-section">
                <div className="section-box">
                    <div className="section-content">
                        <AppMessageBox id="sacMsgErrorId" className="error" messageData={{ message: <AppCustomMessage elemType="fragment" messageId="_general_modulo-indisponivel" /> }} />
                    </div>
                </div>
            </div>

            <ActionButtonFormSolic id="sacBtnErrorCloseId" onClick={onClick} text="OK" />
        </>
    )
}

const MensagemSucessoSolic = (props) => {
    const { onClick } = props;
    return(
        <>
            <div className="information-section">
                <div className="section-box">
                    <AppCustomMessage id="sacMsgSucessoId" elemType="html" messageId="solicitacao-agendamento-consulta_mensagem-sucesso" className="section-content" />
                </div>
            </div>

            <ActionButtonFormSolic id="sacBtnSucessoCloseId" onClick={onClick} text="OK" />
        </>
    );
}

export {
    ActionButtonFormSolic,
    MensagemErroInicializacaoFormSolic,
    MensagemSucessoSolic
}