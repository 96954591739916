/*
** @name: Meu Clínicas - cardConsulta
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2022
** @description: Refatoramento dos cards para componente separado
*/

import React from 'react';
import 'moment-timezone';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { getAppMobileSettings, getAppServiceSettingsByName, getImageThemeContext } from '../../core/appSpecificConfigHandler.js';
import { useAppConfigContext, getContextConfigEntryByPath, AppCustomImage } from '@hcpa-react-components/app-customization';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { hideService } from '../../components/general/appNavigationControls/appNavigationControls.js';

const _isAddToCalendarAllowed = (appCfg, consulta) => {
    const rnIntegration = window.rnIntegration;
    const mobileCfg = getAppMobileSettings(appCfg);
    const isAppRunning = rnIntegration && rnIntegration.isAppRunning();
    const appVersionInfo = isAppRunning && rnIntegration.getAppVersionInfo();
    const isAndroidIntegrationAvailable = isAppRunning && appVersionInfo && 
            utils.checkAppVersion(appVersionInfo.version, mobileCfg.androidCalendarIntegrationVersion);
    const isIOSIntegrationAvailable = isAppRunning && appVersionInfo && 
            utils.checkAppVersion(appVersionInfo.version, mobileCfg.iOSCalendarIntegrationVersion);

    return (!isAppRunning || (genesysUtils.deviceCheck.isAndroid() && isAndroidIntegrationAvailable) || (genesysUtils.deviceCheck.isIOS() && isIOSIntegrationAvailable)) &&
            consulta && genesysUtils.typeCheck.isFunction(window.ics) && consulta.dataHoraEvento;
}

const CardConsulta = (props) => {
    const { baseKey, consultas, disableTeleatendimentoButton, hideAddToCalendarButton, hideTicketButton, 
            hideTeleatendimentoButton, userAllowedTeleatendimento, onAddToCalendar, onShowTicketPdf, onOpenTeleatendimento } = props;
    const authContext = useAuthContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    const serviceConfig = getAppServiceSettingsByName(appContextConfig, APP_SERVICE_LIST.CONSULTAS);
    const { maxTimeEnableTeleatendimentoMs } = serviceConfig || {};
    const user = authContext.properties.user;
    const hideTeleatendimento = hideService(user, appContextConfig, APP_SERVICE_LIST.TELEATENDIMENTO);
    
    return(
        consultas.map((consulta, index) => {
            const isTeleatendimento = consulta.atendimentoRemoto;
            const teleatendimentoLiberavel = consulta.podeLiberarTeleatendimento;
            const estornada = consulta.dthrEstorno ? true : false;
            const situacaoConsultas = estornada ? "CANCELADA" : consulta.situacao;
            const exibirAddToCalendar = !estornada && !hideAddToCalendarButton && _isAddToCalendarAllowed(appContextConfig, consulta);
            const exibirDownloadTicket = !estornada && !hideTicketButton;
            const exibirTeleatendimento = !hideTeleatendimento && !estornada && !hideTeleatendimentoButton && teleatendimentoLiberavel && isTeleatendimento;
            const enableTeleatendimento = !disableTeleatendimentoButton && userAllowedTeleatendimento && (maxTimeEnableTeleatendimentoMs>(consulta.dthrConsulta-new Date().getTime()));
            return (
                <div 
                    key={`${baseKey}_${index}`} 
                    className={`consulta-card-wrapper${consulta.principal ? " principal" : ""}${estornada ? " estornada" : ""}`}>
                    <div className="consulta-situacao">
                        <span className="bold-text">SITUAÇÃO:</span> <span className="nome-situacao">{situacaoConsultas}</span>
                    </div>
                    <div className="consulta-body">
                        <div className="body-info">
                            <div className="col-left">
                                <div>
                                    <div className="info-image-wrapper">
                                        <AppCustomImage imageContextFn={getImageThemeContext} module="consultas" imageId="icon_calendar" />
                                    </div>
                                    <div className="info-text">
                                        {/*<Moment format="DD/MM/YYYY">{consulta.dthrConsulta}</Moment>*/}
                                        {consulta.dataConsulta}
                                    </div>
                                </div>
                                <div>
                                    <div className="info-image-wrapper">
                                        <AppCustomImage imageContextFn={getImageThemeContext} module="consultas" imageId="icon_hour" />
                                    </div>
                                    <div className="info-text">
                                        {/* <Moment format="HH:mm">{consulta.dthrConsulta}</Moment> */}
                                        {consulta.horaConsulta}
                                    </div>
                                </div>
                                { isTeleatendimento ?
                                <div>
                                    <div className="info-image-wrapper">
                                        <AppCustomImage imageContextFn={getImageThemeContext} module="consultas" imageId="icon_video" onErrorReturn="TELE" />
                                    </div>
                                    <div className="info-text highlight">{getContextConfigEntryByPath(serviceConfig, "card.teleatendimentoLabel")}</div>
                                </div>
                                :
                                <div>
                                    <div className="info-image-wrapper">
                                        <AppCustomImage imageContextFn={getImageThemeContext} module="consultas" imageId="icon_location" />
                                    </div>
                                    <div className="info-text">
                                        <div className="zona">
                                            <div className="caption">Zona:</div>
                                            <div className="text">{consulta.unidSigla}</div>
                                        </div>
                                        <div className="sala">
                                            <div className="caption">Sala:</div>
                                            <div className="text">{consulta.unidSala}</div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="col-right">
                                <div><span className="bold-text">CONSULTA Nº:</span> {consulta.numero}</div>
                                <div><span className="bold-text">EQUIPE:</span> {consulta.equipe}</div>
                                <div><span className="bold-text">ESPECIALIDADE:</span> {consulta.nomeReduzidoEsp}</div>
                            </div>
                        </div>

                        { (exibirAddToCalendar || exibirDownloadTicket || exibirTeleatendimento) &&
                        <div className="body-action">
                            { exibirDownloadTicket &&
                            <>
                                <button 
                                    type="button"
                                    className="app-compact-button action-button"
                                    title="Baixar PDF do comprovante da consulta"
                                    onClick={() => onShowTicketPdf(consulta)}>Comprovante de Consulta</button>

                                    { consulta.downloadError &&
                                    <div className="download-error">{consulta.downloadError}</div>
                                    }
                            </>
                            }

                            { exibirAddToCalendar &&
                            <button 
                                type="button"
                                className="app-compact-button action-button"
                                onClick={() => onAddToCalendar(consulta)}>Adicionar ao Calendário</button>
                            }

                            { exibirTeleatendimento &&
                            <button 
                                type="button"
                                className="app-compact-button action-button"
                                disabled={!enableTeleatendimento}
                                onClick={() => onOpenTeleatendimento(consulta)}>{getContextConfigEntryByPath(serviceConfig, "card.teleatendimentoButtonLabel")}</button>
                            }
                        </div>
                        }

                    </div>
                </div>
            )
        })
    );
}

export default CardConsulta;