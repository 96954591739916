/*
** @name: Meu Clínicas - appInitializing
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2023
** @description: Componente para renderizar indicativo de inicialização do App
*/

import React from 'react';


// Import module styles
import './appInitializing.scss'; 


const AppInitializing = (props) => {
    return(
        <div id="initializingWrapperId">
            {props.children}
        </div>
    )
}

export default AppInitializing;