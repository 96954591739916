/*
** @name: Meu Clínicas - faqContent
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: February 2022
** @description: Módulo para exibicao de uma lita do FAQ
*/

import React, { Fragment, useEffect, useState } from 'react';
import { Accordion } from 'semantic-ui-react';

import { EMPTY_HREF } from '../../core/utils.js';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';
import ImageCarousel from '../../components/general/imageCarousel/imageCarousel.js';


// Import module styles
import './faqContent.scss'; 


const FaqContent = (props) => {
    const { listFaq, hideTitle, hideAnswer, hideTags, hideImages, disableAutoPlay, onAddTag } = props;
    const [content, setContent] = useState({ items: listFaq });
    const _handleAccordionClick = (e, itemIndex) => {
        const updItems = content.items;
        updItems[itemIndex].active = !updItems[itemIndex].active;
        setContent({ items: updItems });
    }

    useEffect(() => setContent({ items: listFaq }), [listFaq]);

    if(!content.items || content.items.constructor!==Array || content.items.length<1) {
        return null;
    }

    return(
        <div id="faqContentId">
            <Accordion fluid styled>
                { 
                    content.items.map((pergunta, indx) => {
                        if(!pergunta) {
                            return null;
                        }
                        return(
                            <div className={'accordion-item' + (pergunta.active ? ' active ':'')} key={'pergunta-' + indx}>
                                { !hideTitle &&
                                <Accordion.Title onClick={e => _handleAccordionClick(e, indx)}>
                                    <AppCustomMessage
                                        elemType="div"
                                        className="title-info-wrapper"
                                        messageTemplate={pergunta.titulo}
                                        missingParameter="{_PARAMETRO_NAO_SUPORTADO_}"
                                    />
                                    <div className="collapsible-wrapper">
                                        <AccordionCollapsible active={pergunta.active} iconVariant={null} />
                                    </div>
                                </Accordion.Title>
                                }

                                <Accordion.Content>
                                    { !hideAnswer &&
                                    <div className="pergunta" key={'resposta-' + indx}>
                                        <AppCustomMessage
                                            elemType="div"
                                            className="resposta"
                                            messageTemplate={pergunta.resposta}
                                            missingParameter="{_PARAMETRO_NAO_SUPORTADO_}"
                                        />
                                    </div>
                                    }

                                    { (!hideTags && pergunta.tags.length>0) &&
                                    <div className="tags-list">
                                        <label>Assuntos:</label>
                                        { " " }
                                        { 
                                            pergunta.tags.map((tag, indxTag) => {
                                                return(
                                                    <Fragment key={'tagListItem-' + indxTag}>
                                                        { (indxTag > 0) && " | " }
                                                        { onAddTag 
                                                        ? 
                                                        <a href={EMPTY_HREF} onClick={() => onAddTag(tag)} >
                                                            { tag }
                                                        </a>
                                                        :
                                                        tag
                                                        }
                                                    </Fragment>
                                                )
                                            }) 
                                        }
                                    </div>
                                    }

                                    { !hideImages &&
                                    <div className="image-carousel">
                                        <ImageCarousel
                                            disableAutoPlay={disableAutoPlay}
                                            keyBase={`c_${indx}`} 
                                            imageList={pergunta.imagens} />
                                    </div>
                                    }
                                </Accordion.Content>
                            </div>
                        )
                    })
                }
            </Accordion>
        </div>  
    );
}

export default FaqContent;