import moment from 'moment';
import 'moment-timezone';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';


const criarEventosConsultas = (configEvento, listConsultas) => {
    const { duracaoMinutos, resumo, descricao, local, localTeleatendimento } = configEvento || {};
    if(!genesysUtils.typeCheck.isArray(listConsultas) && !genesysUtils.typeCheck.isObject(listConsultas)) {
        return null;
    }
    
    listConsultas = genesysUtils.typeCheck.isArray(listConsultas) ? listConsultas : [listConsultas];

    let existsEvent = false;
    const calendar = genesysUtils.typeCheck.isFunction(window.ics) ? window.ics() : null;
    const calData = [];
    listConsultas.forEach(consulta => {
        if(genesysUtils.typeCheck.isObject(consulta)) {
            const inicioEvento = consulta.dataHoraEvento;
            const duracao = genesysUtils.typeCheck.isInteger(duracaoMinutos) ? duracaoMinutos : 60;
            const fimEvento = moment(inicioEvento).add(duracao, 'm').toDate();
            const especialidade = consulta.nomeReduzidoEsp;
            const condicaoAtendimento = consulta.caaDescricao;
            const isTeleAtendimento = consulta.atendimentoRemoto;
            const resumoEvento = resumo ? resumo.replace("{especialidade}", especialidade) : "Consulta";
            const descricaoEvento = !descricao ? "Consulta" :
                descricao.replace("{especialidade}", especialidade).replace("{condicaoAtendimento}", condicaoAtendimento);
            const localEvento = isTeleAtendimento ? localTeleatendimento : (local || "Hospital");

            if(calendar) {
                calendar.addEvent(resumoEvento, descricaoEvento, localEvento, inicioEvento, fimEvento);
            }
            calData.push({ resumoEvento, descricaoEvento, localEvento, inicioEvento, fimEvento });

            existsEvent = true;
        }
    });

    return existsEvent ? { calendar, calData } : null;
}

export {
    criarEventosConsultas
}