import React from 'react';

import Password from '../../components/fields/password/password.js';


const SenhaUsuario = (props) => {
    const { name, className, label, placeHolder, disableFloatingPlaceHolder, value, onChange } = props || {};
    return(
        <div className={className}>
            { disableFloatingPlaceHolder && label &&
            <div className="field-label">{label}</div>
            }
            <div className={`float-label-field${disableFloatingPlaceHolder ? " non-floating-placeholder" : ""}`}>
                <Password 
                    id={`${name}_Id`}
                    name={name}
                    disabled={false}
                    className=""
                    darkerInfo={true}
                    labelText={placeHolder}
                    strengthMeter={false}
                    value={value}
                    onChange={(val) => onChange(null, { name, value: val })}
                />
            </div>
        </div>
    );
}

export default SenhaUsuario;
