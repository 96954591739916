/*
** @name: Meu Clínicas - laudosAtestados
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br), Jardel Gugel (jgugel@hcpa.edu.br)
** @date: Maio 2024
** @description: Módulo para renderizar listagem de laudos e atestados
*/

import React, { Component } from 'react';

import { Accordion } from 'semantic-ui-react';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { AppCustomImage, AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { getAppServiceSettingsByName, getImageThemeContext } from '../../core/appSpecificConfigHandler.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';
import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppDateFilter from '../../components/general/appDateFilter/appDateFilter.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import LaudosAtestadosView from '../../components/general/laudosAtestadoView/laudosAtestadosView.js';
import ViewerModal from '../../components/general/viewerModal/viewerModal.js';

import CardLaudosAtestados from './cardLaudosAtestados.js';

import registrosMedicosClient from '../../apiClients/registrosMedicos/registrosMedicosClient.js';


// Import module styles
import "./laudosAtestados.scss";

const ESCAPE_KEY = 27;
const DEFAULT_ERRO_OBTENDDO_DOCUMENTO = "Erro obtento documento solicitado.";
const DEFAULT_ERRO_SOLICITACAO = "Erro obtento informações do laudos e atestados.";



const LaudosAtestados = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return (
        <LaudosAtestadosImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class LaudosAtestadosImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFiltered: false,
            filterError: null,
            searchResult: [],
            searchErrorMessage: null,
            searchInfoMessage: null,
            historicoActive: false,
            listaAgrupamento: [],
            mostraVisualizador: false,
            dadosVisualizador: null,
            listaLaudosLME: null,
            mostraLaudosLME: false,

        };
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(this._getDadosVisualizacao()) {
                rnIntegration.backAction.push(() => { this._handleCloseVisualizador() });
            }
        }
    }

    _getDadosVisualizacao = () => {
        const { mostraVisualizador, dadosVisualizador } = this.state;
        const exibeVisualizador = (mostraVisualizador && dadosVisualizador);
        return exibeVisualizador ? dadosVisualizador : null;
    }

    _getLaudosAtestados = (params) => {
        const filtro = (params && params.filtro) || {};
        const filterError = params && params.filterError;

        this.setState({ filterError });
        if (filterError) {
            return;
        }

        this.setState({
            searchErrorMessage: null,
            searchInfoMessage: null,
            historicoActive: false
        });

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        registrosMedicosClient.buscarListaLaudosAtestados(pacCodigo, filtro.dtInicial, filtro.dtFinal, {}, {})
            .then(res => {
                const isFiltered = (filtro.dtInicial || filtro.dtFinal) ? true : false;
                const searchInfoMessage = res.data.laudosAtestados.length > 0 ? null :
                    (!isFiltered && utils.isUserInSyncAwayTime() ? <AppCustomMessage messageId="_general_mensagem-possivel-sincronismo-pendente" /> : "Nenhum laudo e atestado encontrado");
                this.setState({
                    isFiltered: isFiltered,
                    searchResult: res.data.laudosAtestados,
                    searchInfoMessage: searchInfoMessage
                });
            })
            .catch(err => {
                this.setState({
                    searchResult: [],
                    searchErrorMessage: "Ocorreu um erro ao processar sua requisição"
                });
            });
    }

    _getSiglasExibicao = () => {
        const serviceConfig = getAppServiceSettingsByName(this.props.appContextConfig, APP_SERVICE_LIST.LAUDOS_ATESTADOS);
        return serviceConfig.siglasExibicao || [];
    }

    _handleClearFilter = () => {
        const { isFiltered } = this.state;
        this.setState({
            isFiltered: false,
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null,
        });

        if (isFiltered) {
            this._getLaudosAtestados();
        }
    }

    _handleCloseVisualizador = () => {
        this.setState({ 
            mostraVisualizador: false, 
            dadosVisualizador: null,
        });
    }

    _handleUpdateFilter = () => {
        this.setState({
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null,
        });
    }

    _getListaAgrupamento = () => {
        const agrupamento = [];
        this.state.searchResult.forEach((item, indx) => {
            const { conNumero, dataConsulta, nomeEquipe, nomeReduzidoEspecialidade } = item;
            const prevItem = agrupamento.filter(i => i.dadosConsulta.conNumero === item.conNumero);
            if(prevItem.length) {
                prevItem[0].listaItens.push(item);
            } else {
                agrupamento.push({
                    dadosConsulta: { conNumero, dataConsulta, nomeEquipe, nomeReduzidoEspecialidade },
                    listaItens: [ item ]
                });
            }
        });
        return agrupamento;
    }

    _handleAccordionClick = (e, item) => {
        item.active = !item.active;
        this.setState({});
    }

    _handleKeyDown = (event) => {
        if(this._getDadosVisualizacao()) {
            switch( event.keyCode ) {
                case ESCAPE_KEY:
                    event.stopPropagation();
                    event.preventDefault();
                    this._handleCloseVisualizador();
                    break;
                default: 
                    break;
            }
        }
    }

    _laudoAtestadoDownload = (item) => {
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        if(item.atestadoSigla === "O"){
            registrosMedicosClient.buscarLaudosAtestadosPDF(item.id, pacCodigo, {}, {})
            .then(res => {
                if(!res.data || !res.data.pdfBase64) {
                    this._updateResultadoDownloadError(item, "Retorno sem dados para consulta.");
                    return;
                }
                this._updateResultadoDownloadError(item, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const fileName = `${item.conNumero}_${item.atestadoSigla}.pdf`;
                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }

                item.visualizadoEm = new Date().getTime;
                this.setState({});
            })
            .catch(err => {
                this._updateResultadoDownloadError(item, DEFAULT_ERRO_OBTENDDO_DOCUMENTO);
            });
        } else if(item.atestadoSigla === "LME"){
            registrosMedicosClient.buscarLaudosLmePDF(item.id, pacCodigo, {}, {})
            .then(res => {
                if(!res.data || !res.data.pdfBase64) {
                    this._updateResultadoDownloadError(item, "Retorno sem dados para consulta.");
                    return;
                }
                this._updateResultadoDownloadError(item, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if (!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const fileName = `${item.conNumero}_${item.atestadoSigla}_${item.pacCodigo}.pdf`;
                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }
                item.visualizadoEm = new Date().getTime;
                this.setState({});
            })
            .catch(err => {
                this._updateResultadoDownloadError(item, DEFAULT_ERRO_OBTENDDO_DOCUMENTO);
            });
        } else {
            this._updateResultadoDownloadError(item, DEFAULT_ERRO_OBTENDDO_DOCUMENTO);
        }
    }

    _laudoAtestadoExibir = (item) => {
        if(item.atestadoSigla === "O") {
            item.visualizadoEm = new Date().getTime;
            this.setState({ 
                mostraVisualizador: true, 
                dadosVisualizador: item
            });

            const pacCodigo = this.props.authContext.properties.user.pacCodigo;
            registrosMedicosClient.indicarLeituraLaudosAtestados(item.id, pacCodigo, {}, {}).then(res => {}).catch(err => {}); 
        } else if(item.atestadoSigla === "LME") {
            this._getLaudoAtestadoLMEPNG(item);
        }
    }

    _updateLaudosAtestadosDownloadError = (item, message) => {
        item.downloadError = message;
        this.setState(this.state);
    }

    _getLaudoAtestadoLMEPNG = (item) => {
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        registrosMedicosClient.buscarLaudosLmePNG(item.id, pacCodigo, {}, {})
            .then(res => {
                if (!res.data || !res.data.listPngBase64 || isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length < 1) {
                    this._updateLaudosAtestadosDownloadError(item, "Retorno sem dados para laudos/atestados.");
                    this.setState({ mostraLaudosLME: false, listaLaudosLME: null });
                    return;
                }
                this._updateLaudosAtestadosDownloadError(item, null);
                item.visualizadoEm = new Date().getTime;
                this.setState({ mostraLaudosLME: true, listaLaudosLME: res.data.listPngBase64, item });
            })
            .catch(err => {
                this._updateLaudosAtestadosDownloadError(item, DEFAULT_ERRO_SOLICITACAO);
            });
    }

    RenderVisualizadorLME = () => {
        const { listaLaudosLME } = this.state;
        const { mostraLaudosLME } = this.state;
        if(listaLaudosLME && mostraLaudosLME) {
            const items = [];
            const totalPaginas = "(Total " + listaLaudosLME.length + " página" + (listaLaudosLME.length>1 ? "s" : "") + ")";
            const titulo = `Laudos Atestados LME ${totalPaginas}`;

            for(var page=0; page<listaLaudosLME.length; page++) {
                const strBase64 = listaLaudosLME[page];
                const imageData = `data:image/png;base64,${strBase64}`;
                items.push(
                    <div key={`page_${page}`}>
                        <img src={imageData} alt=""></img>
                    </div>
                );
                if(page<(listaLaudosLME.length-1)) {
                    items.push(
                        <div key={"space_"+page} className="result-pagespace"></div>
                    );
                }
                return (
                    <div className="laudos-atestados-viwer-wrapper">
                        <ViewerModal
                            title={<div className="titulo-modal">{titulo}</div>}
                            onCloseButton={this._handleCloseResultadoLME}>
                            {items}
                        </ViewerModal>
                    </div>
                );               
            }
            genesysUtils.general.setViewPortRescale(true);
        } else {
            genesysUtils.general.setViewPortRescale(false);
            return null;
        }
    }

    _handleCloseResultadoLME = () => {
        this.setState({
            mostraLaudosLME: false,
            listaLaudosLME: null
        });
    }

    _updateResultadoDownloadError = (item, message) => {
        item.downloadError = message;
        this.setState(this.state);
    }

    componentDidMount(){
        document.addEventListener("keydown", this._handleKeyDown, true);
        this._getLaudosAtestados();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mostraVisualizador, mostraSolicitacao, searchResult } = this.state;
        if((mostraVisualizador!==prevState.mostraVisualizador) || (mostraSolicitacao!==prevState.mostraSolicitacao)) {
            this._appSetBackAction();
        }
        if(searchResult !== prevState.searchResult) {
            this.setState({
                listaAgrupamento: this._getListaAgrupamento()
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown, true);
    }

    render() {
        const dadosAgrupamento = this.state.listaAgrupamento || [];
        const { mostraVisualizador } = this.state;
        const viwerTitle = "Visualizador de Laudos/Atestados";

        return (
            <AppCardModuleBasicWrapper wrapperName="laudos-atestados">

                { this.RenderVisualizadorLME() }
                
                { mostraVisualizador &&
                <div className='laudos-atestados-viwer-wrapper'>
                    <ViewerModal
                        title={<div className="titulo-modal">{viwerTitle}</div>}
                        onCloseButton={this._handleCloseVisualizador}>

                        <LaudosAtestadosView dados={this._getDadosVisualizacao()} />

                    </ViewerModal>
                </div>
                }

                <AppDateFilter
                    filterError={this.state.filterError}
                    onFilterClear={this._handleClearFilter}
                    onFilterUpdate={this._handleUpdateFilter}
                    onFilter={this._getLaudosAtestados}
                />

                { this.state.searchErrorMessage &&
                    <AppMessageBox
                        id="msg-laudos-atestados-error"
                        className="error"
                        messageData={{ message: this.state.searchErrorMessage }} />
                }

                { this.state.searchInfoMessage &&
                    <AppMessageBox
                        id="msg-laudos-atestados-information"
                        className="information"
                        messageData={{ message: this.state.searchInfoMessage }} />
                }

                <div className="laudos-atestados-section">

                    <Accordion fluid styled>
                        
                        { dadosAgrupamento.map((itemAgrupamento, index) => {
                            const itemLaudo = itemAgrupamento.dadosConsulta;
                            const siglas = this._getSiglasExibicao();
                            const exibirResultados = (itemAgrupamento.listaItens || []).filter(i => !siglas.length || siglas.includes(i.atestadoSigla)).length ? true : false;
                            const indicativoNaoLido = (itemAgrupamento.listaItens || []).filter(e => !e.visualizadoEm).length;
                            
                            if(!exibirResultados) {
                                return null;
                            }

                            return(
                                <div key={`itemLista_${index}`} className={`accordion-item${itemAgrupamento.active ? " active": ""}${indicativoNaoLido ? " emphasis" : ""}`}>
                                    
                                    <Accordion.Title onClick={exibirResultados ? (e) => this._handleAccordionClick(e, itemAgrupamento) : () => {}}>
                                        <div className="title-info-wrapper">
                                            <div className="information">
                                                
                                                <div className="laudos-atestados-body">
                                                    <div className="body-info">
                                                        <div className="col-left">
                                                            <div><span className="bold-text">CONSULTA Nº:</span>&nbsp;{itemLaudo.conNumero}</div>
                                                            <div className="info-data-consulta">
                                                                <div className="info-image-wrapper">
                                                                    <AppCustomImage imageContextFn={getImageThemeContext} module="laudosAtestados" imageId="icon_calendar" className={indicativoNaoLido ? " emphasis" : ""} />
                                                                </div>
                                                                <div className="info-text">
                                                                    {itemLaudo.dataConsulta}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-right">
                                                            <div><span className="bold-text">EQUIPE:</span> {itemLaudo.nomeEquipe}</div>
                                                            <div><span className="bold-text">ESPECIALIDADE:</span> {itemLaudo.nomeReduzidoEspecialidade}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        { exibirResultados &&
                                        <div className="collapsible-wrapper">
                                            <AccordionCollapsible active={itemAgrupamento.active} iconVariant={indicativoNaoLido ? "new" : null} />
                                        </div>
                                        }
                                    </Accordion.Title>

                                    <div className={`accordion-item${itemAgrupamento.active ? " active" : ""}`}>
                                        <Accordion.Content>
                                            { exibirResultados &&
                                            <CardLaudosAtestados
                                                listaItens={itemAgrupamento.listaItens}
                                                habilitaDownload={true}
                                                siglasExibirDownload={this._getSiglasExibicao()}
                                                onLaudoAtestadoShow={this._laudoAtestadoExibir}
                                                onLaudoAtestadoDownload={this._laudoAtestadoDownload}
                                            />
                                            }
                                        </Accordion.Content>
                                    </div>
                                </div>
                            );
                        }) }

                    </Accordion>
                    <div className="padding-rodape"></div>
                </div>
            </AppCardModuleBasicWrapper>
        );
    }
}

export default LaudosAtestados;