/*
** @name: Meu Clínicas - modalDocumentSaveSucess
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar modal de confirmação do salvamento dos arquivos do paciente
*/
import React from 'react';
import AppMessageDialog from '../../components/general/appMessageDialog/appMessageDialog.js';

const ModalDocumentSaveSucess = (props) => {
    const { onConfirm } = props;
    return(
        <AppMessageDialog
            wrapper={true}
            wrapperClassName="wrapper-confirmacao-upload"
            header={ 
                <div className="header-title">Sucesso</div>
            }
            className="confirmation-box"
            message="Arquivos atualizados com sucesso."
            messageClass="message"
            buttonClass="app-form-button"
            buttonOneText="Ok"
            buttonTwoText={null}
            buttonOneClick={onConfirm}
            buttonTwoClick={null}
        />
    );
}

export default ModalDocumentSaveSucess;