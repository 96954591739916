/*
** @name: Meu Clínicas - mensagemValidacao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Maio 2024
** @description: Módulo para renderizar mensagem de validação do laudo/atestado
*/

import React from "react";

import Moment from 'react-moment';


const formatAuthenticationKey = (key) => {
    if(!key) {
        return null;
    }

    while(key.length < 24) {
        key = "0" + key;
    }

    const chunks = key.match(/.{1,6}/g);
    const formated = chunks.join("-");

    return formated;
}

const MensagemValidacao = (props) => {
    const { chaveAutenticacao, emphasisValidacao, validationURL, validadaEm } = props;
    if(validadaEm && chaveAutenticacao) {
        const momentValidacao = <Moment format="DD/MM/YYYY HH:mm:ss" >{validadaEm}</Moment>
        return(
            <div className={emphasisValidacao ? "emphasis" : ""}>
                Laudo/Atestado validado em: {momentValidacao}<br />
                Autenticação: {formatAuthenticationKey(chaveAutenticacao)}
            </div>
        );
    } else {
        return(
            <div>
                Para validar aponte para o QRCode ou valide em:<br />{validationURL}
            </div>
        );
    }

}

export default MensagemValidacao;