/*
** @name: Meu Clínicas - appModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render de um 'wrapper' para conteúdo modal (children) com 
** possibilidade de header e de fechar com tecla ESC
*/

import React, { Component } from 'react';

// Import module styles
import './appModal.scss';


const ESCAPE_KEY = 27;


class AppModal extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    _getOnCloseEvent() {
        const { onClose } = this.props;
        return onClose ? e => onClose(e) : () => { console.warn('no close button') };
    }

    _handleKeyDown = (event) => {
        const onClose = this._getOnCloseEvent();
        switch(event.keyCode) {
            case ESCAPE_KEY:
                event.stopPropagation();
                event.preventDefault();
                if(this.props.closeOnEsc) {
                    onClose();
                }
                break;
            default: 
                break;
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown, true);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown, true);
    }

    render() {
        const { headerContent, closeOnClickOutside } = this.props;
        const onCloseButton = this._getOnCloseEvent();
        const onClickOutside = closeOnClickOutside ? onCloseButton : () => { };
        return(
            <div id="wrapperAppModalId" onClick={onClickOutside}>
                <div className="app-modal-floating-box" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                    <div className="app-modal-box no-select">
                        { headerContent &&
                        <div className="app-modal-header">
                            { headerContent }
                        </div>
                        }
                        <div className="app-modal-content-wrapper">
                            <div className="app-modal-content">

                                { this.props.children }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppModal;