/*
** @name: Meu Clínicas - exhibitLocation
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Rotina para geracao da lista de localizade para exibicao da notícia
** 
*/

const EXHIBIT_LOCATION = {
    USER_HOME: "User home page",
    TELEATENDIMENTO_SALA_ESPERA: "Sala de espera Teleatendimento",
}

const buildExhibitLocationOptionList = () => {
    const options = Object.keys(EXHIBIT_LOCATION)
        .sort((a, b) => a < b ? -1 : (a > b ? 1 : 0))
        .map((key, indx) => {
            return { "key": `location_${indx}`, "value": key, "text": EXHIBIT_LOCATION[key] };
        }, []);
    return options;
}

export {
    EXHIBIT_LOCATION,
    buildExhibitLocationOptionList
}