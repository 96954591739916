/*
** @name: Meu Clínicas - camshotFrame
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2020
** @description: Componente para render de uma moldura (estilo maquina fotografica) para  QRCode
**
** @update: Agosto 2024
** @description: Alterando coponente para substituir 'this.refs' que foi deprecado
*/

import React, { Component } from 'react';


class CamshotFrame extends Component {

    constructor(props) {
        super(props);

        const fitContentSize = props.fitContentSize ? props.fitContentSize : false;
        const height = props.height ? props.height : 100;
        const width = props.width ? props.width : 100;
        const color = props.color ? props.color : "#000000";
        const thickness = props.thickness ? props.thickness : 2;
        const overflow = props.overflow ? props.overflow : 'hidden';
        const styles = {
            container: {
                position: 'relative',
                display: 'block',
                width: width,
                height: height,
                overflow: overflow
            },
            canvas: {
                position: 'absolute',
                top: 0,
                left: 0,
            },
            children: {
                position: 'absolute',
                display: 'inline-block',
                top: 0,
                left: 0,
            }
        }

        this.containerRef = React.createRef();
        this.childrenRef = React.createRef();
        this.canvasRef = React.createRef();
        this.state = {
            fitContentSize: fitContentSize,
            styles: styles,
            height: height,
            width: width,
            color: color,
            thickness: thickness,
            overflow: overflow,
            children: props.children
        } 
    }

    _updateCanvas = () => {
        const canvas = this.canvasRef.current;
        const width = canvas.width;
        const height = canvas.height;
        const ctx = canvas.getContext('2d');
        const horizLineSize = Math.trunc(0.3 * width);
        const vertLineSize = Math.trunc(0.3 * height);

        ctx.clearRect(0, 0, width, height);

        ctx.fillStyle = 'transparent';
        ctx.fillRect(0, 0, width, height);

        ctx.strokeStyle = this.state.color;
        ctx.lineWidth = this.state.thickness;
        ctx.beginPath();
        
        ctx.moveTo(0, 0);
        ctx.lineTo(horizLineSize, 0);
        ctx.moveTo(0, 0);
        ctx.lineTo(0, vertLineSize);
        
        ctx.moveTo(width, 0);
        ctx.lineTo(width-horizLineSize, 0);
        ctx.moveTo(width, 0);
        ctx.lineTo(width, vertLineSize);

        ctx.moveTo(0, height);
        ctx.lineTo(horizLineSize, height);
        ctx.moveTo(0, height);
        ctx.lineTo(0, height-vertLineSize);

        ctx.moveTo(width, height);
        ctx.lineTo(width-horizLineSize, height);
        ctx.moveTo(width, height);
        ctx.lineTo(width, height-vertLineSize);

        ctx.stroke();
    }

    componentDidMount() {
        if(this.state.fitContentSize) {
            const container = this.containerRef.current;
            const children = this.childrenRef.current;
            container.style.width = children.clientWidth + "px";
            container.style.height = children.clientHeight + "px";

            this.setState({ 
                height: children.clientHeight,
                width: children.clientWidth
            });
        } else {
            this._updateCanvas();
        }
    }

    componentDidUpdate() {
        this._updateCanvas();
    }

    render() {
        return(
            <div ref={this.containerRef} style={this.state.styles.container} width={this.state.width} height={this.state.height}>
                <div ref={this.childrenRef} style={this.state.styles.children}>{this.state.children}</div>
                <canvas ref={this.canvasRef} style={this.state.styles.canvas} width={this.state.width} height={this.state.height} />
            </div>
        );
    }    


} export default CamshotFrame;
