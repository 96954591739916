/*
** @name: Meu Clínicas - redefinirSenha
** @author: 
** @date:
** @description: Módulo para redefinir senha. Controla a conexão pelo link de recuperação de senha e efetivação da troca
**
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação
*/

import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import utils, { EMPTY_HREF } from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import Password from '../../components/fields/password/password.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';


// Import module styles
import './redefinirSenha.scss'; 


const queryString = require('query-string');

const RedefinirSenha = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <RedefinirSenhaImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class RedefinirSenhaImplem extends Component {

    constructor(props) {
        super(props);

        const urlParams = queryString.parse(props.location.search);
        this.state = { 
            inputPasswordType: "password",
            novaSenha: {
                value: null,
                valid: null
            },
            urlToken: urlParams.tkn,
            msgErro: null,
            msgSucesso: null
        } 
    }

    _clearMessages = () => {
        this.setState({
            msgErro: null,
            msgSucesso: null
        }); 
    }

    _handleBackToHome = (e) => {
        e.preventDefault();
        e.stopPropagation();
        utils.goToHome();
    }

    _handlePasswordChange = (value, valid) => {
        this._clearMessages();
        this.setState({ novaSenha: { value, valid } });
    }

    _redefinirSenha = (e) => {
        e.preventDefault(); 

        this._clearMessages();
        usuarioClient.redefinirSenha(this.state.novaSenha.value, this.state.urlToken, {}, {})
            .then(res => {                
                const header = "Parabéns!";
                const message = "Senha alterada com sucesso";
                this.setState({ msgSucesso: { header, message }});
            })
            .catch(err => { 
                let message = null;
                let header = null;
                if (err.response && err.response.data) {
                    header = "Atenção"
                    message = err.response.data;
                } else {
                    header = "Ops!"
                    message = "Ocorreu um erro ao processar sua requisição.";
                } 

                this.setState({ msgErro: { header, message } }); 
            });
    }  
    
    _togglePasswordVisibility = () => {
        this.setState({ 
            inputPasswordType: this.state.inputPasswordType === 'password' ? 'input' : 'password'
        });
    }

    _validateForm = () => {
        return this.state.novaSenha.valid;             
    }

    render() {
        return (
            <div className="redefinir-senha-wrapper">
                <AppExtraDocumentHead subTitle="Redefinir senha" robots="noindex,nofollow" />

                <div className="content-wrapper">
                    <div className="content-box">
                        <div className="header">
                            <div className="app-logo">
                                <AppCustomImage imageContextFn={getImageThemeContext} module="redefinirSenha" imageId="logo_app-horizontal" />
                            </div>
                        </div>

                        <div className="body">
                            <Form name="frmMain">
                                <div className="info-principal">
                                    <h2>Redefinir senha</h2>
                                </div>

                                <div className="float-label-field nova-senha">
                                    <Password 
                                        id="input-nova-senha" 
                                        name="novaSenha"
                                        className=""
                                        darkerInfo={false}
                                        labelText="Informe uma nova senha"
                                        strengthMeter={true}
                                        onChange={this._handlePasswordChange}
                                    />     
                                </div>

                                {(!this.state.msgSucesso && this.state.msgErro) && 
                                <AppMessageBox
                                    id="msg-redefinir-senha-error"
                                    className="error"
                                    messageData={this.state.msgErro}
                                />
                                }

                                {(this.state.msgSucesso && !this.state.msgErro) && 
                                <AppMessageBox
                                    id="msg-redefinir-senha-information"
                                    className="information"
                                    messageData={this.state.msgSucesso}
                                />
                                }
                                
                                <div className="main-action">
                                    {!this.state.msgSucesso 
                                    ?
                                    <>
                                        <button
                                            id="button-redefinir"
                                            className="app-form-button"
                                            disabled={!this._validateForm()}
                                            onClick={(e) => this._redefinirSenha(e)}>
                                            Redefinir
                                        </button>
                                        <div className="cancelar-redefinicao-senha">
                                            <a href={EMPTY_HREF} id="link-cancelar" onClick={(e) => { this._handleBackToHome(e) }}>
                                                Cancelar
                                            </a> 
                                        </div> 
                                    </>
                                    :
                                    <button 
                                        id="button-ok"
                                        className="app-form-button"
                                        onClick={(e) => { this._handleBackToHome(e) }}>
                                        OK
                                    </button>
                                    }
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>                        
            </div>
        )   
    } 
}

export default RedefinirSenha;
