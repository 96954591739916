import React, { Component } from 'react';
import $ from 'jquery';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { HANGUP_MODE, baseAppRoomOptions } from './baseConfig.js';

import CustomToolbar from './customToolbar.js';


// Import module styles
import './scss/jitsiMeetingRoom.scss'; 


let JitsiMeetAPI;
let jitsiApi;
let connTimeoutId;
let customHangupTimeoutId;
let resetCameraTimeoutId;

class JitsiMeetingRoom extends Component {
    constructor(props) {
        super(props);

        connTimeoutId = null;
        jitsiApi = null;
        resetCameraTimeoutId = null;
        this.jitsiCntnr = React.createRef();
        this.state = {
            cameraOn: false,
            readyToStart: false,
            conferenceUserData: null,
            enableUpload: genesysUtils.typeCheck.isFunction(props.onUpload)
        }
    }

    _buildRoomOptions = (jwtToken, roomId, container, bgColor, hangupMode) => {
        const options = baseAppRoomOptions;
        options.onload = () => { };
        options.jwt = jwtToken;
        options.roomName = roomId;
        options.parentNode = container;
        options.interfaceConfigOverwrite.DEFAULT_BACKGROUND = bgColor;
        if(hangupMode!==HANGUP_MODE.CUSTOM && hangupMode!==HANGUP_MODE.DISABLED) {
            options.configOverwrite.toolbarButtons.push('hangup');
        }
        return options;
    }

    _clearContainer = () => {
        if(this.jitsiCntnr.current) { this.jitsiCntnr.current.innerHTML = ""; }
    }

    _clearResetCameraTimeout = () => {
        if(resetCameraTimeoutId) {
            clearTimeout(resetCameraTimeoutId);
            resetCameraTimeoutId = null;
        }
    }
    
    _getTimestamp = () => {
        return {
            timestamp: new Date().getTime()
        }
    }

    _handleAnexarModalDismiss = (wasCameraOn) => {
        if(genesysUtils.deviceCheck.isMobile() && wasCameraOn && jitsiApi) {
            this._clearResetCameraTimeout();
            jitsiApi.executeCommand('toggleVideo');
            resetCameraTimeoutId = setTimeout(() => jitsiApi.executeCommand('toggleVideo'), 750);
        }
    }

    _handleHangup = () => {
        const { onHangup, onConferenceLeft } = this.props;
        if(genesysUtils.typeCheck.isFunction(onHangup)) {
            onHangup();
        } else {
            if(jitsiApi && genesysUtils.typeCheck.isFunction(onConferenceLeft)) {
                onConferenceLeft();
            }
            this._clearContainer();
            jitsiApi = null;
        }
    }

    _handleCustomToolbarVisibility = () => {
        $("#jitsiWrapperId > .custom-toolbar").addClass("visible");
        if(!customHangupTimeoutId && this.props.autoHideCustomToolbar) {
            customHangupTimeoutId = setTimeout(() => {
                customHangupTimeoutId = null;
                $("#jitsiWrapperId > .custom-toolbar").removeClass("visible");
            }, 4000);
        }
    }

    _handleToolbarEvents = evnt => {
        const { onToolbarButtonClick } = this.props;
        if(evnt.key) {
            switch(evnt.key.toLowerCase()) {
                case "hangup":
                    this._handleHangup();
                    break;
                default:
                    if(evnt.key.toLowerCase()) {
                        if(genesysUtils.typeCheck.isFunction(onToolbarButtonClick)) {
                            onToolbarButtonClick(evnt.key.toLowerCase());
                        }
                    }
            }
        }
    }

    _handleParticipantRoleChanged = evnt => {
        if(evnt.role && evnt.role.toLowerCase()==='moderator') {
            this._setRoomName();
        }
    }

    _handleParticipantJoined = data => {
        const { onParticipantJoined } = this.props;
        if(genesysUtils.typeCheck.isFunction(onParticipantJoined)) {
            onParticipantJoined(data);
        }
    }

    _handleParticipantLeft = data => {
        const { onParticipantLeft } = this.props;
        if(genesysUtils.typeCheck.isFunction(onParticipantLeft)) {
            onParticipantLeft(data);
        }
    }

    _handleUpload = () => {
        const { onUpload } = this.props;
        const { cameraOn, enableUpload } = this.state;
        if(enableUpload) {
            this._clearResetCameraTimeout();
            onUpload(() => this._handleAnexarModalDismiss(cameraOn));
        }
    }

    _handleVideoConferenceJoined = data => {
        const { onConferenceJoined, onFinishLoading } = this.props;
        if(connTimeoutId) {
            clearTimeout(connTimeoutId);
        }
        this._handleCustomToolbarVisibility();
        this.setState({ conferenceUserData: data }, () => { // conference joined/ready
            if(genesysUtils.typeCheck.isFunction(onConferenceJoined)) {
                onConferenceJoined(jitsiApi, data);
            }
            if(genesysUtils.typeCheck.isFunction(onFinishLoading)) {
                onFinishLoading();
            }
        });
    }

    _handleVideoStatusChanged = data => {
        const cameraOn = data && data.muted===false;
        this.setState({ cameraOn: data && data.muted===false });
        if(cameraOn) {
            this._clearResetCameraTimeout();
        }
    }

    _initialize = () => {
        const { domain, roomId, jwtToken, onMissingRequirements } = this.props;
        const isApiOk = genesysUtils.typeCheck.isClass(window.JitsiMeetExternalAPI);
        const isReady = isApiOk && domain && roomId && jwtToken && this.jitsiCntnr.current ? true : false;
        if(isReady) {
            JitsiMeetAPI = window.JitsiMeetExternalAPI;
        } else {
            this._reset();
            if(genesysUtils.typeCheck.isFunction(onMissingRequirements)) {
                onMissingRequirements({ type: 'missing-requirements', ...this._getTimestamp() });
            }
        }
        this.setState({ readyToStart: isReady });
    }

    _openJistiRoom = () => {
        const { domain, jwtToken, roomId, hangupMode, meetingDefaultBackgroundColor,
                maxTimeToJoin, onConnectionTimeout, onStartLoading, onFinishLoading } = this.props;
        if(!jitsiApi) {
            if(genesysUtils.typeCheck.isFunction(onStartLoading)) {
                onStartLoading();
            }
            if(maxTimeToJoin) {
                connTimeoutId = setTimeout(() => {
                    jitsiApi = null;
                    this._clearContainer();
                    if(genesysUtils.typeCheck.isFunction(onFinishLoading)) {
                        onFinishLoading();
                    }
                    if(genesysUtils.typeCheck.isFunction(onConnectionTimeout)) {
                        onConnectionTimeout({ type: 'timeout', ...this._getTimestamp() });
                    }
                }, maxTimeToJoin);
            }

            this._clearContainer();
            const roomOptions = this._buildRoomOptions(jwtToken, roomId, this.jitsiCntnr.current, meetingDefaultBackgroundColor, hangupMode);
            jitsiApi = new JitsiMeetAPI(domain, roomOptions);

            // Events (requires https connection on Chrome / Firefox triggers in http connections)
            jitsiApi.addListener('toolbarButtonClicked', this._handleToolbarEvents);
            jitsiApi.addListener('participantRoleChanged', this._handleParticipantRoleChanged);
            jitsiApi.addListener('videoConferenceJoined', this._handleVideoConferenceJoined);
            jitsiApi.addListener('participantJoined', this._handleParticipantJoined);
            jitsiApi.addListener('participantLeft', this._handleParticipantLeft);
            jitsiApi.addListener('videoMuteStatusChanged', this._handleVideoStatusChanged);

            // Extra room configurations
            this._setRoomName(); // requires participant with moderator role
        }
    }
    
    _reset = () => {
        connTimeoutId = null;
        jitsiApi = null;
        this._clearContainer();
        this.setState({ conferenceUserData: null });
    }

    _setRoomName = () => {
        const { roomName } = this.props;
        if(jitsiApi && roomName) {
            jitsiApi.executeCommand('subject', roomName);
        }
    };

    componentDidMount() {
        this._reset();
        this._initialize();
        $("#jitsiWrapperId").on("mouseover mouseout", this._handleCustomToolbarVisibility);
    }

    componentWillUnmount() {
        this._clearResetCameraTimeout();
        if(connTimeoutId) {
            clearTimeout(connTimeoutId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { readyToStart } = this.state;
        if(prevState.readyToStart!==readyToStart) {
            if(readyToStart) {
                this._openJistiRoom();
            } else {
                this._reset();
            }            
        }
    }

    render() {
        const { hiddenWhileLoading, hangupMode } = this.props;
        const { conferenceUserData, enableUpload } = this.state;

        return(
            <div id="jitsiWrapperId" className='jitsi-root-wrapper'>
                <div ref={this.jitsiCntnr} id='jitsiRootId' className={`jitsi-root-element${(hiddenWhileLoading && !conferenceUserData) ? " loading" : ""}`}></div>
                <CustomToolbar 
                    visible={false}
                    onHangup={hangupMode===HANGUP_MODE.CUSTOM ? this._handleHangup : null} 
                    onUpload={enableUpload ? this._handleUpload : null} />
            </div>
        );
    }
}

export default JitsiMeetingRoom;