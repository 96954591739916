import React, { Component } from 'react'
import PropTypes from 'prop-types'


const NOT_VALIDATE = "NOT_VALIDATE";

const propTypes = {
    elementID: PropTypes.string,
    verifyCallbackName: PropTypes.string,
    verifyCallback: PropTypes.func,
    sitekey: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired
}

const defaultProps = {
    elementID: 'g-recaptcha',
    verifyCallbackName: 'verifyCallback'
}

const isReady = () => typeof window !== 'undefined' && typeof window.grecaptcha !== 'undefined' && typeof window.grecaptcha.execute !== 'undefined';

let readyCheck;

class ReCaptcha extends Component {
    constructor (props) {
        super(props);

        this.execute = this.execute.bind(this);
        this._updateReadyState = this._updateReadyState.bind(this);
        this.state = {
            ready: isReady()
        }
    }

    _updateReadyState () {
        if (isReady()) {
            this.setState(() => ({ ready: true }));
            clearInterval(readyCheck);
        }
    }

    execute (skipValidation) {
        const { sitekey, verifyCallback, action, onError } = this.props
        if(skipValidation) {
            if (verifyCallback) {
                verifyCallback(NOT_VALIDATE);
            }
        }

        if(!this.state.ready) {
            return null;
        }

        try {
            window.grecaptcha.execute(sitekey, { action })
                .then(token => {
                    if (verifyCallback) {
                        verifyCallback(token);
                    }
                })
                .catch(err => {
                    if(onError) {
                        onError(err);
                    }
                })
        } catch(e) {
            console.error("[RECAPTCHA VALIDATION EXCEPTION]", e);
            if(onError) {
                onError(e, true);
            }
            return false;
        }
        return true;
    }

    componentDidMount () {
        if (this.state.ready) {
            //this.execute()
        } else {
            readyCheck = setInterval(this._updateReadyState, 1000);
        }
    }

    componentDidUpdate (_, prevState) {
        if (this.state.ready && !prevState.ready) {
            //this.execute()
        }
    }

    componentWillUnmount () {
        clearInterval(readyCheck);
    }

    render () {
        return this.state.ready ? (
            <div
              id={this.props.elementID}
              data-verifycallbackname={this.props.verifyCallbackName}
            />
        ) : 
        (
            <div id={this.props.elementID} className='g-recaptcha' />
        )
    }
}

ReCaptcha.propTypes = propTypes;
ReCaptcha.defaultProps = defaultProps;

export default ReCaptcha;
