export default class PasswordControl {

    constructor(policies, mediumThreshold, strongThreshold, required) {
        this.policies = policies || this._defaultPolicies();
        this.mediumThreshold = mediumThreshold || 3;
        this.strongThreshold = strongThreshold || 6;
        this.required = required !== undefined ? required : true;
    }

    _defaultPolicies() {
        return [
            {
                id: 'letter',
                title: 'Letra',
                matches: value => /[a-zA-Z]/.test(value),
                required: true
            },            
            {
                id: 'numeric',
                title: 'Número',
                matches: value => /[0-9]/.test(value),
                required: true 
            },
            {
                id: 'atLeast6Chars',
                title: 'Pelo menos 6 caracteres',
                matches: value => /.{6,}/.test(value),
                required: true
            },
            {
                id: 'specialChars',
                title: 'Caracter especial: ! . @ # $ % ^ &',
                matches: value => /[!@#$%^&.]/.test(value),
                required: false
            },
            {
                id: 'lowercase',
                title: 'Letra minúscula',
                matches: value => /[a-z]/.test(value),
                required: false
            },
            {
                id: 'uppercase',
                title: 'Letra maiúscula',
                matches: value => /[A-Z]/.test(value),
                required: false
            },           
            {
                id: 'atMost64Chars',
                title: 'No máximo 64 caracteres',
                matches: value => /^.{0,64}$/.test(value),
                required: true,
                constraint: true
            },
        ]
    }

    checkStrength = (value) => {
        let result = {
            matchCount: 0,
            valid: !this.required || value,
            strength: null,
            strengthTitle: null,
            policyProcessing: [],
            mediumThreshold: this.mediumThreshold,
            strongThreshold: this.strongThreshold
        }

        if (!value) {
            result.strength = 'empty';
            return result;
        }

        this.policies.forEach(policy => {
            let matches = policy.matches(value);
            let isValid = !policy.required || matches;

            if (matches && !policy.constraint) {
                result.matchCount++;
            } 
            result.valid = result.valid && isValid; 
            
            result.policyProcessing.push({
                policyId: policy.id,
                title: policy.title,
                required: policy.required,
                matches: matches,
                valid: isValid
            });
        });  

        if (result.matchCount >= this.strongThreshold) {
            result.strength = 'strong';
            result.strengthTitle = 'Senha forte'
        } else if (result.matchCount >= this.mediumThreshold) {
            result.strength = 'medium';
            result.strengthTitle = 'Senha média'
        } else {
            result.strength = 'weak';
            result.strengthTitle = 'Senha fraca'
        }

        return result;
    }

    checkConstraint = (value) => {
        let success = true;
        this.policies.forEach(policy => {
            let matches = policy.matches(value);
            let isValid = matches || !policy.required || !policy.constraint;

            success = success && isValid;
        });  

        return success;
    }

}
