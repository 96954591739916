/*
** @name: Meu Clínicas - visualizador
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Componente para renderizar o visualizador da tela de exames
*/

import React from 'react';
import { genesysUtils } from "@hcpa-react-components/genesys-utils";

import ViewerModal from '../../components/general/viewerModal/viewerModal.js';


const Visualizador = (props) => {
    const { listaAnexo, listaResultado, listaImages, onCloseViewer } = props;

    if(genesysUtils.typeCheck.isArray(listaImages)) {
        const items = [];
        const totalPaginas = "(Total " + listaImages.length + " página" + (listaImages.length>1 ? "s" : "") + ")";
        const titulo = listaResultado ? `Resultado do Exame ${totalPaginas}` : 
                        (listaAnexo ? `Anexo do Exame ${totalPaginas}` :   `Solicitação ${totalPaginas}`);

        for(var page=0; page<listaImages.length; page++) {
            const strBase64 = listaImages[page];
            const imageData = `data:image/png;base64,${strBase64}`;

            items.push(
                <div key={`page_${page}`}>
                    <img src={imageData} alt=""></img>
                </div>
            );
            if(page<(listaImages.length-1)) {
                items.push(
                    <div key={"space_"+page} className="result-pagespace"></div>
                );
            }
        }

        genesysUtils.general.setViewPortRescale(true);

        return (
            <div className="exames-viwer-wrapper">
                <ViewerModal
                    title={<div className="titulo-modal">{titulo}</div>}
                    onCloseButton={onCloseViewer}>
                    {items}
                </ViewerModal>
            </div>
        );

    } else {
        genesysUtils.general.setViewPortRescale(false);
        return null;
    }
}

export default Visualizador;