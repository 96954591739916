import React from 'react';

import { EMPTY_HREF } from '../../core/utils.js';
import { getAppGeneralSettingsPropertyByName, isAppServiceEnabled } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import MainButton from './mainButton.js';
import WelcomeMessage from './welcomeMessage.js';


const PendenteAtivacao = (props) => {
    const { appContextConfig, envioEmail, onVoltar, onReenviarEmail, onAlterarDados } = props;
    const appName = getAppGeneralSettingsPropertyByName(appContextConfig, "app-name");
    return(
        <div className="login-form ativar-cadastro">
            <WelcomeMessage 
                title="Ative seu cadastro" 
                message={<AppCustomMessage messageId="login_welcome-step-pendente-ativacao" params={[appName]}/>} 
            />

            <MainButton
                id="button-ok" 
                wrapperClassName="main-action voltar"
                className="app-form-button"
                disabled={false}
                onClick={onVoltar} 
                text="Ok"
            />

            { (!envioEmail || !envioEmail.enviadoComSucesso) && 
            <div className="reenviar-email-ativacao">
                <div>Não recebeu?</div>
                <a id="link-reenviar-email-ativacao" 
                    href={EMPTY_HREF}
                    onClick={onReenviarEmail}>
                    Clique aqui para enviar um novo e-mail.
                </a>
            </div>
            }

            { isAppServiceEnabled(appContextConfig, APP_SERVICE_LIST.EDITAR_DADOS) &&
            <MainButton
                id="button-alterar"
                wrapperClassName="main-action alterar-dados"
                className="app-form-button"
                disabled={!onAlterarDados} 
                onClick={onAlterarDados} 
                text="Alterar Meus Dados" />
            }

            { envioEmail ?
            <AppMessageBox
                id={`msg-login-${envioEmail.enviadoComSucesso ? "information" : "error"}`}
                className={`${envioEmail.enviadoComSucesso ? "information" : "error"} login-message-box`}
                messageData={envioEmail}
            />
            :
            <div className="login-message-box"></div>
            }

        </div>
    );
}

export default PendenteAtivacao;