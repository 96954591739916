/*
** @name: Meu Clínicas - laudosAtestadosValidado
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Maio 2024
** @description: Renderiza a laudos/atestados validados
*/

import React from 'react';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import LaudosAtestadosView from '../../components/general/laudosAtestadoView/laudosAtestadosView.js';
import ViewerModal from '../../components/general/viewerModal/viewerModal.js';

import ModalPdfDownloadError from './modalPdfDownloadError.js';
import ViewerActionButtons from './viewerActionButtons.js';
import ViewerHeader from './viewerHeader.js';


const LaudosAtestadosValidado = (props) => {
    const { resultadoValidacao, resultadoDownloadPDF, onClearDownloadError, onClose, onObterPDF } = props;

    const { laudosAtestados, chaveAutenticacao, validadaEm } = resultadoValidacao || {};
    const viewerTitle = <AppCustomMessage elemType="fragment" messageId="validador_viewer-laudos-atestados-validado" params={[ laudosAtestados.tokenValidacao ]} />

    return(
        <div className="documento-valido-viwer-wrapper">
            <ViewerModal 
                key={"laudoAtestadoValidadoID"}
                header={<ViewerHeader title={viewerTitle} onClose={() => onClose()} />}
                footer={null}
            >

                <LaudosAtestadosView validadaEm={validadaEm} chaveAutenticacao={chaveAutenticacao} dados={laudosAtestados} />

            </ViewerModal>

            <ViewerActionButtons
                onGetPdf={() => onObterPDF(laudosAtestados)}
                onDispensar={null}
            />

            { resultadoDownloadPDF.error && 
            <ModalPdfDownloadError
                onConfirm={()=> onClearDownloadError()}
                message={resultadoDownloadPDF.message} />
            }
        </div>
    );
}
export default LaudosAtestadosValidado;