import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

import sessionStorageManager from './sessionStorageManager';


class WebsocketUtils {

    stompClient = null;

    _getAuthenticationParams() {
        return {
            'jwt-header': sessionStorageManager.auth.getToken(),
            'fingerprint': sessionStorageManager.auth.getFingerprint()
        };
    }

    connect = (stompClient) => {
        if(stompClient && stompClient.activate) {
            stompClient.activate();
        }
    }

    disconnect = (stompClient) => {
        if(stompClient && stompClient.deactivate) {
            stompClient.deactivate();
        }
    }

    /**
     * 
     * @param {*} events object with stomp client events.
     * 
     * Available events are: { onChangeState, onConnect, onDisconnect, onWebSockectClose, onWebSockectError, onStompError, onUnhandledFrame, onUnhandledMessage, onUnhandledReceipt }
     * @returns Stomp Client object
     */
     getStompClient = (socketUrl, connectHeaders, events, heartbeat, reconnDelay, onDebug) => {
        const { onChangeState, onConnect, onDisconnect, onWebSockectClose, onWebSockectError, onStompError,
                onUnhandledFrame, onUnhandledMessage, onUnhandledReceipt } = events || {};
        const emptyFunc = () => {};
        const paramsQueryString = new URLSearchParams(this._getAuthenticationParams());
        const socket = new SockJS(`${socketUrl}?${paramsQueryString}`);
        const stompClient = new Client({
            connectHeaders: {
                ...connectHeaders
            },
            debug: onDebug ? onDebug : emptyFunc,
            heartbeatIncoming: heartbeat ? heartbeat : 4000,
            heartbeatOutgoing: heartbeat ? heartbeat : 4000,
            reconnectDelay: reconnDelay ? reconnDelay : 0,
            webSocketFactory: () => { return socket },
            onChangeState: onChangeState ? onChangeState : emptyFunc,
            onConnect: onConnect ? onConnect : emptyFunc,
            onDisconnect: onDisconnect ? onDisconnect : emptyFunc,
            onWebSocketClose: onWebSockectClose ? onWebSockectClose : emptyFunc,
            onWebSocketError: onWebSockectError ? onWebSockectError : emptyFunc,
            onStompError: onStompError ? onStompError : emptyFunc,
            onUnhandledFrame: onUnhandledFrame ? onUnhandledFrame : emptyFunc,
            onUnhandledMessage: onUnhandledMessage ? onUnhandledMessage : emptyFunc,
            onUnhandledReceipt: onUnhandledReceipt ? onUnhandledReceipt : emptyFunc,
        });
        return stompClient;
    }

    publish = (stompClient, destination, headers, body) => {
        if(stompClient && stompClient.publish) {
            const params = { 
                destination: destination,
                headers: { 
                    "Content-type": 'application/json',
                    ...headers
                },
                body: JSON.stringify(body)
            }
            stompClient.publish(params);
        }
    }

    subscribe = (stompClient, topic, headers, onMessageReceived) => {
        if(stompClient && stompClient.subscribe && topic) {
            stompClient.subscribe(topic, onMessageReceived, { ...headers });
        }
    }
}

const websocketUtils = new WebsocketUtils();
export default websocketUtils;