/*
** @name: Meu Clínicas - instrucoes
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Componente para renderizar informacoes iniciais do teleatendimento
*/

import React, { useState, useEffect } from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import FaqContent from '../../modules/faq/faqContent.js';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const Instrucoes = (props) => {
    const { infoConsulta, instrucoes, errorMessage, onClose, onAvancar } = props;
    const [ instructionsContent, setInstructionsContent ] = useState(null);

    useEffect(() => { // ativar item faq
        if(genesysUtils.typeCheck.isArray(instrucoes)) {
            instrucoes[0].active = true;
            setInstructionsContent([ instrucoes[0] ]);
        }
    }, [instrucoes]);

    return(
        <div className='instrucoes-wrapper'>
            { instructionsContent &&
            <div className='instrucoes-box'>
                <FaqContent 
                    listFaq={instructionsContent}
                    disableAutoPlay={false}
                    hideTitle={true}
                    hideTags={true}
                    hideAnswer={true}
                    hideImages={false}
                    onAddTag={null}
                />
            </div>
            }

            { errorMessage ?
            <>
                <AppMessageBox 
                    id="msg-instrucoes-error" 
                    className="error" 
                    messageData={{ message: errorMessage }}
                />
                <div className='main-action'>
                    <button className="app-form-button" onClick={onClose}>Fechar</button>
                </div>
            </>
            :
            <>
                { (infoConsulta && instructionsContent) &&
                <div className='main-action'>
                    <button className="app-form-button" onClick={onAvancar}>Avançar</button>
                </div>
                }
            </>
            }
        </div>
    );
}

export default Instrucoes;
