/*
** @name: Meu Clínicas - teleatendimentoClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de teleatendimento
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';

import { buildApiRequestInstance } from '../../core/appRequest.js';
import websocketUtils from "../../core/websocketUtils";

import { API_PORT, API_SERVICE, API_VERSION } from './consultasClient.js';


const TELEATENDIMENTO_TOPIC = '/user/ws-topic/teleatendimento';
const TELEATENDIMENTO_PUBLISH = '/app/ws-post/teleatendimento';

const MESSAGE_TYPE = {
    CHECKIN: 'CHECKIN',
    READY_TO_START: 'READY_TO_START',
    ECHO: 'ECHO'
}

const MESSAGE_TYPE_EXTENDED = {
    ...MESSAGE_TYPE,
    SUBSCRIPTION_CONFIRMATION: 'SUBSCRIPTION_CONFIRMATION'
}

class TeleatendimentoClient {
    #apiRequest;

    constructor() {
        this.#apiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    infoConsulta(pacCodigo, numeroConsulta, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, numeroConsulta };
        return this.#apiRequest.auth.get('/teleatendimento/info-consulta', queryParams, null, extraAxiosConfig, options);
    }

    informarReadyToStart(payload, extraAxiosConfig, options) { 
        return this.#apiRequest.noAuth.get('/public/teleatendimento/ready-to-start', payload, null, extraAxiosConfig, options);
    }

    obterJitsiToken(pacCodigo, numeroConsulta, userDisplayName, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo, numeroConsulta, userDisplayName };
        return this.#apiRequest.auth.get('/teleatendimento/jwt', queryParams, null, extraAxiosConfig, options);
    }

    obterJitsiTokenExternal(queryParams, extraAxiosConfig, options) { 
        return this.#apiRequest.noAuth.get('/public/teleatendimento/jwt', queryParams, null, extraAxiosConfig, options);
    }

    obterJitsiTokenExternalFromToken(jwtServiceToken, fingerprint, extraAxiosConfig, options) { 
        const queryParams = { jwtServiceToken,  fingerprint };
        return this.#apiRequest.noAuth.get('/public/teleatendimento/jwt-from-token', queryParams, null, extraAxiosConfig, options);
    }

    realizarCheckin(pacCodigo, numeroConsulta, extraAxiosConfig, options) {
        const payload = { pacCodigo, numeroConsulta };
        return this.#apiRequest.auth.post('/teleatendimento/checkin', payload, null, extraAxiosConfig, options);
    }

    sairSalaEspera(pacCodigo, numeroConsulta, extraAxiosConfig, options) {
        const payload = { pacCodigo, numeroConsulta };
        return this.#apiRequest.auth.post('/teleatendimento/sair-sala', payload, null, extraAxiosConfig, options);
    }

    videoChamadaFeedback(payload, extraAxiosConfig, options) {
        return this.#apiRequest.auth.post('/teleatendimento/video-feedback', payload, null, extraAxiosConfig, options);
    }

    wsPatientCheckin = (stompClient, pacCodigo, numeroConsulta) => {
        if(!stompClient) {
            return null;
        }
        this.wsTopicPublish(stompClient, { 
            type: MESSAGE_TYPE_EXTENDED.CHECKIN,
            pacCodigo,
            attributes: {
                numeroConsulta
            }
        });
    }

    wsDisconnect = (stompClient) => {
        websocketUtils.disconnect(stompClient);
    }

    /**
     * 
     * @param {*} events object with stomp client events.
     * 
     * Available events are: { onChangeState, onConnect, onDisconnect, onWebSockectClose, onWebSockectError, onStompError, onUnhandledFrame, onUnhandledMessage, onUnhandledReceipt, onMessageReceived, onSubscriptionConfirmed }
     * @returns Stomp Client object
     */
    wsTopicConnect = (pacCodigo, events) => { 
        let stompClient = null;
        const subscribeHeaders = {
            pacCodigo
        }
        const origOnConnect = events.onConnect;
        const origOnMessageReceived = events.onMessageReceived;
        const origOnSubscriptionConfirmed = events.onSubscriptionConfirmed;
        delete events.onMessageReceived;
        events.onConnect = frame => {
            if(origOnConnect) {
                origOnConnect(frame);
            }
            const messageHandler = frame => {
                try {
                    const jsonBody = JSON.parse(frame.body) || {};
                    if(jsonBody.type===MESSAGE_TYPE_EXTENDED.SUBSCRIPTION_CONFIRMATION) {
                        if(jsonBody.pacCodigo===pacCodigo && jsonBody.attributes && jsonBody.attributes.confirmed) {
                            if(origOnSubscriptionConfirmed) {
                                origOnSubscriptionConfirmed(stompClient, TELEATENDIMENTO_TOPIC);
                                return;
                            }
                        } else { // diconect confirmation failed
                            this.wsDisconnect(stompClient);
                        }
                    }
                } catch(e) {
                    console.error('Error parsing websocket message body');
                }
                if(origOnMessageReceived) {
                    origOnMessageReceived(frame);
                }
            }

            // Subscribe to topic
            websocketUtils.subscribe(stompClient, TELEATENDIMENTO_TOPIC, subscribeHeaders, messageHandler);

            // Send message requesting subscription confirmation
            this.wsTopicPublish(stompClient, { 
                type: MESSAGE_TYPE_EXTENDED.SUBSCRIPTION_CONFIRMATION,
                pacCodigo,
                attributes: {
                    topic: TELEATENDIMENTO_TOPIC
                }
            });
        }

        stompClient = websocketUtils.getStompClient(
            buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/ws-entry'), {}, events);
        
        websocketUtils.connect(stompClient);
        return stompClient;
    }

    wsTopicPublish = (stompClient, body) => {
        websocketUtils.publish(stompClient, TELEATENDIMENTO_PUBLISH, {}, body);
    }
}

const teleatendimentoClient = new TeleatendimentoClient();
export default teleatendimentoClient;
export {
    MESSAGE_TYPE
}