import React from "react";
import { getFieldContentClasses, FormBuilderSupportFields } from 'react-dj-forms-builder';

import Password from '../../fields/password/password.js';


const PasswordField = (props) => {
    const { id, name, label, labelText, darkerInfo, strengthMeter, disabled,
            requiredMark, onChange, value, errorMessage, disablePaste } = props;
    const _handleChangePass = (value, valid, name) => onChange(null, { name, value }, valid);

    return(
        <>
            { label &&
            <div className="field-label">{label}{requiredMark ? <FormBuilderSupportFields.RequiredMark /> : null}</div>
            }

            <div className={`password-field-wrapper ${getFieldContentClasses(value, errorMessage, disabled)}`}>
                <Password 
                    id={id}
                    name={name}
                    className={errorMessage ? "error" : ""}
                    disabled={disabled}
                    value={value}
                    onChange={_handleChangePass}
                    darkerInfo={darkerInfo}
                    labelText={labelText}
                    strengthMeter={strengthMeter}
                    disablePaste={disablePaste}
                />
                { errorMessage &&
                <div className="field-error">{errorMessage}</div>
                }
            </div>
        </>
    );
}

export default PasswordField;