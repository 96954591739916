/*
** @name: Meu Clínicas - accessStatistics
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2024
** @description: Módulo para renderizar seção de estatísticas de acesso no dashboard (Refatorado do módulo principal)
*/

import React, { useEffect } from 'react';
import { Accordion } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';


const _buildChartData = (dadosAcessos) => {
    if(!dadosAcessos) {
        return null;
    }

    const labels = { total: [], crescimento: [], percentual: [] };
    const data = { total: [], crescimento: [], percentual: [] };
    const totalAcessos = Object.keys(dadosAcessos).reduce((total, key) => total + (parseInt(dadosAcessos[key]) || 0), 0);
    var valorAnterior = null;
    Object.keys(dadosAcessos).forEach((key, indx) => {
        const valor = parseInt(dadosAcessos[key]) || 0;
        const percentual = totalAcessos > 0 ? (100 * valor/totalAcessos) : 0; // Percentual do total
        const cresc = valorAnterior > 0 ? (100 * ((valor/valorAnterior) - 1)) : 0; // Pecentual em relação ao mês anterior
        const legenda = `${key.substr(-2, 2)}/${key.substr(0, 4)}`;
        
        labels.total.push(legenda);
        labels.crescimento.push(legenda);
        labels.percentual.push(legenda);
        data.total.push(valor);
        data.crescimento.push(cresc);
        data.percentual.push(percentual);
        
        valorAnterior = valor;
    });

    const chartData = [
        {
            labels: labels.total,
            datasets: [
                {
                    label: "Total de Acessos",
                    data: data.total,
                    backgroundColor: "green",
                },
            ],
        },
        {
            labels: labels.crescimento,
            datasets: [
                {
                    label: "Crescimento (%)",
                    data: data.crescimento,
                    backgroundColor: "blue",
                },
            ],
        }
    ]

    return chartData;
}

const _fittingString = (ctx, str, maxWidth) => {
    var width = ctx.measureText(str).width;
    var ellipsis = '…';
    var ellipsisWidth = ctx.measureText(ellipsis).width;
    if (width<=maxWidth || width<=ellipsisWidth) {
        return str;
    } else {
        var len = str.length;
        while((width>=(maxWidth-ellipsisWidth) || (str.substring(len-1)==='.')) && len-->0) {
            str = str.substring(0, len);
            width = ctx.measureText(str).width;
        }
        return str+ellipsis;
    }
}

const _roundPercentage = (dblValue, decimals) => {
    const mult = Math.pow(10, decimals);
    return Math.round((dblValue + Number.EPSILON) * mult) / mult;
}

const AccessStatistics = (props) => {
    const { fetchingMessage, onToggle } = props;
    const accesses = props.data;
    const chartData = _buildChartData(accesses.data);
    const chartOptions = {
        responsive: true,
        legend: {
            position: "top",
            labels: {
                boxWidth: 20
            }
        },
        tooltips: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
    };

    useEffect(() => {
        // Register code to render bar value
        Chart.register(...registerables);
        Chart.register({
            id: 'pluginsId',
            afterDraw: (chartInstance) => {
                /*
                ** Specific instance can be obtained by this.<REF_NAME>.current.chartInstance;
                **/
                const datasetIndex = 0;
                const ctx = chartInstance.ctx;
                ctx.textAlign = "center";
                ctx.textBaseline = "bottom";
                const data = chartInstance?.config?._config?.data;
                const yScale = chartInstance.scales.y;
                const labels = chartInstance?.data?.labels;
                const dataArray = data?.datasets[datasetIndex]?.data;
                const meta = chartInstance.getDatasetMeta(datasetIndex);
                if(chartInstance?.config?._config?.type === 'bar' && meta && yScale && labels && dataArray) {
                    dataArray.forEach((barValue, index) => {
                        const fontSize = 10;
                        const fontFamily = "Verdana";
                        const barLegendVSpacing = 4;
                        const barLegendHMargin = 2;
                        const textHeight = fontSize + barLegendVSpacing;
                        const scaleBase = meta.data[index].base; // same of yScale.getPixelForValue(0)
                        const barY = meta.data[index].y; // same as yScale.getPixelForValue(barValue)
                        const barX = meta.data[index].x;
                        const barHeight = (scaleBase - barY);
                        const barWidth = meta.data[index].width;
                        const insideBar = Math.abs(barHeight) > textHeight;

                        let x = barX;
                        let y = insideBar ? (barY + (barHeight < 0 ? 0 : textHeight)) : (barHeight >= 0 ? barY : (barY + textHeight));
                        if(meta.label === 'Total de Acessos') { // same as data.datasets[datasetIndex].label
                            barValue = _roundPercentage(barValue, 1).toFixed(1);
                        } else {
                            barValue = barValue.toLocaleString("pt-BR", {minimumFractionDigits: 0});
                        }

                        ctx.font = `${fontSize}px ${fontFamily}`;
                        ctx.fillStyle = insideBar ? "#ffffff" : "#333333";
                        ctx.fillText(_fittingString(ctx, barValue, barWidth-(2*barLegendHMargin)), x, y);
                    });
                }
            }
        });
    }, []);

    return(
        <div className={`accordion-item${accesses.active ? " active" : ""} statistic-accesses`}>
            <Accordion.Title onClick={() => onToggle(accesses)}>
                <div className="title-info-wrapper">
                    <div className="information">Acessos</div>
                </div>
                <div className="collapsible-wrapper">
                    <AccordionCollapsible active={accesses.active} iconVariant={null} />
                </div>
            </Accordion.Title>
            <Accordion.Content>
                <div>
                    { (!genesysUtils.typeCheck.isArray(chartData) || chartData.length!==2) ? fetchingMessage :
                    <div className="statistic-content-wrapper">
                        <div className="graphic-wrapper">
                            <div className="statistic-row">
                                <Bar
                                    options={
                                        {
                                            ...chartOptions,
                                            scales: {
                                                y: {
                                                    ticks: {
                                                        beginAtZero: true,
                                                        callback: function(value, index, values) {
                                                            return value.toLocaleString("pt-BR", {minimumFractionDigits: 0}) + " ";
                                                        }
                                                    }
                                                }
                                            },
                                        }
                                    }
                                    data={chartData[0]} />

                                <Bar 
                                    options={
                                        {
                                            ...chartOptions,
                                            scales: {
                                                y: {
                                                    ticks: {
                                                        beginAtZero: true,
                                                        callback: function(value, index, values) {
                                                            return value.toFixed(1) + "% ";
                                                        }
                                                    }
                                                }
                                            },
                                        }
                                    }
                                    data={chartData[1]} />
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </Accordion.Content>
        </div>
    );
}

export default AccessStatistics;