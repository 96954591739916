/*
** @name: Meu Clínicas - aceiteTermosSessao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para exibir termos de usos dentor da sessão, exigindo o aceite.
**
** @update: Agosto 2024
** @description: Refatoramento para passar os termos para estrutura de mensagens
*/

import React, { Component } from 'react';

import { AppCustomMessage } from '@hcpa-react-components/app-customization';
import AppModal from '../appModal/appModal.js';

import CheckboxField from '../../fields/formsBuilderCustoms/checkboxField.js';

// Import module styles
import './aceiteTermosSessao.scss';


class AceiteTermosSessao extends Component {

    constructor(props) {
        super(props);

        const { aceite } = props;
        this.state = {
            aceite: aceite ? true : false,
            erroMessage: null
        }
    }

    _handleTogleAceite = (checked) => {
        this.setState({ 
            aceite: checked,
            erroMessage: null
        });
    }

    _handleCancelar = (e) => {
        if(this.props.onCancel) {
            this.props.onCancel(e);
        }
    }

    _handleConfirmar = (e) => {
        if(!this.state.aceite) {
            this.setState({
                erroMessage: "Para confirmar você deve primeiro marcar que aceita os termos."
            });
            return;
        }

        if(this.props.onConfirm)
            this.props.onConfirm(e);
    }

    render() {
        const ExtraContent = this.props.mensagemExtra ? this.props.mensagemExtra : () => { return null };

        return(
            <div id="aceiteTermosWrapperId">
                <AppModal>
                    <div id="aceiteTermosContentId">
                        <div className="termos">
                            <AppCustomMessage elemType="html" messageId="_general_termos-aceite" />
                        </div>

                        <ExtraContent />

                        <div className="aceite" >
                            <CheckboxField
                                id='checkbox-aceite2'
                                name='aceite2'
                                label="Li e aceito os termos de uso e privacidade."
                                disabled={false}
                                className='checkbox-aceite'
                                checked={this.state.aceite}
                                onToggle={(e, data) => this._handleTogleAceite(data.value)} />
                        </div>

                        { this.state.erroMessage && 
                        <div className="error-message">
                            { this.state.erroMessage }
                        </div>
                        }

                        <div className="action-buttons">
                            <div className="left-btn">
                                <button 
                                    type="button" 
                                    className="btn btn-aceite"
                                    disabled={false}
                                    onClick={e => { this._handleConfirmar(e) }} >Confirmar</button>
                            </div>
                            <div className="right-btn">
                                <button 
                                    type="button" 
                                    className="btn btn-aceite"
                                    onClick={e => { this._handleCancelar(e) }} >Cancelar</button>
                            </div>
                            <div className="clear"> </div>
                        </div>
                    </div>
                </AppModal>
            </div>
        );
    }
} export default AceiteTermosSessao;
