/*
** @name: Meu Clínicas - laudosAtestadosView
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br), Jardel Gugel (jgugel@hcpa.edu.br)
** @date: Maio 2024
** @description: Módulo para renderizar a visualização de um laudo/atestado
*/

import React, { Component } from 'react';
import { pascalCase } from '@hcpa-react-components/string-utils';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { getAppServiceSettingsByName, getAppGeneralSettingsPropertyByName, importCustomImage } from '../../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../../core/appServiceList.js';

import QRCodeBlock from '../qrCodeBlock/qrCodeBlock.js';

import CorpoDocumento from './corpoDocumento.js';
import MensagemValidacao from './mensagemValidacao.js';

import './laudosAtestadosView.scss';


const LaudosAtestadosView = (props) => {
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <LaudosAtestadosViewImplem 
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

export class LaudosAtestadosViewImplem extends Component {
    constructor(props) {
        super(props);

        this.state = { } 
    }

    _getEnderecoTelefone = () => {
        const serviceConfig = getAppServiceSettingsByName(this.props.appContextConfig, APP_SERVICE_LIST.LAUDOS_ATESTADOS);
        const enderecoTelefone = serviceConfig.enderecoTelefone;
        return { 
            enderecoL1: enderecoTelefone["endereco-l1"],
            enderecoL2: enderecoTelefone["endereco-l2"],
            telefone: enderecoTelefone.telefone
        }
    }

    _isNull = (value, nullvalue) => value || nullvalue;

    render() {
        const dadosExibicao = this.props.dados;
        if(!genesysUtils.typeCheck.isObject(dadosExibicao)) {
            throw new Error("Não há dados de laudos/atestado para exibição");
        }

        if(!this.props.appContextConfig || this.props.appContextConfig.constructor !== ({}).constructor) {
            throw new Error("App configuration not informed or is invalid");
        }

        const { validadaEm, chaveAutenticacao }  = this.props;
        const validationBaseURL = process.env.REACT_APP_SERVICES_LAUDOS_ATESTADOS_VALIDAR;
        const logoHeader = importCustomImage(this.props.appContextConfig, "laudosAtestados", "logo_laudoos-atestados-header");
        const logoImageAlt = getAppGeneralSettingsPropertyByName(this.props.appContextConfig, "nome-hospital");
        const { enderecoL1, enderecoL2, telefone } = this._getEnderecoTelefone();

        const identificacaoServidor = pascalCase((this._isNull(dadosExibicao.servidorTitulo, "") + " " + this._isNull(dadosExibicao.servidorNome, "")).trim());
        const nomePaciente = this._isNull(dadosExibicao.pacNome, "").toUpperCase();
        const dataElaboracao = dadosExibicao.dataConsulta;

        const validationURL = validationBaseURL + `/${dadosExibicao.tokenValidacao}`;
        const corpoLaudoAtestado = <CorpoDocumento dados={dadosExibicao} />
        const mensagemValidacao = <MensagemValidacao emphasisValidacao={validadaEm && chaveAutenticacao} validadaEm={validadaEm} chaveAutenticacao={chaveAutenticacao} validationURL={validationURL} />;

        const qrCodeBlock = [];
        qrCodeBlock.push(<QRCodeBlock keyName="camshot_frame_m" frameColor="#000000" frameThickness={3} size={250} value={validationURL} enableToolTip={false} onClick={null} />); // Portraid/Mobile view
        qrCodeBlock.push(<QRCodeBlock keyName="camshot_frame_d" frameColor="#000000" frameThickness={3} size={150} value={validationURL} enableToolTip={false} onClick={null} />); // Landscape/Desktop view

        return(
            <div id="contentLaudoAtestadoWrapperID" className="content-wrapper">
                <div className="content-header-wrapper">
                    <div className="_head">
                        <div className="img-wrapper">
                            <img id="imgLogoID" className="img-logo" src={logoHeader} alt={logoImageAlt} />
                        </div>
                        <div className="head-content">
                            <label className="info">{identificacaoServidor}</label><br />
                        </div>
                        <div className="flex-break-line"> </div>
                    </div>
                    <div className="content-separator"><hr /></div>
                </div>
                <div className="content-body-wrapper">
                    <div className="_body">
                        <div className="paciente">Paciente: {nomePaciente}</div>
                        <div className="body-content">
                            {corpoLaudoAtestado}
                        </div>
                    </div>
                </div>
                <div className="content-footer-wrapper">
                    <div className="validacao">
                        <div className="qrcode-pv">
                            {qrCodeBlock[0]}
                        </div>
                        <div className="validacao-msgs-wrapper">
                            <div className="elaborado">Este documento foi elaborado no dia {dataElaboracao}</div>
                            <div className="mensagem-validacao no-select">{mensagemValidacao}</div>
                        </div>
                        <div className="qrcode-lv">
                            {qrCodeBlock[1]}
                        </div>
                        <div className="clear"> </div>
                    </div>
                    <div className="content-separator"><hr /></div>
                    <div className="_footer">
                        <label>{enderecoL1}</label><br />
                        <label>{enderecoL2}</label><br />
                        <label>{telefone}</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default LaudosAtestadosView;
