/*
** @name: Meu Clínicas - instrucoesCadasto
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar instruções do cadastro
*/
import React from "react";

import { AppCustomMessage } from '@hcpa-react-components/app-customization';


const InstrucoesCadastro = (props) => {
    const { instrucoesCadastro, onAvancar } = props;
    return(
        <div className="cadastro-instrucoes">
            <div className="instrucoes-content">
                <AppCustomMessage messageTemplate={instrucoesCadastro || ""} />
            </div>

            <div className="main-action">
                <button id="button-avancar" className="app-form-button" onClick={onAvancar}>Avançar</button>
            </div>
        </div>
    );
}

export default InstrucoesCadastro;