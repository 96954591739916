/*
** @name: Meu Clínicas - buildCardParameters
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Prove um metodo que, baseado no módulo, retorna um objeto de parâmetros conforme o contexto.
** Usado pelo 'appNavigationControls' (botões) e demais modulos que precisem gerar parametros, de forma padrão, 
** para a chamada de outros modulos. Esses parametros podem ser complementados se necessário.
*/

import { APP_SERVICE_LIST } from './appServiceList.js';


const buildCardParameters = (authContext, serviceName) => {
    const parameters = {};

    if(authContext) {
        const user = authContext.properties.user;
        const isAuth = authContext.methods.isAuthenticated();

        switch(serviceName) {
            case APP_SERVICE_LIST.CADASTRO:
                parameters.localizador = isAuth ? user.localizador : null;
                break;
            default:
                break;
        }
    }

    return parameters;
}

export default buildCardParameters;