/*
** @name: Meu Clínicas - configuration
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Rotinas para lidar com as configuracoes dos formulários
*/
import { setConfigFieldProperty, setConfigFieldCustomComponent } from 'react-dj-forms-builder';

import { DateWithDatePicker, ScoreMeter } from '../../components/fields/formsBuilderCustoms/';

import { InstrucoesGeral, InstrucoesPesquisa } from './instrucoes.js';
import { Footer } from './footer.js';

const FORM_CONFIG = {
    SELECAO: 'pesqSelecao',
    PESQUISA_AMBULATORIAL: 'pesqAmbulatorio',
    PESQUISA_EXAME: 'pesqExames',
    PESQUISA_INTERNACAO: 'pesqInternacao',
    PESQUISA_TELEATENDIMENTO: 'pesqTeleconsulta'
}

const STEPS = {
    INITIALIZE: 'initialize',
    SELECAO: 'selecao',
    PESQUISA_AMBULATORIAL: 'pesquisaAmbulatorio',
    PESQUISA_EXAME: 'pesquisaExames',
    PESQUISA_INTERNACAO: 'pesquisaInternacao',
    PESQUISA_TELEATENDIMENTO: 'pesquisaTeleconsulta'
}

const buildConfigPesquisa = (formConfigs, currentStep, exibirMensagemSelecaoPesquisa) => {
    let configPesquisa = null;
    switch(currentStep) {
        case STEPS.SELECAO:
            configPesquisa = {
                formConfig: Object.assign({}, formConfigs[FORM_CONFIG.SELECAO]),
                className: "selecao-section",
                title: "Seleção da Pesquisa de Experiência",
                instruction: <InstrucoesGeral exibirMensagemSelecao={exibirMensagemSelecaoPesquisa} />,
                buttonMessage: "Avançar",
                enableVoltar: false
            }
            break;
        case STEPS.PESQUISA_AMBULATORIAL:
            configPesquisa = {
                formConfig: Object.assign({}, formConfigs[FORM_CONFIG.PESQUISA_AMBULATORIAL]),
                className: "pesquisa-section pesquisa-ambulatorial",
                title: "Experiência do Paciente no Atendimento Ambulatorial",
                instruction: <InstrucoesPesquisa />,
                footer: <Footer />,
                buttonMessage: "Enviar",
                enableVoltar: true
            }
            break;
        case STEPS.PESQUISA_EXAME:
            configPesquisa = {
                formConfig: Object.assign({}, formConfigs[FORM_CONFIG.PESQUISA_EXAME]),
                className: "pesquisa-section pesquisa-exame",
                title: "Experiência do Paciente no Atendimento de Exames",
                instruction: <InstrucoesPesquisa />,
                footer: <Footer />,
                buttonMessage: "Enviar",
                enableVoltar: true
            }
            break;
        case STEPS.PESQUISA_INTERNACAO:
            configPesquisa = {
                formConfig: Object.assign({}, formConfigs[FORM_CONFIG.PESQUISA_INTERNACAO]),
                className: "pesquisa-section pesquisa-internacao",
                title: "Experiência do Paciente no Atendimento de Internação",
                instruction: <InstrucoesPesquisa />,
                footer: <Footer />,
                buttonMessage: "Enviar",
                enableVoltar: true
            }
            break;
        case STEPS.PESQUISA_TELEATENDIMENTO:
            configPesquisa = {
                formConfig: Object.assign({}, formConfigs[FORM_CONFIG.PESQUISA_TELEATENDIMENTO]),
                className: "pesquisa-section pesquisa-teleatendimento",
                title: "Experiência do Paciente na Consulta Remota",
                instruction: <InstrucoesPesquisa />,
                footer: <Footer />,
                buttonMessage: "Enviar",
                enableVoltar: true
            }
            break;
        default:
            return null;
    }

    return configPesquisa;
}

const configureForms = (formConfigs, selecaoPesquisa, disableSelecao) => {
    setConfigFieldProperty(formConfigs[FORM_CONFIG.SELECAO], ["selecaoPesquisa"], "value", selecaoPesquisa);
    setConfigFieldProperty(formConfigs[FORM_CONFIG.SELECAO], ["selecaoPesquisa"], "disabled", ((selecaoPesquisa && disableSelecao) ? true : false));
    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_AMBULATORIAL], 
        ["ambPergunta1", "ambPergunta2", "ambPergunta3", "ambPergunta5",
         "ambPergunta6", "ambPergunta7", "ambPergunta8", "ambPergunta9", "ambPergunta10",
         "ambPergunta11", "ambPergunta12", "ambPergunta13", "ambPergunta14", "indicacaoHospital"], ScoreMeter);
    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_EXAME], 
        ["exmPergunta1", "exmPergunta2", "exmPergunta3", "exmPergunta4", "exmPergunta5", 
         "exmPergunta6", "exmPergunta7", "exmPergunta8", "indicacaoHospital"], ScoreMeter);
    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_INTERNACAO], 
        ["intPerguntaA1", "intPerguntaA2", "intPerguntaA3", "intPerguntaA4",
         "intPerguntaB1", "intPerguntaB2", "intPerguntaB3", "intPerguntaB4", "intPerguntaB5",
         "intPerguntaC1", "intPerguntaC2", "intPerguntaC3", "intPerguntaC4",
         "indicacaoHospital"], ScoreMeter);
    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_TELEATENDIMENTO], 
        ["telePergunta1", "telePergunta2", "telePergunta3", "telePergunta5",
         "telePergunta6", "telePergunta7", "telePergunta8", "indicacaoHospital"], ScoreMeter);

    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_AMBULATORIAL], ["dataNascimento"], DateWithDatePicker);
    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_EXAME], ["dataNascimento"], DateWithDatePicker);
    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_INTERNACAO], ["dataNascimento"], DateWithDatePicker);
    setConfigFieldCustomComponent(formConfigs[FORM_CONFIG.PESQUISA_TELEATENDIMENTO], ["dataNascimento"], DateWithDatePicker);
}

const updateConfigDataNascimento = (config, user, anonymous, setField) => {
    const dataNascimento = user && user.dtNascimento ? user.dtNascimento : null;
    if(dataNascimento) { // Em teoria todos usuarios devem ter data de nascimento no cadastro
        setConfigFieldProperty(config, ["dataNascimento"], "datePicker.enabled", false);
        setConfigFieldProperty(config, ["dataNascimento"], "value", dataNascimento);
        setConfigFieldProperty(config, ["informarDataNascimento"], "value", !anonymous);
        setConfigFieldProperty(config, ["informarDataNascimento"], "disabled", !anonymous);
        setField("dataNascimento", { value: dataNascimento, disabled: true });
        setField("informarDataNascimento", { value: !anonymous, disabled: !anonymous });
    } else {
        setConfigFieldProperty(config, ["dataNascimento"], "datePicker.enabled", true);
        setConfigFieldProperty(config, ["dataNascimento"], "disabled", false);
        setConfigFieldProperty(config, ["dataNascimento"], "value", null);
        setConfigFieldProperty(config, ["informarDataNascimento"], "disabled", false);
        setConfigFieldProperty(config, ["informarDataNascimento"], "value", false);
        setField("dataNascimento", { value: null, disabled: false });
        setField("informarDataNascimento", { value: !anonymous, disabled: false });
    }
}

export {
    FORM_CONFIG,
    STEPS,
    buildConfigPesquisa,
    configureForms,
    updateConfigDataNascimento
}