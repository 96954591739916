/*
** @name: Meu Clínicas - wikiClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo wiki
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';


const API_PORT = utils.isDev() ? 8087 : null;
const API_SERVICE = utils.isDev() ? null : 'wiki-service';
const API_VERSION = 'v2';

class WikiClient {
    #apiRequest;

    constructor() {
        this.#apiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#apiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#apiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    news(extraAxiosConfig, options) {
        return this.#apiRequest.noAuth.get('/public/news', null, null, extraAxiosConfig, options);
    }

    newsGetAll(extraAxiosConfig, options) {
        return this.#apiRequest.noAuth.get('/public/news/all', null, null, extraAxiosConfig, options);
    }

    newsByLocation(exhibitLocation, extraAxiosConfig, options) {
        return this.#apiRequest.noAuth.get(`/public/news/${exhibitLocation}`, null, null, extraAxiosConfig, options);
    }

    newsBatchUpdate(jwtServiceToken, fingerprint, listUpdateItems, extraAxiosConfig, options) {
        const payload = { jwtServiceToken, fingerprint, listUpdateItems };
        return this.#apiRequest.noAuth.post('/public/news/batch-update', payload, null, extraAxiosConfig, options);
    }

    newsDelete(jwtServiceToken, fingerprint, id, extraAxiosConfig, options) {
        const payload = { jwtServiceToken, fingerprint, id };
        return this.#apiRequest.noAuth.post('/public/news/delete', payload, null, extraAxiosConfig, options);
    }

    newsSave(jwtServiceToken, fingerprint, news, extraAxiosConfig, options) {
        const payload = { jwtServiceToken, fingerprint, news };
        return this.#apiRequest.noAuth.post('/public/news/save', payload, null, extraAxiosConfig, options);
    }

    userFaq(questionId, allTags, tags, extraAxiosConfig, options) {
        const payload = { questionId, allTags: (allTags ? true : false), tags };
        return this.#apiRequest.noAuth.post('/public/faq', payload, null, extraAxiosConfig, options);
    }

    userFaqGetAll(questionId, allTags, tags, extraAxiosConfig, options) {
        const payload = { questionId, allTags: (allTags ? true : false), tags };
        return this.#apiRequest.noAuth.post('/public/faq/all', payload, null, extraAxiosConfig, options);
    }

    userFaqDelete(jwtServiceToken, fingerprint, id, extraAxiosConfig, options) {
        const payload = { jwtServiceToken, fingerprint, id };
        return this.#apiRequest.noAuth.post('/public/faq/delete', payload, null, extraAxiosConfig, options);
    }

    userFaqSave(jwtServiceToken, fingerprint, userFaq, extraAxiosConfig, options) {
        const payload = { jwtServiceToken, fingerprint, userFaq };
        return this.#apiRequest.noAuth.post('/public/faq/save', payload, null, extraAxiosConfig, options);
    }

    userFaqBatchUpdate(jwtServiceToken, fingerprint, listUpdateItems, extraAxiosConfig, options) {
        const payload = { jwtServiceToken, fingerprint, listUpdateItems };
        return this.#apiRequest.noAuth.post('/public/faq/batch-update', payload, null, extraAxiosConfig, options);
    }

}

const wikiClient = new WikiClient();
export default wikiClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};