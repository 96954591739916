/*
** @name: Meu Clínicas - aceiteTermosSessao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
**
** @update: Agosto 2024
** @description: Refatoramento para não usar mais 'Checkbox' de 'semantic-ui-react' e implmentar o mesmo manualmente por problemas
** após a migração para do React v16.X para v18.3.1
*/

import React, { useState, useEffect } from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';


const CheckboxField = (props) => {
    const { id, name, label, disabled, onToggle, checked, errorMessage, children, renderChild, hideChildren } = props;
    const [ isChecked, setChecked ] = useState(checked);
    const checkboxClassName = `${disabled ? 'disabled' : ''} ${checked ? ' checked' : ''}`.trim();

    useEffect(() => setChecked(checked), [checked]);

    return(
        <>
            <div className={`ui checkbox ${checkboxClassName}`.trim()} onClick={disabled ? () => {} : (e) => onToggle(e, {name: name, value: !checked})}>
                <input 
                    id={id} type="checkbox" checked={isChecked} className={disabled ? 'hidden' : ''} 
                    name={name} readOnly="" tabIndex="0" disabled={disabled} onChange={() => {}} />
                <label htmlFor={id}>{label}</label>
            </div>

            { errorMessage &&
            <div className="field-error">{errorMessage}</div>
            }

            { (!(hideChildren && !isChecked) && genesysUtils.typeCheck.isArray(children)) &&
                children.map((child, indxChild) => renderChild(child, indxChild))
            }

        </>
    )
}

export default CheckboxField;
