/*
** @name: Meu Clínicas - modalPdfDownloadError
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para renderizar modal com mensagem de erro no download do PDF
*/
import React from 'react';
import AppMessageDialog from '../../components/general/appMessageDialog/appMessageDialog.js';

const ModalPdfDownloadError = (props) => {
    const { onConfirm, message } = props;
    return(
        <AppMessageDialog
            wrapper={true}
            wrapperClassName="wrapper-download-error"
            className="download-error-box dialog-shadow"
            message={message}
            messageClass="message"
            buttonClass="btn-servicos btn-form-dispensacao btn-download-error"
            buttonOneText="Ok"
            buttonOneClick={onConfirm}
        />
    );
}


export default ModalPdfDownloadError;