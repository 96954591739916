/*
** @name: Meu Clínicas - appLoading
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render da modal indicativa de processando/carregando
*/

import React, {forwardRef, useImperativeHandle } from 'react';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../../core/appSpecificConfigHandler.js';

// Import module styles
import './appLoading.scss'; 


const APP_LOADING_ID = "appLoadingWrapperId";
const RE_HIDDEN = new RegExp('(\\s|^)hidden(\\s|$)');

const loadingTools = {
    get: (id) => document.getElementById(id ? id : APP_LOADING_ID),
    hide: (id) => {
        var elem = loadingTools.get(id);
        if(elem) {
            elem.className = elem.className.replace(RE_HIDDEN, ' ').trim();
            elem.className += " hidden";
        }
    },
    show: (id) => {
        var elem = loadingTools.get(id);
        if(elem) {
            elem.className = elem.className.replace(RE_HIDDEN, ' ').trim();
        }
    },
    isHidden: (id) => {
        var elem = loadingTools.get(id);
        if(elem) {
            return (elem.className || '').match(RE_HIDDEN) ? true : false;
        }
        return true;
    },
    isVisible: (id) => {
        return loadingTools.isHidden(id);
    }
}

const AppLoading = forwardRef(({ id, config, visible }, ref) => {
    useImperativeHandle(ref, () => ({
        hide: () => loadingTools.hide(),
        show: () => loadingTools.show(),
        isHidden: () => loadingTools.isHidden(),
        isVisible: () => loadingTools.isVisible()
    }));

    return(
        <div id={id || APP_LOADING_ID} className={`app-loading-wrapper${visible ? '' : ' hidden'}`}>
            <div className="loading-frame">
                <div className="loading-box">
                    <AppCustomImage imageContextFn={getImageThemeContext} 
                        config={config}
                        module="appLoading"
                        imageId="loading-indicator" 
                        className="loading"
                        disableException={true} />
                </div>
            </div>
        </div>
    )
});

export default AppLoading;
export {
    APP_LOADING_ID,
    loadingTools
}