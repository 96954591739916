/*
** @name: Meu Clínicas - midiaSalaEspera
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Componente para renderizar as midias na sala de espera
*/

import React from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import ImageCarousel from '../../components/general/imageCarousel/imageCarousel.js';


const MIDIA_AUTOPLAY_INTERVAL = 7000;

const MidiaSalaEspera = (props) => {
    const { data } = props;

    if(!genesysUtils.typeCheck.isArray(data)) {
        return null;
    }

    return(
        <div className="midia-box">
            { data.map((item, index) => {
                const { imagens, visivel } = item;
                if(!visivel) {
                    return null;
                }
                return(
                    <div key={`info_${index}`} className="info-carousel-block">
                        <ImageCarousel
                            keyBase={`infoCarousel_${index}`} 
                            interval={MIDIA_AUTOPLAY_INTERVAL}
                            imageList={imagens} />
                    </div> 
                )
            })}

        </div>
    );
}

export default MidiaSalaEspera;