/*
** @name: Meu Clínicas - corpoDocumento
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Maio 2024
** @description: Módulo para renderizar corpo de um documento de laudo/atestado
*/

import React from "react";

const Wrapper = (props) => <div className='body-content'>{props.children}</div>

const CorpoDocumento = props => {
    const { dados } = props;
    const { atestadoSigla } = dados || {};

    switch(atestadoSigla) {
        case "O": return (
            <Wrapper>
                <div className='body-title'>Atestado</div>
                {dados.observacao}
            </Wrapper>
        )
        default: return (
            <Wrapper>
                <div className='body-title'>Erro</div>
                Tipo de documento não previsto
            </Wrapper>
        )
    }
}

export default CorpoDocumento;