/*
** @name: Meu Clínicas - appCardModuleBasicWrapper
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2022
** @description: App module basic wrapper component
*/

import React, { useRef, useState, useEffect} from 'react';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';

import utils from '../../../core/utils.js';
import { useAppControllerContext } from '../../../core/appControllerContext.js';
import { isAppServiceEnabled } from '../../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../../core/appServiceList.js';

import AppMessageBox from '../appMessageBox/appMessageBox.js';
import { MODULE_HELP } from '../appController/appController.js';


const AppCardModuleBasicWrapper = (props) => {
    const { wrapperName } = props;
    const [ moduleEnabled, setModuleEnabled ] = useState(null);
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    const moduleName = useRef(null);
    const closeTimeoutId = useRef(null);

    const _getModuleName = () => {
        if(appControllerContext.methods.doGetHelpContext()) {
            return MODULE_HELP;
        }
        return appControllerContext.methods.doGetCurrentCardModule()?.moduleName;
    }

    const _handleClose = (module) => {
        appControllerContext.methods.doCardFadeOut(module);
    }

    const _verifyModule = () => {
        moduleName.current = _getModuleName();
        const serviceName = moduleName.current !== MODULE_HELP ? moduleName.current : APP_SERVICE_LIST.FAQ;
        if(isAppServiceEnabled(appContextConfig, serviceName, false)) {
            setModuleEnabled(true);
            return;
        }
        console.error(`[ERROR] Service '${moduleName.current}' not enabled.`);
        closeTimeoutId.current = utils.setAutomaticCardClose(appControllerContext, moduleName.current);
        setModuleEnabled(false);
    };

    useEffect(() => {
        _verifyModule();
        return () => {
            if(closeTimeoutId.current) {
                clearTimeout(closeTimeoutId.current);
            }
        }
    }, []); // eslint-disable-line

    return(
        <>
            { moduleEnabled===false &&
            <>
                <AppMessageBox
                    id="serviceDisabledMessageId" 
                    className="error"
                    messageData={{
                        header: "Ops!",
                        message: "Este módulo de serviço encontra-se desabilitado."
                    }} />

                <div className="main-action mt-1em">
                    <button id="buttonCloseId" type="button" className="app-form-button" onClick={() => _handleClose()}>Fechar</button>
                </div>
            </>
            }

            { moduleEnabled &&
            <div className={`${wrapperName}-wrapper`}>
                <div className="content-wrapper">
                    <div className="content-box">

                        { props.children }

                    </div>
                </div>
            </div>
            }
        </>
    );
}

export default AppCardModuleBasicWrapper;