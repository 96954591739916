/*
** @name: Meu Clínicas - appController
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente principal de controle de fluxo da aplicação. Controla chamada dos modulos nos 'cards',
** seu empilhamento, chamada da ajuda, e provem o contexto da controler 'appControllerContext' para interação dos módulos
**
** @date: Agosto 2021
** @description: Ajustes para permitir que um card não tenha botão (X) de fechar (só é fechado por codigo)
** @description: Refatorado para separa configuracao dos módulos em 'core module' específico
**
** @date: Novembro 2022
** @description: Refatorado para separa o componente que rendeiza o fluco principa da aplicação (cards, help...)
*/

import React, { Component, useRef } from 'react';
import $ from 'jquery';
import { loadAppReactNativeIntegration, removeAppReactNativeIntegration } from '@hcpa-react-components/app-react-native-integration';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';

import utils from '../../../core/utils.js';
import logout from '../../../core/logout.js';
import sessionStorageManager from '../../../core/sessionStorageManager.js';
import specialAccessManager from '../../../core/specialAccessManager.js';
import { getAppMobileSettings, getAppGeneralSettingsPropertyByName } from '../../../core/appSpecificConfigHandler.js';
import { AppServiceRouter } from '../../../core/appServiceRouter.js';
import { AppControllerContextProvider } from '../../../core/appControllerContext.js';
import { useAuthContext } from '../../../core/authContext.js';
import { getCardModuleConfig } from '../../../core/appCardModuleConfig.js';
import { APP_SERVICE_LIST } from '../../../core/appServiceList.js';
import { hasServicePermission, hideService } from '../appNavigationControls/appNavigationControls.js';

import AppLoading from '../appLoading/appLoading.js';
import ErrorPage from '../appErrorPage/appErrorPage.js';

import MainApp from './mainApp.js';
import { processTokenRefresh, verifyTokenRefresh } from './tokenUtils.js';

import configurationClient from '../../../apiClients/login/configurationClient.js';


// Import module styles
import './appController.scss'; 


const ESCAPE_KEY = 27;
const MODULE_HELP = "help";

let prevToken = null;
let tokenRefreshTimeoutId = null;

const clearRefreshTimeout = () => {
    if(tokenRefreshTimeoutId) {
        clearTimeout(tokenRefreshTimeoutId);
        tokenRefreshTimeoutId = null;
    }
}

const setRefreshTimeout = iid => { tokenRefreshTimeoutId = iid };

const loadUnloadRNIntegrationLibrary = (enabled) => {
    // Load/Remove App React Native Integration Library
    if(enabled) {
        loadAppReactNativeIntegration(true);
    } else {
        removeAppReactNativeIntegration();
    }
}

const AppController = (props) => {
    const authContext = useAuthContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    const enableRNIntegration = getAppMobileSettings(appContextConfig)?.enableReactNativeIntegration ? true : false;
    const integrationStatus = useRef(null);
    const isConfigurationCustomized = useAppConfigContext().isCustomized();
    const applyCustomization = useAppConfigContext().applyCustomizationToContextConfig;

    if(integrationStatus.current !== enableRNIntegration) {
        integrationStatus.current = enableRNIntegration;
        loadUnloadRNIntegrationLibrary(enableRNIntegration);
    }

    return(
        <AppControllerImplem 
            authContext={authContext}
            appContextConfig={appContextConfig}
            isConfigurationCustomized={isConfigurationCustomized}
            applyCustomization={applyCustomization}
            {...props}
        />
    )
}

class AppControllerImplem extends Component {
    constructor(props) {
        super(props);

        this.loadingRef = React.createRef();
        this.loadingVisibility = React.createRef();
        this.state = { 
            fadeOutCard: null,
            triggerLoginUpdate: false,
            loginAutoOpenIntegration: null,
            showLogoutConfirmation: null
        };
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            const authContext = this.props.authContext;
            if(authContext.methods.isAuthenticated()) {
                rnIntegration.backAction.set(() => {
                    this._logout();
                });
            } else {
                rnIntegration.backAction.set(() => { rnIntegration.triggerAppExit() });
            }
            if(this._hasVisibleCard()) {
                rnIntegration.backAction.push(() => { this._handleShortCutCardClose() });
            } else { // Force login update, changing a property, to set a specific back action
                this.setState({ triggerLoginUpdate: !this.state.triggerLoginUpdate });
            }
        }
    }

    _addCardModule = (serviceName, parameters, allowModuleRepetition, extraParams) => {
        switch(serviceName) {
            case APP_SERVICE_LIST.LOGOUT:
                this._logout();
                break;
            default:
                const { authContext, appContextConfig } = this.props;
                const user = authContext.properties.user || {};
                if(!hideService(user, appContextConfig, serviceName) && hasServicePermission(user, serviceName, extraParams)) {
                    sessionStorageManager.navigation.addCardModule(serviceName, parameters, allowModuleRepetition);
                    this._triggerRender();
                }
                break;
        }
    }

    _clearCardModule = () => {
        sessionStorageManager.navigation.clearCardModules();
        this.setState({ fadeOutCard: null });
    }

    _fadeOutCard = (module) => {
        let fadeOutCard = null;
        if((module && `${module}`.toLowerCase() === MODULE_HELP) || (!module && this._getHelpContext())) {
            fadeOutCard = MODULE_HELP;
        } else {
            const { moduleName } = this._getCurrentCardModule();
            fadeOutCard = module ? `${module}` : moduleName;
        }
        this.setState({ fadeOutCard: fadeOutCard });
    }

    _getCurrentCardModule() {
        return sessionStorageManager.navigation.getCurrentCardModule();
    }

    _getCardModuleList() {
        return sessionStorageManager.navigation.getCardModules();
    }

    _getHelpContext() {
        return sessionStorageManager.navigation.getHelpContext();
    }

    _handleAceiteTermos = (aceite) => {
        if(aceite) {
            specialAccessManager.permissions.setAceiteTermosSessao(this.props.authContext);
        } else { // Logout user (without any confirmation)
            this._handleLogoutConfirmed();
        }
    }

    _handleBeforeCardClose = (moduleName) => {
        return sessionStorageManager.navigation.runCurrentModuleBeforeClose(moduleName);
    }

    _handleCardClose = (moduleName) => {
        sessionStorageManager.navigation.removeCurrentCardModule(moduleName);
        this.setState({ fadeOutCard: null });
    }

    _handleHelpClose = () => {
        sessionStorageManager.navigation.removeHelpContext();
        this.setState({ fadeOutCard: null });
    }

    _handleKeyDown = (event) => {
        if(event.repeat || this.loadingRef.current?.isVisible()) {
            return;
        }

        if(event.keyCode===ESCAPE_KEY) {
            this._handleShortCutCardClose();
        }
    }

    _handleShortCutCardClose = () => {
        if(this._getHelpContext()) {
            this._fadeOutCard(MODULE_HELP);
        } else {
            const currentModuleName = this._getCurrentCardModule().moduleName;
            const currentModuleConfig = getCardModuleConfig(this.props.authContext, this.props.appContextConfig, currentModuleName);
            if(currentModuleConfig && !currentModuleConfig.disableCardClose && false!==this._handleBeforeCardClose(currentModuleName)) {
                this._fadeOutCard();
            }
        }
    }

    _hasVisibleCard = () => {
        return (this._getCardModuleList().length>0) || this._getHelpContext() ? true : false;
    }

    _hideLoading = () => {
        this.loadingRef.current?.hide();
    }

    _logout(force) {
        if(force) {
            this._handleLogoutConfirmed();
        } else {
            this.setState({ showLogoutConfirmation: true });
        }
    }

    _handleLogoutCanceled() {
        this.setState({ showLogoutConfirmation: null });
    }

    _handleLogoutConfirmed() {
        this._handleLogoutCanceled();
        logout(null, this.props.authContext, () => {  });
    }

    _removeCardModules = (listModuleName, forceFullRender=true) => {
        sessionStorageManager.navigation.removeCardModules(listModuleName);
        const { fadeOutCard } = this.state;
        const moduleList = genesysUtils.typeCheck.isArray(listModuleName) ? listModuleName : (genesysUtils.typeCheck.isString(listModuleName) ? [listModuleName] : []);
        if(genesysUtils.array.inArray(fadeOutCard, moduleList)) {
            this.setState({ fadeOutCard: null });
        }
        if(forceFullRender) {
            this._triggerRender();
        }
    }

    _retryLoadConfiguration = () => {
        configurationClient.asyncObterParametrosFrontend({}, {})
            .then(res => {
                if(!res?.data) {
                    throw Object.assign(new Error("[App Controller] Invalid app configuration response."), {
                        invalidConfigurationResponse: true 
                    });
                }

                // apply custom cofiguration to context configuration and trigger login
                this.props.applyCustomization(res.data);
                sessionStorageManager.customConfig.store(res.data);
            })
            .catch(err => { });
    }

    _setCardModule = (serviceName, parameters) => {
        sessionStorageManager.navigation.clearCardModules();
        this._addCardModule(serviceName, parameters, false);
    }

    _setCardModuleParameters = (serviceName, parameters) => {
        sessionStorageManager.navigation.setCardModuleParameters(serviceName, parameters);
    }

    _setEventOnBeforeCloseModule = (func, moduleName) => {
        sessionStorageManager.navigation.eventOnBeforeCloseModule(func, moduleName);
    }

    _setHelp = (helpContext, avoidTriggerRender) => {
        sessionStorageManager.navigation.setHelpContext(helpContext);
        if(!avoidTriggerRender)
            this._triggerRender();
    }

    _showLoading = () => {
        this.loadingRef.current?.show();
    }

    _triggerLoginIntegration = (autoOpenToken) => {
        this.setState({ loginAutoOpenIntegration: autoOpenToken ? autoOpenToken : false });
    }

    _triggerRender = () => {
        this.setState({ });
    }

    _triggerTokenRefresh = (authContext) => {
        processTokenRefresh(authContext);
    }

    _triggerTokenRefreshValidation = () => {
        const { authContext } = this.props;
        const { token } = authContext.properties;
        if(prevToken!==token) {
            clearRefreshTimeout();
            if(authContext.methods.isAuthenticated() || !token) {
                prevToken = token;
                if(token) {
                    verifyTokenRefresh(authContext, setRefreshTimeout);
                }
            }
        }
    }

    _usageUpdate = () => {
        if(this.props.authContext.methods.isAuthenticated()) {
            sessionStorageManager.usageTrack.setUsageTime();
        }
        this._triggerTokenRefreshValidation();
    }

    componentDidMount() {
        $.fn.bindFirst = function(name, fn) {
            var elem, handlers, i, _len;
            this.on(name, fn);
            for (i = 0, _len = this.length; i < _len; i++) {
                elem = this[i];
                handlers = $._data(elem).events[name.split('.')[0]];
                handlers.unshift(handlers.pop());
            }
        };

        $(window).bindFirst('keydown', this._handleKeyDown);
        $(window).on("load", this._appSetBackAction);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isLoadingVisible = this.loadingRef.current?.isVisible();
        if(prevState.triggerLoginUpdate===this.state.triggerLoginUpdate &&
            isLoadingVisible===this.loadingVisibility.current) { // avoid recursive render to reset app back Action
            this._appSetBackAction();
        }
        this.loadingVisibility.current = isLoadingVisible;
        this._usageUpdate();
    }

    componentWillUnmount() {
        $(window).off("keydown", this._handleKeyDown);
        $(window).off("load", this._appSetBackAction);
        clearRefreshTimeout();
    }

    render() {
        const { isConfigurationCustomized, appContextConfig } = this.props;
        const { fadeOutCard, triggerLoginUpdate, showLogoutConfirmation, loginAutoOpenIntegration } = this.state;
        const pathName = (window.location.pathname || "").replace(/\/+$/g, '') || '/';
        const isMainApp = (pathName === utils.getPublicPath()) || loginAutoOpenIntegration!==null;
        const mobileOS = genesysUtils.deviceCheck.isAndroid() ? " android" : (genesysUtils.deviceCheck.isIOS() ? " ios" : "");
        const deviceClass = (genesysUtils.deviceCheck.isDesktop() ? "desktop" : `mobile${mobileOS}`);
        const verifyConfigCustomization = getAppGeneralSettingsPropertyByName(appContextConfig, "verifyConfigCustomizationOnController");

        return(
            <div className={`app-viewport ${deviceClass}${(isMainApp || pathName.includes("/wifi/")) ? "" : " external-service"}`}>
                <AppControllerContextProvider 
                    onLogout={force => this._logout(force)}
                    onCardFadeOut={this._fadeOutCard}
                    onAddCardModule={this._addCardModule}
                    onClearCardModule={this._clearCardModule}
                    onGetCurrentCardModule={this._getCurrentCardModule}
                    onGetHelpContext={this._getHelpContext}
                    onSetCardModule={this._setCardModule}
                    onSetHelp={this._setHelp}
                    onHideLoading={this._hideLoading}
                    onShowLoading={this._showLoading}
                    onTriggerLoginIntegration={this._triggerLoginIntegration}
                    onTriggerRender={this._triggerRender}
                    onRemoveCardModules={this._removeCardModules}
                    onResetAppBackAction={this._appSetBackAction}
                    onSetCardModuleParameters={this._setCardModuleParameters}
                    onSetEventOnBeforeCloseModule={this._setEventOnBeforeCloseModule}
                    onUsageUpdate={this._usageUpdate}
                    onTokenRefresh={this._triggerTokenRefresh}
                >
                    { (!verifyConfigCustomization || isConfigurationCustomized) ?
                    <>
                        { isMainApp ? 
                        <MainApp 
                            cardModuleList={this._getCardModuleList()}
                            helpContext={this._getHelpContext()}
                            cardFadingOut={fadeOutCard}
                            showLogoutConfirmation={showLogoutConfirmation}
                            loginAutoOpenIntegration={loginAutoOpenIntegration}
                            loginUpdateControl={triggerLoginUpdate}
                            onLogout={this._handleLogoutConfirmed.bind(this)}
                            onLogoutCanceled={this._handleLogoutCanceled.bind(this)}
                            onAceiteTermos={this._handleAceiteTermos.bind(this)}
                            onBeforeCardClose={this._handleBeforeCardClose.bind(this)}
                            onCardClose={this._handleCardClose.bind(this)}
                            onHelpClose={this._handleHelpClose.bind(this)}
                        />
                        : 
                        <AppServiceRouter /> 
                        }
                    </>
                    :
                    <ErrorPage appConfiguration={appContextConfig} page="missing-configuration" onReload={this._retryLoadConfiguration} />
                    }
                    
                    <AppLoading ref={this.loadingRef} config={appContextConfig} visible={false} />

                </AppControllerContextProvider>
            </div>
        );
    }
}

export default AppController;
export {
    MODULE_HELP
}
