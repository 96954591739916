/*
** @name: Meu Clínicas - adminMenu
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2022
** @description: Módulo para exibição das funcionalidades administrativa
** 
*/

import React from 'react';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';


// Import module styles
import './adminMenu.scss';


const AdminMenu = (props) => {
    const baseAdminURL= process.env.REACT_APP_BASE_ADMIN_ADDRESS.replace(/\/+$/, "");

    return(
        <div className="admin-menu-wrapper">
            <AppExtraDocumentHead subTitle="Área Administrativa" robots="noindex,nofollow" />

            <div className="content">
                <div className="header-card">
                    <AppExternalServiceHeader linkToHome={false}>
                        <h1>Área Administrativa</h1>
                    </AppExternalServiceHeader>
                </div>

                <div className="content-section">
                    <div className="section-header-wrapper">
                        <h2>Itens Administrativos:</h2>
                    </div>

                    <div className="links-wrapper">
                        <div className="item-link">
                            <a href={`${baseAdminURL}/dashboard`} rel="noopener noreferrer" target="_blank">Dashboard</a>
                        </div>
                        <div className="item-link">
                            <a href={`${baseAdminURL}/faq-manager`} rel="noopener noreferrer" target="_blank">FAQ Manager</a>
                        </div>
                        <div className="item-link">
                            <a href={`${baseAdminURL}/news-manager`} rel="noopener noreferrer" target="_blank">News Manager</a>
                        </div>
                        <div className="item-link">
                            <a href={`${baseAdminURL}/pesquisa-experiencia`} rel="noopener noreferrer" target="_blank">Pesquisa de Experiência - Exportação</a>
                        </div>
                        <div className="item-link">
                            <a href={`${baseAdminURL}/solicitacao-agendamento-consulta`} rel="noopener noreferrer" target="_blank">Solicitação de Agendamento de Consulta</a>
                        </div>
                        <div className="item-link">
                            <a href={`${baseAdminURL}/integracao-login`} rel="noopener noreferrer" target="_blank">Gerador Token de Integração no Login</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AdminMenu;