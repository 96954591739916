/*
** @name: Meu Clínicas - appConfirmationDialog
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente 'wrapper' para o 'appMessageDialog' para criar uma caixa de diálogo de confirmacao com 
** 1 (Ok) ou 2 (Ok + Cancelar) botões
*/

import React from 'react';

import AppMessageDialog from '../appMessageDialog/appMessageDialog.js';


// Import module styles
import './appConfirmationDialog.scss'; 


const CustomWrapper = (props) => {
    return(
        <div id="confirmationDlgWrapperId">
            {props.children}
        </div>
    );
}

const AppConfirmationDialog = (props) => {
    const { title, titleClass, subtitle, subtitleClass, message, hideCancelButton,
            messageClass, buttonClass, onConfirm, onCancel } = props;
    return(
        <AppMessageDialog
            wrapper={CustomWrapper}
            header={ 
                <div className={titleClass}>
                    { title ? title : "Confirmação" }
                    <div className="btn-close-wrapper" onClick={onCancel}>
                        <div className="close"></div>
                    </div>
                </div>
            }
            className="confirmation-box"
            title={subtitle}
            titleClass={subtitleClass}
            message={message}
            messageClass={`message${messageClass ? ` ${messageClass}` : ""}`}
            buttonClass={`app-confirmation-button${buttonClass ? ` ${buttonClass}` : ""}`}
            buttonOneText="Ok"
            buttonTwoText="Cancelar"
            buttonOneClick={onConfirm}
            buttonTwoClick={!hideCancelButton ? onCancel : null}
        />
    );
}

export default AppConfirmationDialog;