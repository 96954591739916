/*
** @name: Meu Clínicas - requerimentoDocumentosProntuario
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para requerimento de documentos de prontuários
**
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizando tratamento do retorno de erro para novo sistema de validacao
*/

import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import pt_BR from "date-fns/locale/pt-BR";
import { pascalCase } from '@hcpa-react-components/string-utils';
import { useAppConfigContext, AppCustomMessage, AppCustomLink } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getAppServiceSettingsByName } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import { CheckboxField, DateWithDatePicker, InputField } from '../../components/fields/formsBuilderCustoms/';

import { ActionButtonFormReqDoc, MensagemErroInicializacaoFormReqDoc, MensagemSucessoReqDoc } from './messages.js';

// Import node modules styles
import "react-datepicker/dist/react-datepicker.css";

// Import module styles
import './requerimentoDocumentosProntuario.scss'; 


const FORM_CONFIG = {
    MAIN: "reqDocProntuario"
}
const ERROR_CLOSE_TIMEOUT = 7000;

let autoCloseTimeoutId = null;

const RequerimentoDocumentosProntuario = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <RequerimentoDocumentosProntuarioImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class RequerimentoDocumentosProntuarioImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formConfiguration: null,
            fields: null,
            showConfirmation: false,
            resultadoRegistro: null,
            erroRegistro: null,
            initializationError: false
        };
    }

    _buildRequest = () => {
        const user = this.props.authContext.properties.user;
        const fields = this.state.fields;
        if(!user || !fields || fields.constructor!==Object) {
            return;
        }

        const request = {};
        request["pacCodigo"] = user.pacCodigo;
        request["prontuarioPaciente"] = user.prontuario;
        request["nomePaciente"] = pascalCase(user.nome);
        request["emailPaciente"] = user.email;
        request["telefonePaciente"] = user.celular;
        request["respostas"] = {};
        if(fields && fields.constructor === Object) {
            Object.keys(fields).forEach((key) => {
                let value = fields[key].value;
                if(/^.*(De|Ate)$/.test(key)) { // campos de data (terminados em 'De' ou 'Ate')
                    const date = utils.stringToDateBR(value);
                    value = date ? date.getTime() : null;
                }
                if(value!==null) {
                    request.respostas[key] = value;
                }
            });
        }
        return request;
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(genesysUtils.typeCheck.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _getConfirmationMessage = () => {
        const user = this.props.authContext.properties.user;
        const email = user.email;

        return(
            <AppCustomMessage 
                messageId="req-doc-prontuario_confirmacao"
                missingParameter={undefined}
                params={[
                    <strong key={`comp_1`}>{email}</strong>,
                    <AppCustomLink linkName="faq-contato-telefone" className="phone-number" />
                ]} 
            />
        );
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }   

    _handleConfirmationOk = () => {
        this._showConfirmacaoEnvio(false);
        this._registrarRequerimento();
    }

    _handleConfirmationCancel = () => {
        this._showConfirmacaoEnvio(false);
    }

    _handleEnviar = () => {
        this._showConfirmacaoEnvio(true);
    }

    _initilize = () => {
        const reqConfig = Object.values(FORM_CONFIG).sort();
        formulariosClient.obterConfiguracoesFormularios(reqConfig, {}, {})
            .then(res => {              
                if(genesysUtils.typeCheck.isObject(res.data)) {
                    const keys = Object.keys(res.data).sort();
                    if(utils.arrayCompare(keys, reqConfig)) {
                        setConfigFieldProperty(res.data[FORM_CONFIG.MAIN], [
                            "examesAmbulatoriaisDe", "examesAmbulatoriaisAte", "examesInternacaoDe", "examesInternacaoAte",
                            "internacaoCompletoDe", "internacaoCompletoAte", "declaracaoInternacaoDe", "declaracaoInternacaoAte",
                            "declaracaoRecemNascidoDe", "declaracaoRecemNascidoAte", "declaracaoObitoDe", "declaracaoObitoAte" ], "datePicker.locale", pt_BR);
                        this.setState({
                            formConfiguration: res.data[FORM_CONFIG.MAIN]
                        });
                        return;
                    }
                }
                throw new Error("[Configuration-RDP] Invalid configuration response.");
            })
            .catch(err => { 
                this.setState({ initializationError: true });
                console.error("Error:", err);
            });
    }

    _processaErrosFormulario = (erros) => {
        if(erros && erros.constructor === Object) {
            const updateFields = this.state.fields;
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    let message = erros[key];
                    if(!updateFields[key]) {
                        updateFields[key] = {
                            value: null,
                            errorMessage: null
                        }
                    }
                    updateFields[key].errorMessage = message;
                }
            });
            this.setState({
                fields: updateFields
            });
        }
    }

    _registrarRequerimento = () => {
        this._setErroRegistro(null);
        this._clearFieldsError();

        const request = this._buildRequest();
        formulariosClient.requerimentoDocumentosProntuario(request, {}, {})
            .then(res => {              
                const result = res.data;
                this.setState({ resultadoRegistro: result });

                if(!result.valid) {
                    const validatorResponse = result.validatorResponse || {};
                    if(validatorResponse.errors && validatorResponse.errors.erroGeral) {
                        this._setErroRegistro(validatorResponse.errors.erroGeral);
                    } else {
                        this._setErroRegistro("Por favor, verifique o correto preenchimento do formulário.");
                    }
                    this._processaErrosFormulario(validatorResponse.errors);
                }
            })
            .catch(err => { 
                this._setErroRegistro("Ops!, ocorreu um erro registrando sua solicitação.");
            });
    }

    _setErroRegistro = (msg) => {
        this.setState({ erroRegistro: msg });
    }

    _showConfirmacaoEnvio = (show) => this.setState({ showConfirmation: show });

    componentDidMount() {
        this._initilize();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.initializationError && !prevState.initializationError) {
            autoCloseTimeoutId = setTimeout(() => this._handleClose(), ERROR_CLOSE_TIMEOUT);
        }
    }

    componentWillUnmount() {
        if(autoCloseTimeoutId) {
            clearTimeout(autoCloseTimeoutId);
        }
    }

    render() {
        const { initializationError, showConfirmation, resultadoRegistro, erroRegistro, formConfiguration, fields } = this.state;
        const user = this.props.authContext.properties.user;
        const nomePaciente = pascalCase(user.nome);
        const emailPaciente = user.email;
        const telefonePaciente = user.celular;
        const exibeMensagemSucesso = resultadoRegistro && resultadoRegistro.valid;
        const registroProtocolo = exibeMensagemSucesso ? utils.formatGuidProtocol(resultadoRegistro.protocolo) : null;
        const serviceConfig = getAppServiceSettingsByName(this.props.appContextConfig, APP_SERVICE_LIST.REQUERIMENTO_DOCUMENTOS_PRONTUARIO);
        const { emailAtendimento } = serviceConfig || {};
        const exibeConteudoModulo = !initializationError && formConfiguration && !exibeMensagemSucesso;

        return(
            <AppCardModuleBasicWrapper wrapperName="requerimento-documentos-prontuario">

                { initializationError && <MensagemErroInicializacaoFormReqDoc onClick={this._handleClose} /> }

                { showConfirmation && 
                <div className="confirmacao-envio-wrapper">
                    <AppConfirmationDialog
                        title="Confirmação"
                        subtitle="Atenção"
                        subtitleClass="confirm-subtitle"
                        message={this._getConfirmationMessage()}
                        messageClass="confirm-message"
                        onConfirm={this._handleConfirmationOk}
                        onCancel={this._handleConfirmationCancel} />
                </div>
                }

                { exibeMensagemSucesso &&
                <MensagemSucessoReqDoc
                    emailAtendimento={emailAtendimento}
                    emailPaciente={emailPaciente}
                    registroProtocolo={registroProtocolo}
                    registradoEm={resultadoRegistro.criadoEm}
                    onClick={this._handleClose} />
                }

                { exibeConteudoModulo &&
                <>
                    <div className="user-identification-section">
                        <div className="section-box">
                            <div className="section-title">Identificação do paciente</div>
                            <div className="section-content">
                                <div>
                                    <div className="titulo">Nome completo:</div>
                                    <div className="descricao">{nomePaciente}</div>
                                </div>
                                <div>
                                    <div className="titulo">Endereço de e-mail:</div>
                                    <div className="descricao">{emailPaciente}</div>
                                </div>
                                <div>
                                    <div className="titulo">Telefone:</div>
                                    <div className="descricao">{telefonePaciente}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-section">
                        <div className="section-box">
                            <div className="section-title">Requerimento</div>
                            <div className="section-content">
                                <div className="subtitle">Assinale abaixo o(s) documentos que deseja requerer. Ao menos um documento deve ser selecionado e, caso solicitado, ter o período informado.</div>
                                <div className="form-wrapper">
                                    <Form name="formMain">
                                        <FormBuilder 
                                            blockFieldUpdate={false}
                                            disableClearErrorOnFieldChange={false}
                                            config={formConfiguration}
                                            fields={fields}
                                            page={0}
                                            className="form-content" 
                                            onChange={(fields) => { this.setState({ fields: fields })}}
                                            overrideFieldRender={{
                                                'checkbox': CheckboxField,
                                                'input': InputField
                                            }}
                                            customComponents={{
                                                examesAmbulatoriaisDe: DateWithDatePicker,
                                                examesAmbulatoriaisAte: DateWithDatePicker,
                                                examesInternacaoDe: DateWithDatePicker,
                                                examesInternacaoAte: DateWithDatePicker,
                                                internacaoCompletoDe: DateWithDatePicker,
                                                internacaoCompletoAte: DateWithDatePicker,
                                                declaracaoInternacaoDe: DateWithDatePicker,
                                                declaracaoInternacaoAte: DateWithDatePicker,
                                                declaracaoRecemNascidoDe: DateWithDatePicker,
                                                declaracaoRecemNascidoAte: DateWithDatePicker,
                                                declaracaoObitoDe: DateWithDatePicker,
                                                declaracaoObitoAte: DateWithDatePicker
                                            }} />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    { erroRegistro &&
                    <AppMessageBox
                        id="msg-error"
                        className="error"
                        messageData={{ "message": erroRegistro }} />
                    }

                    <ActionButtonFormReqDoc id="rdpBtnEnviar" text="Enviar" onClick={this._handleEnviar} />
                </>
                }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default RequerimentoDocumentosProntuario;