/*
** @name: Meu Clínicas - receitaView
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Maio 2024
** @description: Módulo para renderizar bloco de QRCode para visualizadores
*/

import React from 'react';

import QRCode  from 'qrcode.react';

import CamshotFrame from '../camshotFrame/camshotFrame.js';

const QRCodeBlock = (props) => {
    const { keyName, frameColor, frameThickness, size, value, enableToolTip, onClick } = props;
    const qrCode = <QRCode renderAs="canvas" size={size} includeMargin={true} fgColor={"#000000"} value={value} />;
    
    return(
        <div id="qrcodeBlockID" 
        className={`qrcode-block${(enableToolTip ? ' pointer' : '')}`}
        onClick={(enableToolTip && onClick ? () => onClick() : null)}>
        <CamshotFrame
            key={keyName}
            fitContentSize={false}
            height={size}
            width={size}
            color={frameColor}
            thickness={frameThickness}
            overflow="hidden">
            {qrCode}
        </CamshotFrame>
    </div>
);
}

export default QRCodeBlock;