/*
** @name: Meu Clínicas - modalTermos
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar modal com termos de uso para o cadastro
**
** @update: Agosto 2024
** @description: Refatoramento para passar os termos para estrutura de mensagens
*/

import React from 'react';


import { AppCustomImage, AppCustomMessage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';
import AppModal from '../../components/general/appModal/appModal.js';


const ModalTermos = (props) => {
    const { onClose } = props;

    return(
        <div id="termosModalWrapperId">
            <AppModal
                closeOnEsc={true}
                closeOnClickOutside={true}
                onClose={onClose}
                headerContent={
                    <div className="modal-header-wrapper">
                        <div className="close-button" onClick={onClose}>
                            <AppCustomImage imageContextFn={getImageThemeContext} module="appCard" imageId="normal-icon_back" />
                        </div>
                    </div>
                }
            >
                <div className="termos-content-wrapper">
                    <div className="termos">
                        <AppCustomMessage elemType="html" messageId="_general_termos-aceite" />
                    </div>
                </div>
            </AppModal>
        </div>
    );
}

export default ModalTermos;