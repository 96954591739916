/*
** @name: Meu Clínicas - buildInfo
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Módulo para criar promises que buscam informações de build dos serviços
*/

import { DISABLE_LOADING } from '../../core/appRequest.js';

import consultasClient from '../../apiClients/consultas/consultasClient.js';
import examesClient from '../../apiClients/exames/examesClient.js';
import formulariosClient from '../../apiClients/formularios/formulariosClient.js';
import loginClient from '../../apiClients/login/loginClient.js';
import notificationClient from '../../apiClients/notification/notificationClient.js';
import receitasClient from '../../apiClients/receitas/receitasClient.js';
import registrosMedicosClient from '../../apiClients/registrosMedicos/registrosMedicosClient.js';
import wikiClient from '../../apiClients/wiki/wikiClient.js';


const AXIOS_TIMEOUT = { timeout: 5000 }

const _logError = (error, module) => {
    console.warn('Erro ao buscar a informação de build: ' + module, JSON.stringify(error));
}

const consultasBuildInfo = () => new Promise(resolve => {
    consultasClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                consultas: {
                    title: "Consultas",
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/consultas-service',
                    order: 21,
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'consultas')
            resolve({});
        });
});


const examesBuildInfo = () => new Promise(async resolve => {
    examesClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                exames: {
                    title: "Exames",
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/exames-service',
                    order: 22,
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'exames')
            resolve({});
        });
});

const formulariosBuildInfo = () => new Promise(async resolve => {
    formulariosClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                formularios: {
                    title: "Formularios",
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/formularios-service',
                    order: 23,
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'formularios')
            resolve({});
        });
});

const loginBuildInfo = () => new Promise(async resolve => {
    loginClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                login: {
                    title: "Login",
                    order: 24,
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/login-service',
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'login')
            resolve({});
        });
});

const notificationBuildInfo = () => new Promise(async resolve => {
    notificationClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                notification: {
                    title: "Notification",
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/notification-service',
                    order: 25,
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'notification')
            resolve({});
        });
});

const receitasBuildInfo = () => new Promise(async resolve => {
    receitasClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                receitas: {
                    title: "Receitas",
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/receitas-service',
                    order: 26,
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'receitas')
            resolve({});
        });
});

const regmedicosBuildInfo = () => new Promise(async resolve => {
    registrosMedicosClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                regmedicos: {
                    title: "Registros Médicos",
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/regmedicos-service',
                    order: 27,
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'regmedicos')
            resolve({});
        });
});

const wikiBuildInfo = () => new Promise(async resolve => {
    wikiClient.buildInfo(AXIOS_TIMEOUT, DISABLE_LOADING)
        .then(res => {
            resolve({
                wiki: {
                    title: "Wiki",
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/wiki-service',
                    order: 28,
                    active: false,
                    data: res.data.buildInfo
                }
            })
        })
        .catch(err => {
            _logError(err, 'wiki')
            resolve({});
        });
});

export {
    consultasBuildInfo,
    examesBuildInfo,
    formulariosBuildInfo,
    loginBuildInfo,
    notificationBuildInfo,
    receitasBuildInfo,
    regmedicosBuildInfo,
    wikiBuildInfo
}