/*
** @name: Meu Clínicas - sectionAnexo
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Componente para renderizar seção de anexo na listagem da tela de exames
*/

import React, { Fragment } from 'react';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from "@hcpa-react-components/genesys-utils";

import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';


const SectionAnexos = (props) => {
    const { groupIndex, resultados, checkStatusNovo, onView, onDownloadPdf } = props;

    if(!genesysUtils.typeCheck.isArray(resultados)) {
        return <></>;
    }

    return(
        <>
            {resultados.map((exame, idx) => {
                const isResultadoContemAnexo = exame.idArmazenamentoAnexo !== null;
                const indicativoNovoAnexo = checkStatusNovo(exame.statusLeituraAnexo);
                if (isResultadoContemAnexo) {
                    return (
                        <Fragment key={`anexoId_${groupIndex}-${idx}`}>
                            <div className={`content-row${indicativoNovoAnexo ? " novo" : ""}`}>
                                <div className="titulo-anexo">ANEXO</div>
                            </div>
                            <div className={`content-row${indicativoNovoAnexo ? " novo" : ""}`}>
                                <div className="resultados-wrapper">
                                    <div className="buttons-wrapper">
                                        <button
                                            type="button"
                                            className="btn-visualizar"
                                            title="Visualizar anexo"
                                            onClick={() => onView(exame)}>
                                            <AppCustomImage imageContextFn={getImageThemeContext}
                                                module="exames"
                                                imageId={`icon_botao-visualizar${indicativoNovoAnexo ? "-new" : ""}`}
                                                className="img-buttomimg" />
                                        </button>

                                        <button
                                            type="button"
                                            className="btn-baixar-pdf"
                                            title="Baixar anexo"
                                            onClick={() => onDownloadPdf(exame)}>
                                            <AppCustomImage imageContextFn={getImageThemeContext}
                                                module="exames"
                                                imageId={`icon_botao-pdf${indicativoNovoAnexo ? "-new" : ""}`}
                                                className="img-buttomimg" />
                                        </button>

                                    </div>
                                </div>
                            </div>

                            {exame.downloadErrorAnexo &&
                            <div className="content-row">
                                <div className="download-error">{exame.downloadErrorAnexo}</div>
                            </div>
                            }
                        </Fragment>
                    );
                }                                             
                return null;

            })}
        </>
    );
}

export default SectionAnexos;