/*
** @name: Meu Clínicas - viewerModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2020
** @description: Componente para render de uma moda de visualizacao de documentos (Exames, Receitas, ...) com rolagem
** e possibilidade customizar cabeçalho e rodapé
*/

import React, { Component } from 'react';


// Import module styles
import './viewerModal.scss';


class ViewerModal extends Component {

    constructor(props) {
        super(props);
       
        const onClose = props.onCloseButton ? props.onCloseButton : () => { console.warn('there is no function associated to close button.') }
        this.state = {
            header: props.header,
            footer: props.footer,
            title: props.title,
            children: props.children,
            onCloseButton: onClose
        }
    }

    RenderHeader = () => {
        return(
            this.state.header 
            ?
            <div className="vw-modal-header_custom">
                { this.state.header }
            </div>
            :
            <div className="vw-modal-header">
                <div className="vw-modal-header-content">{this.state.title}</div>
                <div className="vw-modal-close" onClick={() => this.state.onCloseButton()} >
                    <div className="btn-close-wrapper" onClick={() => this.state.onCloseButton()} >
                        <div className="close"></div>
                    </div>
                </div>
            </div>
        );
    }

    RenderFooter = () => {
        return(
            this.state.footer
            ?
            <div className="vw-modal-footer_custom">
                { this.state.footer }
            </div>
            :
            <div className="vw-modal-footer">
                { null }
            </div>
        );
    }

    render() {
        return(
            <div id="viwerModalId">
                <div className="vw-modal-wrapper">
                    { this.RenderHeader() }
                    <div className="vw-modal-body">
                        <div className="vw-modal-body-content">
                            { this.state.children }
                        </div>
                    </div>
                    { this.RenderFooter() }
                </div>
            </div>
        );
    }

} export default ViewerModal;
