/*
** @name: Meu Clínicas - sectionResultados
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2024
** @description: Componente para renderizar seção de resultados na listagem da tela de exames
*/

import React, { Fragment } from 'react';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from "@hcpa-react-components/genesys-utils";

import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';


const SectionResultados = (props) => {
    const { groupIndex, resultados, exibirTicketSolicitacao, existeResultadoNovo, existeExameNaoLiberado, checkStatusNovo, onView, onDownloadPdf } = props;

    if(!genesysUtils.typeCheck.isArray(resultados)) {
        return <></>;
    }

    return(
        <>
            <div className={`content-row${existeResultadoNovo ? " novo" : ""}`}>
                <div className="titulo-resultados">RESULTADOS</div>
            </div>

            { resultados.map((exame, index) => {
                const indicativoNovoResult = checkStatusNovo(exame.statusLeituraResultado);
                return(
                    <Fragment key={`exameId_${groupIndex}-${index}`}>
                        <div className={`content-row${indicativoNovoResult ? " novo" : ""}`}>
                            <div className="resultados-wrapper">
                                <div className="buttons-wrapper">
                                    <button 
                                        type="button"
                                        className="btn-visualizar"
                                        title="Visualizar resultado"
                                        onClick={() => onView(exame)}>
                                        <AppCustomImage imageContextFn={getImageThemeContext} 
                                            module="exames"
                                            imageId={`icon_botao-visualizar${indicativoNovoResult ? "-new" : ""}`}
                                            className="img-buttomimg" />

                                    </button>                                               
                                    <button 
                                        type="button"
                                        className="btn-baixar-pdf"
                                        title="Baixar arquivo do resultado"
                                        onClick={() => onDownloadPdf(exame)}>
                                        <AppCustomImage imageContextFn={getImageThemeContext}
                                            module="exames"
                                            imageId={`icon_botao-pdf${indicativoNovoResult ? "-new" : ""}`}
                                            className="img-buttomimg" />
                                    </button>
                                </div>   
                                <div className="info-wrapper">
                                    <div className="tipo-exame">
                                        <span className="bold-text">Tipo de exame:</span><br/>
                                        <span>{exame.nomeGrupoExame}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        { exame.downloadError &&
                        <div className="content-row">
                            <div className="download-error">{exame.downloadError}</div>
                        </div>
                        }
                    </Fragment>
                );
            })}

            { (exibirTicketSolicitacao && existeExameNaoLiberado) &&
                <div className={`content-row mensagem-resultado-pedente${existeResultadoNovo ? " novo" : ""}`}>* existem outros exames ainda sem resultado</div>
            }
        </>
    );
}

export default SectionResultados;