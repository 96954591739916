import React from "react";
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';


const GridIcon = (props) => {
    const { visible, className, title, imageId, onClick } = props;
    if(!visible) {
        return null;
    }
    const clickFunc = genesysUtils.typeCheck.isFunction(onClick) ? onClick : () => {};
    return(
        <div className={className} title={title} onClick={clickFunc}>
            <AppCustomImage imageContextFn={getImageThemeContext} module="faq-manager" imageId={imageId} onErrorReturn="N/A" />
        </div>
    );
}

export default GridIcon;