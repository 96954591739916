/*
** @name: Meu Clínicas - appBooking
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para criar grade de agendamento com navegação e permitir escolha de uma data horário
*/

import React, { Component, Fragment } from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../../core/appSpecificConfigHandler.js';


// Import module styles
import './appBooking.scss'; 


const DAYS_OF_WEEK = ['DOM','SEG','TER','QUA','QUI','SEX','SAB'];

const getDayOfWeek = (year, month, day) => {
    const date = new Date(year, month-1, day);
    return isNaN(date.getTime()) ? "ERR" : DAYS_OF_WEEK[date.getDay()];
}

const isSelected = (key, selected) => {
    return (selected && genesysUtils.typeCheck.isArray(selected) && genesysUtils.array.inArray(key, selected));
}

const DayColumn = (props) => {
    const { className, dayData, selected, width, onDayClick, onHourClick } = props;
    const { year, month, day, schedules } = dayData;
    const dayOfWeek = dayData.dayOfWeek || getDayOfWeek(year, month, day);
    const hide = !year || !month || !day;
    const dayMonth = `0${day}`.slice(-2) + "/" + `0${month}`.slice(-2);
    const dayKey = dayData.key || `0000${year}`.slice(-4) + `0${month}`.slice(-2) + `0${day}`.slice(-2);
    const isDaySelected = isSelected(dayKey, selected);
    const extraDayClass = dayData.className ? ` ${dayData.className}` : "";
    const colStyle = { width: width };

    return(
        <>
            { hide ?
            <div className="grid-day-empty" style={colStyle}></div>
            :
            <div 
                className={`grid-day-column${className ? ` ${className}` : ""}`} 
                style={colStyle}
            >
                <div 
                    className={`date${onDayClick ? " clickable" : ""}${isDaySelected ? " selected" : ""}${extraDayClass}`} 
                    onClick={onDayClick ? () => onDayClick({dayKey}) : null}
                >
                    <div>{dayOfWeek}</div>
                    <div>{dayMonth}</div>
                </div>
                { schedules && genesysUtils.typeCheck.isArray(schedules) && schedules.map((schedule, indx) => {
                    const { hour, minute } = schedule;
                    if(!genesysUtils.typeCheck.isInteger(hour) || !genesysUtils.typeCheck.isInteger(minute) || hour<0 || hour>23 || minute<0 || minute>59) {
                        return null;
                    }
                    const text = `0${hour}`.slice(-2) + ":" + `0${minute}`.slice(-2);
                    const hourKey = schedule.key || `0${hour}`.slice(-2) + `0${minute}`.slice(-2);
                    const isHourSelected = isSelected(hourKey, selected);
                    const extraHourClass = schedule.className ? ` ${schedule.className}` : "";

                    return(
                        <Fragment key={`hr_${indx}`}>
                            { (genesysUtils.typeCheck.isInteger(hour) && genesysUtils.typeCheck.isInteger(minute)) &&
                            <div
                                className={`hour${onHourClick ? " clickable" : ""}${isHourSelected ? " selected" : ""}${extraHourClass}`} 
                                onClick={onHourClick ? () => onHourClick({dayKey, hourKey}) : null}
                            >
                                {text}
                            </div>
                            }
                        </Fragment>
                    )
                }) }
            </div>
            }
        </>
    )
}

class AppBooking extends Component {
    constructor(props) {
        super(props);

        this.state = { }
    }

    render() {
        const { available, allowBack, allowForward, selected, onDayClick, onHourClick, onPagination } = this.props;
        if(!available || !genesysUtils.typeCheck.isArray(available) || available.length<1) {
            return null;
        }
        const colWidth = `${100 / available.length}%`;

        return(
            <div id="appBookingId">
                <div className="booking-wrapper">

                    { (onPagination && (allowBack || allowForward)) &&
                    <div className="booking-navbar-wrapper">
                        <div className="booking-navbar-box">
                            <div 
                                className={`${allowBack ? "go-back" : "empty-button"}`} 
                                onClick={() => onPagination(-1)}
                            >
                                { allowBack &&
                                <AppCustomImage imageContextFn={getImageThemeContext} module="appBooking" imageId="icon_back" />
                                }
                            </div>

                            <div 
                                className={`${allowForward ? "go-forward" : "empty-button"}`}
                                onClick={() => onPagination(1)}
                            >
                                { allowForward &&
                                <AppCustomImage imageContextFn={getImageThemeContext} module="appBooking" imageId="icon_forward" />
                                }
                            </div>
                        </div>
                    </div>
                    }

                    <div className={`booking-grid-wrapper${allowBack || allowForward ? " has-navigation" : ""}`}>
                        <div className="booking-grid-box">
                            { available.map((dayData, indx) => {
                                const newMonth = indx && (dayData.year!==available[0].year || dayData.month!==available[0].month);
                                const beginMonth = newMonth && (dayData.year!==available[indx-1].year || dayData.month!==available[indx-1].month);

                                return(
                                    <DayColumn 
                                        key={`day_${indx}`}
                                        className={`${newMonth ? `new-month${beginMonth ? " begin-of-month" : ""}` : ""}`}
                                        selected={selected}
                                        width={colWidth}
                                        onDayClick={onDayClick}
                                        onHourClick={onHourClick}
                                        dayData={dayData} />
                                )
                            }) }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default AppBooking;
