const HANGUP_MODE = {
    CUSTOM: 'custom',
    DEFAULT: 'default',
    DISABLED: 'disabled'
}

// Available toobar buttons: [ 'microphone', 'camera', 'desktop', 'chat', 'raisehand', 'select-background', 'hangup' ]
const defaultToolbarButtons = [ 'microphone', 'camera', 'chat', 'raisehand' ];

const baseAppRoomOptions = {
    lang: "ptBR",
    width: "100%",
    height: "100%",
    configOverwrite: {
        disableDeepLinking: true,
        apiLogLevels: 'error',
        startWithAudioMuted: false,
        buttonsWithNotifyClick: [ // Will expose event and prevent it execution if not set otherwise
            {
                key: 'camera',
                preventExecution: false
            },
            {
                key: 'chat',
                preventExecution: false
            },
            'closedcaptions',
            {
                key: 'desktop',
                preventExecution: false
            },
            'download',
            'embedmeeting',
            'etherpad',
            'feedback',
            'filmstrip',
            'fullscreen',
            'hangup',
            'help',
            'invite',
            'livestreaming',
            {
                key: 'microphone',
                preventExecution: false
            },
            'mute-everyone',
            'mute-video-everyone',
            'participants-pane',
            'profile',
            {
                key: 'raisehand',
                preventExecution: false
            },
            'recording',
            'security',
            {
                key: 'select-background',
                preventExecution: false
            },
            'settings',
            'shareaudio',
            'sharedvideo',
            'shortcuts',
            'stats',
            'tileview',
            'toggle-camera',
            'videoquality',
            'add-passcode', // The add passcode button from the security dialog.
            '__end'
        ],
        toolbarButtons: defaultToolbarButtons,
        prejoinPageEnabled: false,
        prejoinConfig: {
            enabled: false, // This was replaced by `prejoinPageEnabled`
            hideDisplayName: true, // Hides the participant name editing field in the prejoin screen.
            hideExtraJoinButtons: ['no-audio', 'by-phone'] // List of buttons to hide from the extra join options dropdown.
        },
        // Available: 'microphone', 'camera', 'select-background', 'invite', 'settings'
        hiddenPremeetingButtons: ['invite', 'settings', 'select-background'],
        breakoutRooms: {
            hideAddRoomButton: true
        },
        participantsPane: {
            hideModeratorSettingsTab: true,
            hideMoreActionsButton: true,
            hideMuteAllButton: true
        },
        speakerStatsOrder: ['role'],
        disableSelfViewSettings: true,
        disableLocalVideoFlip: true,
        hideDisplayName: false, // Hides the display name from the participant thumbnail
        disableProfile: true, // Disables profile and the edit of all fields from the profile settings (display name and email)
        remoteVideoMenu: {
            disabled: true, // Whether the remote video context menu to be rendered or not.
            disableKick: true, // If set to true the 'Kick out' button will be disabled.
            disableGrantModerator: true, // If set to true the 'Grant moderator' button will be disabled.
            disablePrivateChat: true // If set to true the 'Send private message' button will be disabled.
        },
        connectionIndicators: {
            disabled: true
        },
        desktopSharingFrameRate: {
            min: 5,
            max: 30
        },
        resolution: 320,
        constraints: {
            video: {
                //aspectRatio: 16 / 9,
                aspectRatio: 1,
                height: {
                    ideal: 480,
                    max: 480,
                    min: 180
                }                
            }
        }
    },
    interfaceConfigOverwrite: {
        SHOW_CHROME_EXTENSION_BANNER: false,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
        TILE_VIEW_MAX_COLUMNS: 5
    }
}

export {
    HANGUP_MODE,
    baseAppRoomOptions,
    defaultToolbarButtons
}